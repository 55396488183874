import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FilterProps, ListPaginated } from "types/list.type";
import {
  ApprovisionnementResource,
  ArticleResource,
  InventaireResource,
  MagasinResource,
  VenteResource,
} from "types/stock.type";

type StockState = {
  approvisionnements: ListPaginated<ApprovisionnementResource>;
  approvisionnementFilter: FilterProps;

  ventes: ListPaginated<VenteResource>;
  venteFilter: FilterProps;

  inventaires: ListPaginated<InventaireResource>;
  inventaireFilter: FilterProps;

  articles: ListPaginated<ArticleResource>;
  articleFilter: FilterProps;

  magasins: ListPaginated<MagasinResource>;
  magasinFilter: FilterProps;
};

const INIT_LIST = {
  data: [],
  total: 0,
  totalPage: 0,
  page: 1,
  totalInDatabase: 0,
};

const INIT_FILTRE = {
  page: 1,
  perpage: 10,
};

const initialState: StockState = {
  approvisionnements: { ...INIT_LIST },
  approvisionnementFilter: { ...INIT_FILTRE },

  ventes: { ...INIT_LIST },
  venteFilter: { ...INIT_FILTRE },

  inventaires: { ...INIT_LIST },
  inventaireFilter: { ...INIT_FILTRE },

  articles: { ...INIT_LIST },
  articleFilter: { ...INIT_FILTRE },

  magasins: { ...INIT_LIST },
  magasinFilter: { ...INIT_FILTRE },
};

const stockSlice = createSlice({
  name: "stock",
  initialState,
  reducers: {
    setApprovisionnements(
      state,
      action: PayloadAction<ListPaginated<ApprovisionnementResource>>
    ) {
      state.approvisionnements = action.payload;
    },
    setApprovisionnementFilter(state, { payload }: PayloadAction<FilterProps>) {
      state.approvisionnementFilter = payload;
      state.approvisionnements.data = [];
    },

    setVentes(state, action: PayloadAction<ListPaginated<VenteResource>>) {
      state.ventes = action.payload;
    },
    setVenteFilter(state, { payload }: PayloadAction<FilterProps>) {
      state.venteFilter = payload;
      state.ventes.data = [];
    },

    setInventaires(
      state,
      action: PayloadAction<ListPaginated<InventaireResource>>
    ) {
      state.inventaires = action.payload;
    },
    setInventaireFilter(state, { payload }: PayloadAction<FilterProps>) {
      state.inventaireFilter = payload;
      state.inventaires.data = [];
    },

    setArticles(state, action: PayloadAction<ListPaginated<ArticleResource>>) {
      state.articles = action.payload;
    },
    setArticleFilter(state, { payload }: PayloadAction<FilterProps>) {
      state.articleFilter = payload;
      state.articles.data = [];
    },

    setMagasins(state, action: PayloadAction<ListPaginated<MagasinResource>>) {
      state.magasins = action.payload;
    },
    setMagasinFilter(state, { payload }: PayloadAction<FilterProps>) {
      state.magasinFilter = payload;
      state.magasins.data = [];
    },
  },
});

export default stockSlice.reducer;

export const {
  setApprovisionnements,
  setApprovisionnementFilter,
  setVentes,
  setVenteFilter,
  setArticleFilter,
  setArticles,
  setMagasinFilter,
  setMagasins,
  setInventaireFilter,
  setInventaires,
} = stockSlice.actions;

const getStockReducer = (state: any): StockState => state.stock;

export const selectApprovisionnements = createSelector(
  getStockReducer,
  ({ approvisionnements }) => approvisionnements
);

export const selectApprovisionnementFilter = createSelector(
  getStockReducer,
  ({ approvisionnementFilter }) => approvisionnementFilter
);

export const selectVentes = createSelector(
  getStockReducer,
  ({ ventes }) => ventes
);

export const selectVenteFilter = createSelector(
  getStockReducer,
  ({ venteFilter }) => venteFilter
);

export const selectArticles = createSelector(
  getStockReducer,
  ({ articles }) => articles
);

export const selectArticleFilter = createSelector(
  getStockReducer,
  ({ articleFilter }) => articleFilter
);

export const selectMagasins = createSelector(
  getStockReducer,
  ({ magasins }) => magasins
);

export const selectMagasinFilter = createSelector(
  getStockReducer,
  ({ magasinFilter }) => magasinFilter
);

export const selectInventaires = createSelector(
  getStockReducer,
  ({ inventaires }) => inventaires
);

export const selectInventaireFilter = createSelector(
  getStockReducer,
  ({ inventaireFilter }) => inventaireFilter
);
