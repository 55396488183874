import { Close, Subject } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { Collapse, PersonnelAvatar } from "components";
import {
  ViewIcons,
  ViewItem2 as ViewItem,
  ViewList,
  ViewListItem,
  ViewListItemEmpty,
} from "pages/common";

import { FC, Fragment, useState } from "react";
import { EquipageResource } from "types/transport.types";
import { ListPrestataire } from "./ListPrestataire";
import { equipageService } from "services";

type EquipageModalProps = {
  equipageId: string;
};

export const EquipageModal: FC<EquipageModalProps> = ({ equipageId }) => {
  const [equipage, setEquipage] = useState<EquipageResource | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    if (!equipage) {
      equipageService.findById(equipageId).then(setEquipage);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Button
        onClick={handleClick}
        size="extraSmall"
        color="primary"
        variant="text"
        sx={{ marginLeft: 1 }}
      >
        Equipage
      </Button>
      <Popover
        id="dessertes"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        elevation={3}
      >
        <Box
          sx={{
            padding: 1.5,
            minHeight: 200,
            overflowY: "auto",
            minWidth: 900,
          }}
        >
          <Stack sx={{ marginBottom: 2 }} flexDirection="row">
            <Subject sx={{ marginTop: 0.5, marginRight: 1 }} />
            <Stack flexDirection="row" sx={{ width: "100%" }}>
              <Stack>
                <Typography
                  sx={{
                    fontWeight: "500 !important",
                  }}
                  component="span"
                  variant="h4"
                  marginBottom={0}
                >
                  Equipage
                </Typography>
                <Typography marginBottom={0} color="textSecondary">
                  Liste des personnes de l'équipage
                </Typography>
              </Stack>
            </Stack>

            <IconButton color="error" onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>
          {equipage && (
            <ViewList>
              <ViewListItem>
                <Grid item xs={12} md={4}>
                  <ViewItem>
                    <PersonnelAvatar
                      personnel={equipage.chefDeBord}
                      withMatricule={false}
                      subtitle="Chef de bord"
                    />
                  </ViewItem>
                </Grid>
                <Grid item xs={12} md={4}>
                  <ViewItem>
                    <PersonnelAvatar
                      personnel={equipage.chauffeur}
                      withMatricule={false}
                      subtitle="Chauffeur"
                    />
                  </ViewItem>
                </Grid>
                <Grid item xs={12} md={4}>
                  <ViewItem>
                    <PersonnelAvatar
                      personnel={equipage.agentGarde}
                      withMatricule={false}
                      subtitle="Agent garde"
                    />
                  </ViewItem>
                </Grid>
              </ViewListItem>

              <Collapse
                title="Prestataires"
                subtitle="Liste des prestataires externes"
                leftIcon={ViewIcons.prestataire}
                sx={{ padding: 2 }}
              >
                {equipage.prestataires?.length ? (
                  <ListPrestataire prestataires={equipage.prestataires || []} />
                ) : (
                  <ViewListItemEmpty />
                )}
              </Collapse>
            </ViewList>
          )}
        </Box>
      </Popover>
    </>
  );
};
