import { useEffect, useMemo, useState } from "react";

import {
  Button,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

// assets
import { TableSimple } from "components";
import { PageTitle } from "pages/common";
import { zoneService } from "services";

import { ZoneResource } from "types/centre.type";
import { useModal, useUser } from "hooks";
import { addOrUpdateElement, selectHelper } from "utils/helpers";
import { EditTwoTone } from "@ant-design/icons";
import { ZoneModal } from "./ZoneModal";

const ListZone = () => {
  const theme = useTheme();
  const [defaultValues, setDefaultValues] = useState<any | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<ZoneResource[]>([]);
  const modal = useModal();
  const { paysId } = useUser();

  useEffect(() => {
    setLoading(true);

    zoneService
      .findAll({ paysId })
      .then((resultat) => {
        setData(resultat.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [paysId]);

  const save = (data: any) => {
    if (defaultValues?.id) {
      return zoneService.update(defaultValues.id, {
        ...data,
        situationGeographiqueId: selectHelper.getValue(
          data.situationGeographiqueId
        ),
      });
    }
    return zoneService.create({
      ...data,
      situationGeographiqueId: selectHelper.getValue(
        data.situationGeographiqueId
      ),
    });
  };

  const onEdit = (original: ZoneResource) => {
    setDefaultValues({
      id: original.id,
      libelle: original.libelle,
      code: original.code,
      situationGeographiqueId: selectHelper.buildSituationGeographique(
        original.situationGeographique
      ),
    });
    modal.show();
  };

  const closeModal = () => {
    modal.close();
    setDefaultValues(undefined);
  };

  const onFinished = (element: ZoneResource) => {
    const index = data.findIndex(({ id }) => id === element.id);
    setData((d) => addOrUpdateElement(d, element, index));
    closeModal();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Libelle",
        accessor: "libelle",
        Cell: ({ row: { original } }: any) => (
          <Typography variant="subtitle1">{original.libelle}</Typography>
        ),
      },
      {
        Header: "Code",
        accessor: "code",
        Cell: ({ row: { original } }: any) => (
          <Typography>{original.code || "-"}</Typography>
        ),
      },
      {
        Header: "Situation géographique",
        accessor: "situationGeographique",
        Cell: ({ row: { original } }: any) => (
          <Typography>
            {original.situationGeographique.libelle || "-"}
          </Typography>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",

        Cell: ({ row: { original } }: any) => (
          <Tooltip title="Modifier la zone">
            <IconButton
              color="error"
              size="large"
              onClick={() => onEdit(original)}
            >
              <EditTwoTone twoToneColor={theme.palette.error.main} />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <PageTitle
        title="Zone"
        subtitle="Consulter et gérer les zones"
        sx={{ padding: "1.5rem 0" }}
        right={
          <Button variant="contained" onClick={modal.show}>
            Créer une zone
          </Button>
        }
      />

      <TableSimple
        columns={columns}
        data={data}
        withPagination
        loading={loading}
      />
      {modal.open && (
        <ZoneModal
          onBack={closeModal}
          onFinished={onFinished}
          onSave={save}
          defaultValues={defaultValues}
        />
      )}
    </>
  );
};

export default ListZone;
