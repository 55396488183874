import { Grid } from "@mui/material";
import { TextInput } from "components";
import { withForm, WrapperProps } from "hoc";
import { FC } from "react";
import { UtilitaireModal } from "../UtilitaireModal";

const Form: FC<WrapperProps> = (props) => {
  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title={
        props.isEditMode ? "Mise à jour de famille d'articles" : "Ajouter une famille d'articles"
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextInput label="Nom *" name="designation" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput label="Code *" name="code" />
        </Grid>
      </Grid>
    </UtilitaireModal>
  );
};

export const FamilleArticleModal = withForm(Form);
