import { FC, useMemo } from "react";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { SelectAsyncVehiculeForm, SelectPersonnelAsyncForm } from "components";
import { TourneePrestataireForm } from "./TourneePrestataireForm";
import { CODE_CONVOYEUR } from "utils/constant";

export const TourneeEquipageForm: FC = () => {
  const { watch } = useFormContext();
  const centreId = watch("centre")?.value;
  const personnelParams = { codeFonction: CODE_CONVOYEUR.join(","), centreId };

  const personnelDeps = useMemo(() => [centreId], [centreId]);
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <SelectAsyncVehiculeForm
            name="equipePrincipale.vehicule"
            label="Véhicule *"
            size="small"
            disabled={!centreId}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectPersonnelAsyncForm
            name="equipePrincipale.chefDeBord"
            label="Chef de bord *"
            size="small"
            params={personnelParams}
            disabled={!centreId}
            resetDependencies={personnelDeps}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <SelectPersonnelAsyncForm
            name="equipePrincipale.agentGarde"
            label="Agent de garde *"
            size="small"
            params={personnelParams}
            disabled={!centreId}
            resetDependencies={personnelDeps}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <SelectPersonnelAsyncForm
            name="equipePrincipale.chauffeur"
            label="Chauffeur *"
            size="small"
            params={personnelParams}
            disabled={!centreId}
            resetDependencies={personnelDeps}
          />
        </Grid>
      </Grid>

      <TourneePrestataireForm base="equipePrincipale" />
    </>
  );
};
