import { FC } from "react";
import { Box, Grid } from "@mui/material";
import { withForm, WrapperProps } from "hoc";
import { TextInput } from "components";
import * as yup from "yup";

const toBody = (data: any) => {
  return {};
};

export const conversationConverter = {
  toBody,
};

//
const Form: FC<WrapperProps> = ({ renderButtons, onSubmit }) => {
  return (
    <form onSubmit={onSubmit}>
      <Grid item xs={12}>
        <TextInput
          name="message"
          label="Message *"
          rows={4}
          multiline
          sx={{ "& .MuiInputBase-input": { fontWeight: 400 } }}
        />
      </Grid>
      <Box sx={{ textAlign: "right", marginTop: 1.5 }}>{renderButtons()}</Box>
    </form>
  );
};

export const IncidentConversationForm = withForm(
  Form,
  yup.object({
    message: yup.string().required(),
  })
);
