import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { endOfMonth, startOfMonth, startOfWeek } from "date-fns";
import { PERIODES, TYPE_DATA } from "pages/transport/reporting/ChiffreAffaire";
import { FilterProps } from "types/list.type";
import { PointReporting } from "types/reporting";

type ReportingState = {
  points: PointReporting[];
  pointFilter: FilterProps;

  chiffreAffaire: PointReporting[];
  chiffreAffaireFilter: FilterProps;
};

const initialState: ReportingState = {
  points: [],
  pointFilter: {
    dateDebut: startOfMonth(new Date()),
    dateFin: endOfMonth(new Date()),
    etatDesserte: { label: "Livrée", value: "livre" },
    etatTournee: { label: "Terminée", value: "termine" },
    modeAffichage: "liste",
  },

  chiffreAffaire: [],

  chiffreAffaireFilter: {
    dateDebut: startOfWeek(new Date(), { weekStartsOn: 1 }),
    dateFin: new Date(),
    date: new Date(),
    mois: new Date(),
    annee: new Date(),
    periode: PERIODES[0],
    type: TYPE_DATA[0],
    modeAffichage: "liste",
  },
};

const reportingSlice = createSlice({
  name: "reporting",
  initialState,
  reducers: {
    setPointReporting(state, action: PayloadAction<PointReporting[]>) {
      state.points = action.payload;
    },
    setPointFilter(state, { payload }: PayloadAction<FilterProps>) {
      state.pointFilter = payload;
    },
    setChiffreAffaireFilter(state, { payload }: PayloadAction<FilterProps>) {
      state.chiffreAffaireFilter = payload;
    },
  },
});

export default reportingSlice.reducer;

export const { setPointReporting, setPointFilter, setChiffreAffaireFilter } =
  reportingSlice.actions;

const getReportingReducer = (state: any): ReportingState => state.reporting;

export const selectPointsReporting = createSelector(
  getReportingReducer,
  ({ points }) => points
);

export const selectPointsFilter = createSelector(
  getReportingReducer,
  ({ pointFilter }) => pointFilter
);

export const selectChiffreAffaireReporting = createSelector(
  getReportingReducer,
  ({ chiffreAffaire }) => chiffreAffaire
);

export const selectChiffreAffaireFilter = createSelector(
  getReportingReducer,
  ({ chiffreAffaireFilter }) => chiffreAffaireFilter
);
