import {
  Stack,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { FC, Fragment } from "react";
import {
  useGlobalFilter,
  useTable,
  usePagination,
  Column,
  useFilters,
} from "react-table";
import {
  HeaderSort,
  TablePaginationSimple,
} from "components/third-party/ReactTable";
import MainCard from "components/MainCard";
import { GlobalFilter } from "utils/react-table";
import { Collapse } from "components/common";
import { Subject } from "@mui/icons-material";
import { renderFilterTypes } from "./TableSimple";

type TableProps = {
  columns: Column[];
  data?: any[];
  title?: string;
  isOpen?: boolean;
};

export const ActivityTable: FC<TableProps> = ({
  columns,
  data,
  title,
  isOpen,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    // @ts-ignore
    page,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    setPageSize,
    // @ts-ignore
    state: { globalFilter, pageIndex, pageSize },
    // @ts-ignore
    preGlobalFilteredRows,
    // @ts-ignore
    setGlobalFilter,
    // @ts-ignore
  } = useTable(
    {
      columns,
      data: data || [],
      // @ts-ignore
      filterTypes: renderFilterTypes,
      // @ts-ignore
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useFilters,
    usePagination
  );

  const renderContent = () => {
    if (!data?.length) {
      return (
        <TableBody {...getTableBodyProps()}>
          <TableRow
            sx={{
              "&:hover": { bgcolor: "transparent !important" },
            }}
          >
            <TableCell
              sx={{ p: 2, py: 3, textAlign: "center" }}
              colSpan={columns.length}
            >
              Aucun résultat
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    return (
      <TableBody {...getTableBodyProps()}>
        {page.map((row: any, i: number) => {
          prepareRow(row);

          return (
            <Fragment key={i}>
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell: any) => (
                  <TableCell
                    {...cell.getCellProps([
                      { className: cell.column.className },
                    ])}
                  >
                    {cell.render("Cell")}
                  </TableCell>
                ))}
              </TableRow>
            </Fragment>
          );
        })}
        <TableRow
          sx={{
            "&:hover": { bgcolor: "transparent !important" },
            bgcolor: (t: any) => t.palette.grey[100],
          }}
        >
          <TableCell sx={{ p: 2, py: 3 }} colSpan={columns.length}>
            <TablePaginationSimple
              gotoPage={gotoPage}
              rows={rows}
              setPageSize={setPageSize}
              pageSize={pageSize}
              pageIndex={pageIndex}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  return (
    <>
      <MainCard>
        <Collapse
          title={title || "Historique des modifications"}
          subtitle="Liste des activités"
          leftIcon={<Subject />}
          defaultValue={isOpen || false}
        >
          <Stack sx={{ marginBottom: 2 }}>
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              size="small"
            />
          </Stack>

          <MainCard elevation={0} content={false}>
            <MuiTable {...getTableProps()}>
              <TableHead>
                {headerGroups.map((headerGroup) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                      <TableCell
                        {...column.getHeaderProps([
                          { className: column.className },
                        ])}
                      >
                        <HeaderSort column={column} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              {renderContent()}
            </MuiTable>
          </MainCard>
        </Collapse>
      </MainCard>
    </>
  );
};
