import { useUser } from "hooks";
import { FC, useEffect, useState } from "react";
import { roleService } from "services";
import { RequestParam } from "types";
import { SelectItem } from "types/form.type";
import { SelectAsyncProps } from "types/select-async.type";
import { useFormContext } from "react-hook-form";
import { MultiAutoCompleteHookForm } from "../MultiAutoComplete";
import { AutoCompleteAsyncHookForm } from "../AutoComplete";

interface SelectRoleAsyncFormProps extends SelectAsyncProps {
  params?: RequestParam;
}

const loadData = async (queryParams?: RequestParam) => {
  const { data } = await roleService.findAll(queryParams);
  return data.map((item) => ({ label: item.libelle, value: item.id }));
};

export const SelectRoleAsyncForm: FC<SelectRoleAsyncFormProps> = ({
  label,
  name,
  params,
  ...rest
}) => {
  const fetchData = async () => {
    const data = await loadData();
    return { data, hasMore: false };
  };

  return (
    <AutoCompleteAsyncHookForm
      label={label}
      name={name}
      fetchData={fetchData}
      {...rest}
    />
  );
};

export const SelectMultipleRoles: FC<SelectRoleAsyncFormProps> = ({
  queryParams,
  name,
  label,
  afterSelected,
  disabled,
}) => {
  const { setValue } = useFormContext();

  const { user } = useUser();
  const [options, setOptions] = useState<SelectItem[]>([]);

  useEffect(() => {
    if (queryParams) {
      loadData(queryParams).then(setOptions);
    }
  }, [user, setValue, queryParams]);

  return (
    <MultiAutoCompleteHookForm
      options={options}
      label={label}
      name={name}
      disabled={disabled}
      afterSelected={afterSelected}
    />
  );
};
