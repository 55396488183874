import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  AlertTitle,
  Grid,
} from "@mui/material";
import { DateInput, LoadingButton } from "components";
import { startOfTomorrow } from "date-fns";
import { withForm, WrapperProps } from "hoc";
import { useNotification } from "hooks";
import { UtilitaireModal } from "pages/utilitaires";
import { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { closeLoader, showLoader } from "reducers";
import * as yup from "yup";
import { desserteService, tourneeService } from "services";
import { DesserteResource } from "types/transport.types";
import { ListDesserteColis } from "../view/ListDesserteColis";
import { DesserteSens } from "./DesserteSens";
import { dateFormater } from "utils/date";

const Form: FC<WrapperProps> = (props) => {
  const { watch } = useFormContext();
  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title="Desserte à réplanifier"
      maxWidth="sm"
    >
      <Alert severity="warning" variant="border" sx={{ marginBottom: 3 }}>
        <AlertTitle sx={{ fontWeight: 600, textTransform: "uppercase" }}>
          <DesserteSens desserte={watch("desserte")} />
        </AlertTitle>
        La desserte&nbsp;
        <strong>N° {watch("desserte")?.numero}</strong> devra être mise en&nbsp;
        <strong>tranist</strong> pour être traiter à nouveau.
      </Alert>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DateInput
            label="Nouvelle date départ*"
            name="dateDepart"
            dateWrapperProps={{
              minDate: startOfTomorrow(),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ListDesserteColis colis={watch("desserte")?.colis} />
        </Grid>
      </Grid>
    </UtilitaireModal>
  );
};

const ReplanifierDesserteModal = withForm(
  Form,
  yup.object({
    dateDepart: yup.date().required().nullable(),
  })
);

export const AnnulerDesserte: FC<{
  desserte: DesserteResource;
  onFinish: (desserte?: DesserteResource) => void;
  closeModal: () => void;
}> = ({ desserte, onFinish, closeModal }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const notification = useNotification();

  const [data, setData] = useState<DesserteResource | undefined>(undefined);
  const dispatch = useDispatch();
  const desserteId = desserte.id;

  useEffect(() => {
    dispatch(showLoader());
    desserteService
      .findById(desserteId)
      .then(setData)
      .then(() => {
        dispatch(closeLoader());
      });
  }, [desserteId, dispatch]);

  const annulerDesserte = (desserteId: string) => {
    setLoading(true);
    desserteService
      .annuler(desserteId)
      .then(({ data }) => {
        notification.success({
          title: "La desserte a bien été annulée",
        });
        onFinish(data);
      })
      .catch((e) => {
        notification.error({ title: e.message });
      })
      .finally(() => {
        setLoading(false);
        closeModal();
      });
  };

  const replanifierDesserte = (body: Record<string, any>) => {
    if (!data || !data.tournee?.id) {
      return Promise.reject({
        message: `La desserte N°${desserte.numero} n'est pas liée à une tournée`,
      });
    }
    return tourneeService.replanifierDesserte(desserte.id, data.tournee.id, {
      dateDepart: dateFormater.toBackPattern(body.dateDepart),
    });
  };

  if (!data) {
    return null;
  }

  if (data.colis?.length) {
    return (
      <ReplanifierDesserteModal
        onBack={closeModal}
        onSave={replanifierDesserte}
        onFinished={() => {
          closeModal();
          onFinish();
        }}
        defaultValues={{ desserte: data, dateDepart: startOfTomorrow() }}
      />
    );
  }

  return (
    <Dialog maxWidth="sm" open onClose={closeModal}>
      <DialogTitle>Confirmer annulation</DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          Vous voulez vraiment annuler la desserte&nbsp;
          <Typography component="span" fontWeight={600}>
            {desserte.numero}
          </Typography>
          &nbsp;?
          <DesserteSens desserte={desserte} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Annuler</Button>
        <LoadingButton
          variant="contained"
          onClick={() => annulerDesserte(desserte.id)}
          autoFocus
          loading={loading}
          color="error"
        >
          Oui
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
