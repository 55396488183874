import { Grid } from "@mui/material";
import { DateInput, SelectCentresByPerimetre } from "components";
import { withForm, WrapperProps } from "hoc";
import { UtilitaireModal } from "pages/utilitaires";
import { FC } from "react";

import * as yup from "yup";

const Form: FC<WrapperProps> = (props) => {
  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title="Générer automatiquement les dessertes"
      maxWidth="sm"
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DateInput
            label="Date *"
            name="date"
            dateWrapperProps={{
              minDate: new Date(),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectCentresByPerimetre />
        </Grid>
      </Grid>
    </UtilitaireModal>
  );
};

export const GenererDesserteModal = withForm(
  Form,
  yup.object({
    date: yup.date().required().nullable(),
    centres: yup.array().min(1, "Selectionnez au moins un centre"),
  })
);
