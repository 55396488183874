import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserResource } from "types";

type UserState = {
  user?: UserResource;
  token?: string;
};

let initialState: UserState = {
  user: undefined,
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserResource | undefined>) => {
      state.user = action.payload;
    },
  },
});

export const { setUser } = auth.actions;

const getUserReducer = (state: any): UserState => state.auth;

export const selectUser = createSelector(getUserReducer, ({ user }) => user);
export const selectUserPays = createSelector(
  getUserReducer,
  ({ user }) => user?.pays.id
);

export default auth.reducer;
