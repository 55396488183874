import { List } from "@mui/material";

import SimpleBar from "components/third-party/SimpleBar";
import { FC, Fragment, ReactNode } from "react";
import { DesserteResource } from "types/transport.types";

type ListDesserteWrapperProps = {
  list: DesserteResource[];
  renderItem: (desserte: DesserteResource) => ReactNode;
};
export const ListDesserteWrapper: FC<ListDesserteWrapperProps> = ({
  list,
  renderItem,
}) => {
  return (
    <List
      role="list"
      sx={{
        width: "100%",
        height: "450px",
        bgcolor: "background.paper",
        overflow: "hidden",
        pt: 0,
      }}
    >
      <SimpleBar>
        {list.map((desserte) => (
          <Fragment key={desserte.id}>{renderItem(desserte)}</Fragment>
        ))}
      </SimpleBar>
    </List>
  );
};
