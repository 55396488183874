import { RoleResource, UserResource } from "types";
import { requestGet, requestPost } from "./request";

const login = (body: Record<string, string>) => {
  return requestPost<{
    user: UserResource;
    roles?: RoleResource[];
    access_token: string;
  }>("auth/login", body);
};

const userInfo = () => {
  return requestGet<UserResource>("auth/user-profile");
};

const forgetpassword = (body: Record<string, string>) => {
  return requestPost<UserResource>("users/forgotpassword", body);
};

const resetpassword = (body: Record<string, string>) => {
  return requestPost<UserResource>("users/resetpassword", body);
};

export const authService = {
  login,
  userInfo,
  forgetpassword,
  resetpassword,
};
