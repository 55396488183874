// material-ui
import { useTheme } from "@mui/material/styles";

// project import
import DrawerHeaderStyled from "./DrawerHeaderStyled";
import { Logo } from "components/logo";

// ==============================|| DRAWER HEADER ||============================== //

interface Props {
  open: boolean;
}

export const DrawerHeader = ({ open }: Props) => {
  const theme = useTheme();

  return (
    <DrawerHeaderStyled theme={theme} open={open}>
      <Logo isIcon={!open} />
    </DrawerHeaderStyled>
  );
};
