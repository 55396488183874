import { FC } from "react";
import { familleArticleService } from "services";
import { SelectAsyncProps } from "types/select-async.type";
import { AutoCompleteAsyncHookForm } from "../AutoComplete";

export const SelectFamilleArticleAsyncForm: FC<SelectAsyncProps> = (props) => {
  const fetchData = async () => {
    const { data } = await familleArticleService.findAll();
    return {
      data: data.map((item) => ({
        label: item.designation,
        value: item.id,
      })),
      hasMore: false,
    };
  };

  return <AutoCompleteAsyncHookForm {...props} fetchData={fetchData} />;
};
