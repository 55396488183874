import { FC, useMemo } from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { DesktopDatePicker, TimePicker } from "@mui/x-date-pickers";
import { useFormContext } from "react-hook-form";
import { getJsonValue } from "utils/helpers";

type DateInputProps = { name: string } & TextFieldProps;

function withDate<T>(Wrapper: any, dateProps: any) {
  const Hoc: FC<
    DateInputProps & {
      dateWrapperProps?: T;
      afterSelected?: (name: string, value: any) => void;
    }
  > = ({
    name,
    label,
    variant = "outlined",
    fullWidth = true,
    disabled = false,
    size = "small",
    dateWrapperProps = {},
    afterSelected,
    ...rest
  }) => {
    const {
      formState: { errors },
      watch,
      setValue,
      clearErrors,
    } = useFormContext();

    const error = useMemo(() => {
      return getJsonValue(errors, name);
    }, [errors, name]);

    return (
      <Wrapper
        value={watch(name) || null}
        {...dateProps}
        onChange={(value: any) => {
          setValue(name, value);
          clearErrors(name);
          afterSelected && afterSelected(name, value);
        }}
        {...(dateWrapperProps || {})}
        ampm={false}
        label={label}
        disabled={disabled}
        renderInput={(params: any) => (
          <TextField
            fullWidth
            {...rest}
            {...params}
            variant={variant}
            name={name}
            error={!!error}
            helperText={error?.message?.toString() || ""}
            size={size}
          />
        )}
      />
    );
  };
  return Hoc;
}

export const DateInput = withDate<any>(DesktopDatePicker, {
  inputFormat: "dd/MM/yyyy",
});

export const TimeInput = withDate(TimePicker, { inputFormat: "HH:mm" });

export const MonthYearInput = withDate(DesktopDatePicker, {
  views: ["month", "year"],
  inputFormat: "MM/yyyy",
});

export const MonthInput = withDate(DesktopDatePicker, {
  views: ["month"],
  inputFormat: "MM",
});

export const YearInput = withDate(DesktopDatePicker, {
  views: ["year"],
  inputFormat: "yyyy",
});
