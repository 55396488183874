import {
  DesserteResource,
  TourneeResource,
  TrajetResource,
} from "types/transport.types";
import { CODE_ETAT_TRAJET, CORPS } from "utils/constant";
import { dateFormater, dateParser, setTime } from "utils/date";
import { selectHelper } from "utils/helpers";

const toInput = (data: TourneeResource) => {
  const {
    vehicule,
    equipage: { chauffeur, agentGarde, chefDeBord },
    centre,
    dateDepart,
    dateArrivee,
    dessertes,
  } = data;

  return {
    id: data.id,
    numero: data.numero,
    coutTournee: data.coutTournee,
    etatTournee: selectHelper.buildReferentiel(data.etatTournee),
    centre: selectHelper.build(centre.libelle, centre.id),
    dateDepart: dateParser.toDateTime(dateDepart),
    heureDepart: dateParser.createDateFromTime(data.heureDepart),
    dateArrivee: dateParser.toDateTime(dateArrivee),
    heureArrivee: dateParser.createDateFromTime(data.heureArrivee),
    vehicule: selectHelper.build(vehicule?.marque, vehicule?.id),
    chauffeur: selectHelper.buildPersonnel(chauffeur),
    agentGarde: selectHelper.buildPersonnel(agentGarde),
    chefDeBord: selectHelper.buildPersonnel(chefDeBord),
    dessertes,
    initialDessertes: dessertes,
    prestataires: (data.prestataires || []).map((item) => ({
      ...item,
      corps: CORPS.find((e) => e.value === item.corps),
    })),
  };
};

export const buildEquipage = (
  equipage: Record<string, any>
): Record<string, any> => {
  const body: Record<string, any> = {
    vehiculeId: selectHelper.getValue(equipage.vehicule),
    chauffeurId: selectHelper.getValue(equipage.chauffeur),
    agentGardeId: selectHelper.getValue(equipage.agentGarde),
    chefDeBordId: selectHelper.getValue(equipage.chefDeBord),
    centreId: selectHelper.getValue(equipage.centre),
    lieu: equipage.lieu,
    prestataires: (equipage.prestataires || []).map((item: any) => ({
      ...item,
      corps: selectHelper.getValue(item.corps),
    })),
  };
  if (equipage.mode === "changement") {
    body.motifChangementId = selectHelper.getValue(equipage.motifChangement);
  }
  return body;
};

const buildEquipages = (
  trajets: TrajetResource[],
  dessertes: DesserteResource[],
  equipePrincipale: Record<string, any>
) => {
  const equipages: any = [
    {
      ...buildEquipage(equipePrincipale),
      dessertes: dessertes.map((desserte) => ({
        id: `${desserte.id}`,
        etat: "actif",
      })),
      trajets: trajets.map(toTrajet),
    },
  ];

  return equipages;
};

const toBody = (data: any) => {
  const {
    centre,
    dateDepart,
    heureDepart,
    dateArrivee,
    heureArrivee,
    dessertes,
    trajets,
    prestataires,
    equipePrincipale,
    jonctions,
  } = data;

  const _equipePrincipale = { ...equipePrincipale, centre };

  const body: Record<string, any> = {
    centreId: selectHelper.getValue(centre),
    dateDepart: dateFormater.toBackPattern(setTime(dateDepart, heureDepart)),
    heureDepart: dateFormater.toBackTime(heureDepart),
    dateArrivee: dateFormater.toBackPattern(setTime(dateArrivee, heureArrivee)),
    heureArrivee: dateFormater.toBackTime(heureArrivee),
    equipages: buildEquipages(trajets, dessertes, _equipePrincipale),
    prestataires:
      prestataires?.map((prestataire: Record<string, any>) => ({
        ...prestataire,
        corps: selectHelper.getValue(prestataire.corps),
      })) || [],
    jonctions: jonctions.map((jonction: any) => {
      const dessertes = jonction?.dessertes?.map(selectHelper.getValue) || [];
      return {
        lieu: jonction.lieu,
        tourneeJonctionId: selectHelper.getValue(jonction.tournee),
        centreId: selectHelper.getValue(jonction.centre),
        dessertes,
      };
    }),
  };
  return body;
};

const toTrajet = (item: TrajetResource) => {
  if (item.etat === CODE_ETAT_TRAJET.new_site) {
    return {
      ...item,
      etat: CODE_ETAT_TRAJET.non_passe,
    };
  }
  return item;
};

export const tourneeConverter = {
  toInput,
  toBody,
  toTrajet,
};
