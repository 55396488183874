import { Typography } from "@mui/material";
import { MontantFormatText } from "components/form";
import { CellPopover, Table } from "components/table";
import { FC } from "react";
import { SousColisResource } from "types/transport.types";

export const SousColisPopover: FC<{
  sousColis?: SousColisResource[];
}> = ({ sousColis }) => {
  return (
    <>
      <Typography color="textPrimary" component="span" variant="subtitle1">
        {`${sousColis?.length || "Aucun"} sous colis`}
      </Typography>

      {!!sousColis?.length && (
        <CellPopover name="colis">
          <Table
            size="small"
            columns={[
              {
                Header: "Numero",
                accessor: "numero",
              },
              {
                Header: "Montant",
                accessor: "montant",
                Cell: ({ row: { original } }: any) => (
                  <MontantFormatText value={original.montant} />
                ),
              },
            ]}
            data={sousColis || []}
          />
        </CellPopover>
      )}
    </>
  );
};
