import { useApp, useUser } from "hooks";
import { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { UserResource } from "types";
import { SelectItem } from "types/form.type";
import { PersonnelResource } from "types/personnel.type";

import {
  hasPerimetreChefCentreRegional,
  hasPerimetreToutCentre,
} from "utils/user.helpers";
import { MultiAutoCompleteHookForm } from "../MultiAutoComplete";

export const SelectCentresByPerimetre: FC = () => {
  const { setValue } = useFormContext();
  const {
    centres: { fetchData: fetchCentres },
  } = useApp();
  const { user } = useUser();
  const [options, setOptions] = useState<SelectItem[]>([]);

  useEffect(() => {
    const findCentres = async (user: UserResource) => {
      if (hasPerimetreChefCentreRegional(user)) {
        return await fetchCentres({
          centreRegionalId: (user.userable as any)?.centreRegional?.id,
        });
      }

      if (hasPerimetreToutCentre(user)) {
        return await fetchCentres();
      }

      const centre = (user.userable as PersonnelResource)?.centre;
      if (centre) {
        return Promise.resolve([centre]);
      }
      return Promise.reject({ message: "Pas de centre" });
    };

    const loadCentres = () => {
      findCentres(user)
        .then((data) =>
          data.map((item) => ({
            label: item.libelle,
            value: item.id,
          }))
        )
        .then((data) => {
          setOptions(data);
          setValue("centres", data);
        });
    };

    loadCentres();
  }, [user, setValue, fetchCentres]);

  return (
    <MultiAutoCompleteHookForm
      options={options}
      name="centres"
      label="Centres *"
    />
  );
};
