import { useUser } from "hooks";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { centreRegionalService } from "services";
import { SelectAsyncProps } from "types/select-async.type";
import { buildSelectItem } from "utils/helpers";
import { AutoCompleteAsyncHookForm, FetchDataProps } from "../AutoComplete";

interface CentreRegionalAsyncSelectFormProps extends SelectAsyncProps {
  centreField: string;
}

export const CentreRegionalAsyncSelectForm: FC<
  CentreRegionalAsyncSelectFormProps
> = ({ label, name, size, centreField }) => {
  const { getFieldState, setValue, watch, clearErrors } = useFormContext();
  const { paysId } = useUser();
  const centreRegionalId = watch(name);

  useEffect(() => {
    if (getFieldState(name).isTouched) {
      setValue(centreField, undefined);
      clearErrors(centreField);
    }
  }, [
    getFieldState,
    setValue,
    centreRegionalId,
    name,
    centreField,
    clearErrors,
  ]);

  const fetchData = async ({ page }: FetchDataProps) => {
    const { data, total, totalPage } = await centreRegionalService.findAll({
      page,
      paysId,
    });
    return {
      data: data.map(({ id, libelle }) => buildSelectItem(libelle, id)),
      hasMore: total < totalPage,
    };
  };

  return (
    <AutoCompleteAsyncHookForm
      label={label}
      name={name}
      fetchData={fetchData}
      size={size}
    />
  );
};
