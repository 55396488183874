import { useEffect, useMemo, useState } from "react";

import {
  Button,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

// assets
import { TableSimple } from "components";
import { PageTitle } from "pages/common";
import { familleArticleService } from "services";

import { FamilleArticleResource } from "types/stock.type";
import { useModal } from "hooks";
import { addOrUpdateElement } from "utils/helpers";
import { EditTwoTone } from "@ant-design/icons";
import { FamilleArticleModal } from "./FamilleArticleModal";
import { useQueryClient } from "@tanstack/react-query";

const ListFamilleArticle = () => {
  const theme = useTheme();
  const [defaultValues, setDefaultValues] = useState<any | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<FamilleArticleResource[]>([]);
  const modal = useModal();
  const queryClient = useQueryClient();

  useEffect(() => {
    setLoading(true);

    familleArticleService
      .findAll()
      .then((resultat) => {
        setData(resultat.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const save = (data: any) => {
    const body = { ...data };
    if (defaultValues?.id) {
      return familleArticleService.update(defaultValues.id, body);
    }
    return familleArticleService.create(body);
  };

  const onEdit = (original: FamilleArticleResource) => {
    setDefaultValues({
      id: original.id,
      designation: original.designation,
      code: original.code,
    });
    modal.show();
  };

  const closeModal = () => {
    modal.close();
    setDefaultValues(undefined);
  };

  const onFinished = (element: FamilleArticleResource) => {
    const index = data.findIndex(({ id }) => id === element.id);
    setData((d) => addOrUpdateElement(d, element, index));
    queryClient.invalidateQueries(["categories"]);
    closeModal();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Désignation",
        accessor: "designation",
        Cell: ({ row: { original } }: any) => (
          <Typography variant="subtitle1">{original.designation}</Typography>
        ),
      },
      {
        Header: "Code",
        accessor: "code",
        Cell: ({ row: { original } }: any) => (
          <Typography>{original.code || "-"}</Typography>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",

        Cell: ({ row: { original } }: any) => (
          <Tooltip title="Modifier la fonction">
            <IconButton
              color="error"
              size="large"
              onClick={() => onEdit(original)}
            >
              <EditTwoTone twoToneColor={theme.palette.error.main} />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <PageTitle
        title="Famille d'articles"
        subtitle="Consulter et gérer les familles d'articles"
        sx={{ padding: "1.5rem 0" }}
        right={
          <Button variant="contained" onClick={modal.show}>
            Créer une famille d'articles
          </Button>
        }
      />

      <TableSimple
        columns={columns}
        data={data}
        withPagination
        loading={loading}
      />
      {modal.open && (
        <FamilleArticleModal
          onBack={closeModal}
          onFinished={onFinished}
          onSave={save}
          defaultValues={defaultValues}
        />
      )}
    </>
  );
};

export default ListFamilleArticle;
