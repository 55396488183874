import { Stack, Typography } from "@mui/material";
import Avatar from "components/@extended/Avatar";
import { useUser } from "hooks";
import { FC, useEffect, useState } from "react";
import { personnelService } from "services";
import { RequestParam } from "types";
import { SelectItem } from "types/form.type";
import { SelectAsyncProps } from "types/select-async.type";
import { useFormContext } from "react-hook-form";
import { AutoCompleteAsyncHookForm } from "../AutoComplete";
import { MultiAutoCompleteHookForm } from "../MultiAutoComplete";

interface PersonnelItem extends SelectItem {
  matricule: string;
  photo?: string;
  firstname: string;
  lastname: string;
  email: string;
  centre: string;
}

interface SelectPersonnelAsyncFormProps extends SelectAsyncProps {
  params?: RequestParam;
}

const renderPersonnel = (props: any, _option: any) => {
  const option = _option as PersonnelItem;
  return (
    <>
      <Avatar
        src={option.photo}
        resource="personne"
        color="primary"
        sx={{ marginRight: 1, color: "textPrimary" }}
      />
      <Stack>
        <Typography variant="subtitle1">{option.label}</Typography>
        <Typography color="secondary" variant="caption">
          {option.centre}
        </Typography>
      </Stack>
    </>
  );
};

const loadData = async (queryParams?: RequestParam) => {
  const { data } = await personnelService.findAll(queryParams);
  return data.map((item) => ({
    label: `${item.lastname} ${item.firstname}`,
    value: item.id,
    matricule: item.matricule,
    photo: item.photo,
    firstname: item.firstname,
    lastname: item.lastname,
    email: item.email,
    centre: item.centre.libelle,
  }));
};

export const SelectPersonnelAsyncForm: FC<SelectPersonnelAsyncFormProps> = ({
  label,
  name,
  params,
  ...rest
}) => {
  const { paysId } = useUser();

  const fetchData = async () => {
    const data = await loadData({ paysId, ...(params || {}) });
    return { data, hasMore: false };
  };

  return (
    <AutoCompleteAsyncHookForm
      label={label}
      name={name}
      fetchData={fetchData}
      {...rest}
      renderOption={renderPersonnel}
    />
  );
};

export const SelectMultiplePersonnels: FC<SelectPersonnelAsyncFormProps> = ({
  queryParams,
  name,
  label,
  afterSelected,
  disabled,
}) => {
  const { setValue } = useFormContext();

  const { user } = useUser();
  const [options, setOptions] = useState<SelectItem[]>([]);

  useEffect(() => {
    if (queryParams) {
      loadData(queryParams).then(setOptions);
    }
  }, [user, setValue, queryParams]);

  return (
    <MultiAutoCompleteHookForm
      options={options}
      label={label}
      name={name}
      disabled={disabled}
      afterSelected={afterSelected}
      renderOption={renderPersonnel}
    />
  );
};
