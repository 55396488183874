import { Grid } from "@mui/material";
import {
  CentreAsyncSelectForm,
  SelectTourneeAsyncForm,
  TextInput,
} from "components";
import { withForm, WrapperProps } from "hoc";
import { UtilitaireModal } from "pages/utilitaires";
import { FC, useMemo } from "react";
import { jonctionService } from "services";
import { DesserteResource, JonctionResource } from "types/transport.types";
import { selectHelper } from "utils/helpers";

import * as yup from "yup";
import { SelectDessertes } from "components/form/asycSelect/SelectDessertes";
import { useFormContext } from "react-hook-form";

const AddJonctionForm: FC<WrapperProps> = (props) => {
  const { setValue, watch } = useFormContext();
  const dateDepart = watch("dateDepart");
  const centreId = watch("centre")?.value;

  const tourneeDeps = useMemo(
    () => [{ value: centreId, label: centreId }],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [centreId, dateDepart]
  );

  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title="Ajouter une jonction"
      maxWidth="md"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <CentreAsyncSelectForm
            name="centre"
            label="Centre *"
            afterSelected={() => {
              setValue("tournee", null);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectTourneeAsyncForm
            name="tournee"
            label="Tournée *"
            disabled={!centreId}
            resetDependencies={tourneeDeps}
            queryParams={{
              centreId,
              // dateDepart: dateFormater.toBackPattern(dateDepart, "yyyy-MM-dd"),
              states: "init,en_cours",
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextInput name="lieu" label="Lieu *" />
        </Grid>

        <Grid item xs={12}>
          <SelectDessertes
            name="dessertes"
            label="Dessertes *"
            defaultOptions={watch("tourneeDessertes")}
            limitTags={5}
          />
        </Grid>
      </Grid>
    </UtilitaireModal>
  );
};

const AddJonctionModal = withForm(
  AddJonctionForm,
  yup.object({
    dessertes: yup.array().min(1).required("Au moins une desserte"),
    lieu: yup.string().required().nullable(),
    centre: yup.object().required().nullable(),
    tournee: yup.object().required().nullable(),
  })
);

type AddJonctionProps = {
  tourneeDateDepart: string;
  tourneeId: string;
  tourneeDessertes: DesserteResource[];
  closeModal: () => void;
  onFinished: (data: JonctionResource) => void;
};

export const AddJonction: FC<AddJonctionProps> = ({
  tourneeDateDepart,
  tourneeId,
  tourneeDessertes,
  closeModal,
  onFinished,
}) => {
  const modifierEquipage = (data: Record<string, any>) => {
    return jonctionService.create({
      lieu: data.lieu,
      centreId: selectHelper.getValue(data.centre),
      tourneeJonctionId: selectHelper.getValue(data.tournee),
      tourneeId,
      dessertes: data.dessertes.map(selectHelper.getValue),
    });
  };

  return (
    <AddJonctionModal
      onSave={modifierEquipage}
      onBack={closeModal}
      onFinished={onFinished}
      defaultValues={{
        // tourneeDateDepart : dateFormater.toBackPattern(tourneeDateDepart),
        tourneeDessertes,
      }}
    />
  );
};
