import { FC, Fragment } from "react";
import { Grid } from "@mui/material";
import {
  MontantFormat,
  MontantFormatText,
  TextInput,
  ThousandFormat,
} from "components";
import { useFormContext } from "react-hook-form";
import { desserteHelper, numberHelper } from "utils/helpers";
import { ViewItem2 } from "pages/common";

type TDFProps = {
  hasTdf: boolean;
};

export const MontantTransportDeFondIntraMuros: FC<TDFProps> = ({ hasTdf }) => {
  const { watch } = useFormContext();
  const modeFacture = watch("transport.modeFacture");

  const isParDesserte = desserteHelper.isFacturationParDesserte(modeFacture);

  const isMensuel = desserteHelper.isFacturationMensuel(modeFacture);

  const isPourcentageColis =
    desserteHelper.isFacturationPourcentage(modeFacture);

  return (
    <Fragment key="intramuros_facturation">
      {isParDesserte && (
        <Fragment key="intramuros_facturation_par_desserte">
          <Grid item xs={12} md={5}>
            <TextInput
              label="FCFA / Desserte"
              name="transport.montantForfaitaire"
              InputProps={{
                inputComponent: MontantFormat as any,
              }}
              disabled={!hasTdf}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextInput
              label="FCFA / Dim. et jr. férié"
              name={`transport.montantJrFerie`}
              InputProps={{
                inputComponent: MontantFormat as any,
              }}
              disabled={!hasTdf}
            />
          </Grid>
        </Fragment>
      )}
      {isMensuel && (
        <Fragment key="intramuros_facturation_mensuel">
          <Grid item xs={12} md={5}>
            <TextInput
              label="FCFA / Mensuel"
              name="transport.montantMensuel"
              InputProps={{
                inputComponent: MontantFormat as any,
              }}
              disabled={!hasTdf}
            />
          </Grid>
        </Fragment>
      )}
      {isPourcentageColis && (
        <Fragment key="intramuros_facturation_pourcentageColis">
          <Grid item xs={12} md={5}>
            <TextInput
              label="Pourcentage colis "
              name="transport.pourcentageColis"
              InputProps={{
                inputComponent: MontantFormat as any,
              }}
              disabled={!hasTdf}
              placeholder="En pourcentage entre 1 et 100"
            />
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
};

export const MontantTransportDeFondExtraMuros: FC<TDFProps> = ({ hasTdf }) => {
  const { watch } = useFormContext();
  const montant = desserteHelper.computeMontant({
    montantKmVb: numberHelper.parseInt(watch("transport.montantKmVb")),
    kmVoieBitumee: numberHelper.parseInt(watch("kmVoieBitumee")),
    montantKmVnb: numberHelper.parseInt(watch("transport.montantKmVnb")),
    kmVoieNonBitumee: numberHelper.parseInt(watch("kmVoieNonBitumee")),
  });

  return (
    <Fragment key="extramuros_facturation">
      <Grid item xs={12} md={3}>
        <TextInput
          label="Bitume FCFA / Km"
          name={"transport.montantKmVb"}
          InputProps={{
            inputComponent: MontantFormat as any,
          }}
          disabled={!hasTdf}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextInput
          label="Km voie bitumée"
          name="kmVoieBitumee"
          InputProps={{
            inputComponent: ThousandFormat as any,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextInput
          label="Piste FCFA / Km"
          name={`transport.montantKmVnb`}
          InputProps={{
            inputComponent: MontantFormat as any,
          }}
          // disabled={isIntraMuros || !hasTdf}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextInput
          label="Km piste"
          name="kmVoieNonBitumee"
          InputProps={{
            inputComponent: ThousandFormat as any,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <ViewItem2
          label="Montant desserte"
          contentSx={{ fontWeight: 700 }}
          labelSx={{ fontWeight: 400 }}
        >
          <MontantFormatText value={montant} />
        </ViewItem2>
      </Grid>
    </Fragment>
  );
};
