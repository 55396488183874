import { FormattedMessage } from "react-intl";

import ApartmentIcon from "@mui/icons-material/Apartment";
import { UserModule } from "types";
import { filterSousMenu } from "menu-items";
import { LINKS } from "utils/constant";
import { Person } from "@mui/icons-material";

const sousmenus = [
  {
    id: "clients",
    title: <FormattedMessage id="menu.clientele.client" />,
    type: "item",
    url: "/clients",
    icon: Person,
    caption: "Consulter et gérer les clients",
  },
  {
    id: "sites",
    title: <FormattedMessage id="menu.clientele.site" />,
    type: "item",
    url: "/sites",
    caption: "Consulter et gérer les sites",
  },
  {
    id: "factures",
    title: "Factures",
    type: "item",
    url: LINKS.factures.list,
    caption: "Consulter et gérer les factures",
  },
];

export const buildClienteleMenu = (userModule: UserModule) => {
  const data = filterSousMenu(userModule, sousmenus);

  if (data.length) {
    return {
      id: "group-clients",
      type: "group",
      children: [
        {
          id: "menu.clientele.title",
          title: <FormattedMessage id="menu.clientele.title" />,
          type: "collapse",
          icon: ApartmentIcon,
          children: data,
        },
      ],
    };
  }
  return null;
};
