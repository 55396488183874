import { Grid } from "@mui/material";
import { SelectDeviseAsycForm, TextInput } from "components";
import { withForm, WrapperProps } from "hoc";
import { FC } from "react";
import { UtilitaireModal } from "../UtilitaireModal";
import * as yup from "yup";

const schema = yup.object({
  nationalite: yup.string().required(),
  libelle: yup.string().required(),
  code: yup.string().required(),
});

const Form: FC<WrapperProps> = (props) => {
  return (
    <UtilitaireModal {...props} onClose={props.onBack} title="Gestion du pays">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextInput label="Nom *" name="libelle" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput label="Code *" name="code" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput label="Nationalité *" name="nationalite" />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="TVA *"
            name="tva"
            helperText="Entre 0 et 1 (Exemple 0.25)"
          />
        </Grid>
        <Grid item xs={12}>
          <SelectDeviseAsycForm label="Devise *" name="devise" />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Temps d'attente *"
            name="tempsAttente"
          />
        </Grid>
      </Grid>
    </UtilitaireModal>
  );
};

export const PaysModal = withForm(Form, schema);
