import { Delete, FolderCopy } from "@mui/icons-material";
import {
  Checkbox,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import { FC } from "react";
import { TrajetResource } from "types/transport.types";
import { CODE_ETAT_TRAJET } from "utils/constant";

type PlanificationTrajetItemProps = {
  trajet: TrajetResource;
  isChecked: boolean;
  withDuplicate?: boolean;
  withDelete?: boolean;
  disabled?: boolean;
  handleToggle: (trajet: TrajetResource) => void;
  onDuplicate: (trajet: TrajetResource) => void;
  onDelete: (trajet: TrajetResource) => void;
};

export const PlanificationTrajetItem: FC<PlanificationTrajetItemProps> = ({
  isChecked,
  trajet,
  withDuplicate = false,
  withDelete = false,
  handleToggle,
  onDuplicate,
  onDelete,
  disabled,
}) => {
  return (
    <ListItemButton
      alignItems="center"
      onClick={() => {
        handleToggle(trajet);
      }}
      divider
      disabled={disabled}
      sx={{
        py: 1.5,
        px: 1,
        "&.MuiListItem-root": {
          bgcolor: isChecked ? (t) => t.palette.grey[100] : "inherent",
          "&:hover": {
            bgcolor: (t) => t.palette.grey[50],
          },
        },
        "&:last-child": {
          border: 0,
        },
        ".actions": {
          display: "none",
        },
        "&:hover": {
          ".actions": {
            display: "block",
          },
        },
      }}
    >
      <ListItemIcon sx={{ marginRight: 0.75 }}>
        <Checkbox checked={isChecked} />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            component="span"
            fontWeight={600}
            sx={{
              color:
                trajet.etat === CODE_ETAT_TRAJET.new_site
                  ? "#c2c2c2"
                  : "inherit",
            }}
          >
            {trajet.nomSite}
          </Typography>
        }
      />

      <Stack flexDirection="row" className="actions">
        {withDuplicate && (
          <Tooltip title="Dupliquer le site">
            <IconButton
              size="small"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                onDuplicate(trajet);
              }}
            >
              <FolderCopy />
            </IconButton>
          </Tooltip>
        )}
        {withDelete && trajet.etat === "new_site" && (
          <Tooltip title="Supprimer le site">
            <IconButton
              size="small"
              color="error"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(trajet);
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </ListItemButton>
  );
};
