import { ActivityTable } from "components";
import { useUser } from "hooks";
import { FC } from "react";
import { hasRoleAdmin } from "utils/user.helpers";

type ListEventHistoriqueProps = {
  events?: any[];
  isOpen?: boolean;
};

const columns = [
  {
    Header: "ID",
    accessor: "userId",
  },
  {
    Header: "Utilisateur",
    accessor: "userName",
  },

  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Action",
    accessor: "action",
  },
  {
    Header: "Message",
    accessor: "message",
  },
];

export const ListEventHistorique: FC<ListEventHistoriqueProps> = ({
  events,
  isOpen,
}) => {
  const { user } = useUser();
  const isAdmin = hasRoleAdmin(user);

  if (isAdmin) {
    return (
      <ActivityTable columns={columns} data={events || []} isOpen={isOpen} />
    );
  }
  return null;
};
