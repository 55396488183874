import { FC } from "react";
import { referentielService } from "services";
import { REFERENTIEL_TYPE, RequestParam } from "types";
import { SelectAsyncProps } from "types/select-async.type";
import { buildSelectItem } from "utils/helpers";
import { AutoCompleteAsyncHookForm } from "../AutoComplete";

interface SelectReferentielAsyncProps extends SelectAsyncProps {
  type: REFERENTIEL_TYPE;
  codes?: string[];
}

export const SelectReferentielAsycForm: FC<SelectReferentielAsyncProps> = ({
  type,
  codes,
  ...props
}) => {
  const fetchData = async () => {
    const params: RequestParam = { type };
    if (codes) {
      params.code = codes.join(",");
    }
    const { data } = await referentielService.findAll(params);
    return {
      data: data.map(({ id, name }) => buildSelectItem(name, id)),
      hasMore: false,
    };
  };
  return <AutoCompleteAsyncHookForm {...props} fetchData={fetchData} />;
};
