import React, { useState } from "react";

// material-ui
import {
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";

// assets
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { authService } from "services";
import { useNotification } from "hooks";
import { LoadingButton } from "components";
import { useLocation, useNavigate } from "react-router-dom";

export const InitPasswordForm = () => {

  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      newPassword: "",
      confirmPassword: "",
    },
  });
  const notification = useNotification();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const resetPassword = (data: any) => {
    data.token = token;
    setLoading(true);
    authService
      .resetpassword(data)
      .then((data: any) => {
        setLoading(false);
        notification.success({
          title: "Bravo",
          message: data.message,
        })
        navigate("/login");
      })
      .catch(({ status }) => {
        setLoading(false);
        if (status === 404) {
          notification.error({
            title: "Echec",
            message: "Aucun utilisateur ne correspond aux informations fournies au serveur",
          });
        } else {
          notification.error({
            title: "Erreur technique",
            message: "Une erreur technique est survenue lors du traitement",
          });
        }
      });
  };

  const loginPage = () => {
    navigate("/login");
  }

  return (
    <form onSubmit={handleSubmit(resetPassword)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <InputLabel htmlFor="newPassword">Nouveau mot de passe</InputLabel>
            <OutlinedInput
              fullWidth
              error={Boolean(errors.newPassword)}
              id="newPassword"
              type={showPassword ? "text" : "password"}
              {...register("newPassword", { required: true })}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                    size="large"
                  >
                    {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                  </IconButton>
                </InputAdornment>
              }
              placeholder="Votre nouveau mot de passe"
            />
            {errors.newPassword && (
              <FormHelperText error id="newPassword">
                {errors.newPassword?.message?.toString()}
              </FormHelperText>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <InputLabel htmlFor="confirmPassword">Confirmez le mot de passe</InputLabel>
            <OutlinedInput
              fullWidth
              error={Boolean(errors.confirmPassword)}
              id="confirmPassword"
              type={showPassword ? "text" : "password"}
              {...register("confirmPassword", { required: true })}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                    size="large"
                  >
                    {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                  </IconButton>
                </InputAdornment>
              }
              placeholder="Confirmez le mot de passe"
            />
            {errors.confirmPassword && (
              <FormHelperText error id="confirmPassword">
                {errors.confirmPassword?.message?.toString()}
              </FormHelperText>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            disableElevation
            disabled={false}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            loading={loading}
          >
            ENREGISTRER
          </LoadingButton>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Typography
              sx={{
                "&:hover": {
                  textDecoration: "underline",
                  cursor: "pointer",
                },
              }}
              onClick={loginPage}
              component="span"
              marginBottom={0}
            >
              Retour à la page de connexion
            </Typography>
        </Grid>
      </Grid>
    </form>
  );
};
