import { FC } from "react";
import { ColisResource, DesserteResource } from "types/transport.types";
import DesserteInformationGenerale from "./DesserteInformationGenerale";
import DesserteFacturation from "./DesserteFacturation";
import MainCard from "components/MainCard";
import { ListDesserteColis } from "./ListDesserteColis";
import { DesserteSignatures } from "./DesserteSignatures";
import { DesserteIncident } from "./DesserteIncident";
import { ViewIcons } from "pages/common";
import { Collapse } from "components";
import { CODE_REFERENTIEL } from "types";
import { useUser } from "hooks";
import { ListEventHistorique } from "components/historique";

type DetailDesserteProps = {
  desserte: DesserteResource;
  updateColis?: (colis: ColisResource) => void;
};

const DetailDesserte: FC<DetailDesserteProps> = ({ desserte, updateColis }) => {
  const { canReadOrWrite } = useUser();
  const canReadFactures = canReadOrWrite("factures");
  return (
    <>
      <DesserteInformationGenerale desserte={desserte} />
      {canReadFactures && <DesserteFacturation desserte={desserte} />}
      <MainCard sx={{ marginBottom: 3 }}>
        <Collapse
          title="Colis"
          subtitle="Liste des colis pris en charge"
          leftIcon={ViewIcons.colis}
        >
          <ListDesserteColis
            colis={desserte.colis}
            updateColis={updateColis}
            canEdit={desserte.etatDesserte?.id !== CODE_REFERENTIEL.LIVRE}
          />
        </Collapse>
      </MainCard>
      <MainCard sx={{ marginBottom: 3 }}>
        <DesserteSignatures signatures={desserte.signatures} />
      </MainCard>
      <MainCard sx={{ marginBottom: 3 }}>
        <DesserteIncident desserteId={desserte.id} />
      </MainCard>
      <ListEventHistorique events={desserte.events} />
    </>
  );
};

export default DetailDesserte;
