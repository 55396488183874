import { Cancel, CheckCircle } from "@mui/icons-material";
import { MoreMenu } from "components";
import { DemarrerIcon, TerminerIcon } from "pages/common";
import { LivrerColis, RamasserColis } from "pages/tournees/dessertes";
import { FC, useState } from "react";
import {
  DesserteResource,
  EquipageResource,
  TrajetResource,
} from "types/transport.types";
import { CODE_ETAT_TRAJET } from "utils/constant";
import { AnnulerPassage } from "./AnnulerPassage";
import { DeleteTrajet } from "./DeleteTrajet";
import { PasserSite } from "./PasserSite";
import { TrajetItem } from "./ViewTrajet";

type TrajetOptionsProps = {
  trajet: TrajetResource;
  dessertes: DesserteResource[];
  equipage: EquipageResource;
  onFinished: () => void;
};

export const TrajetOptions: FC<TrajetOptionsProps> = ({
  trajet,
  dessertes,
  equipage,
  onFinished,
}) => {
  const [action, setAction] = useState<string | undefined>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeModal = () => {
    setAction(undefined);
  };

  const isClosed = [
    CODE_ETAT_TRAJET.passe,
    CODE_ETAT_TRAJET.annule,
    CODE_ETAT_TRAJET.ignore,
  ].includes(trajet.etat);
  const isEquipageActif = !!equipage.actif;

  return (
    <>
      <TrajetItem
        trajet={trajet}
        handleClick={handleClick}
        onDelete={
          [CODE_ETAT_TRAJET.non_passe, CODE_ETAT_TRAJET.annule].includes(
            trajet.etat
          )
            ? () => setAction("delete")
            : undefined
        }
      />
      <MoreMenu
        actions={[
          {
            code: "ramasser",
            title: "Ramasser",
            subtitle: "Prendre en charge les colis sur ce site",
            Icon: <DemarrerIcon color="info" />,
            disabled: isClosed || !isEquipageActif,
          },
          {
            code: "passer",
            title: "Passer",
            subtitle: "Passer au prochain site ",
            Icon: <CheckCircle color="primary" />,
            disabled: isClosed || !isEquipageActif,
          },
          {
            code: "livraison",
            title: "Livrer",
            subtitle: "Livrer les colis sur le site",
            Icon: <TerminerIcon color="success" />,
            disabled: isClosed || !isEquipageActif,
          },
          {
            code: "annuler",
            title: "Annuler",
            subtitle: "Annuler passage sur le site",
            Icon: <Cancel color="error" />,
            disabled: isClosed || !isEquipageActif,
          },
        ]}
        anchorEl={anchorEl}
        onClose={handleClose}
        onSelected={({ code }) => setAction(code)}
      />

      {action === "ramasser" && (
        <RamasserColis
          dessertes={dessertes}
          trajet={trajet}
          equipageId={equipage.id}
          closeModal={closeModal}
          onFinished={() => {
            onFinished();
            closeModal();
          }}
        />
      )}

      {action === "livraison" && (
        <LivrerColis
          dessertes={dessertes}
          trajet={trajet}
          equipageId={equipage.id}
          closeModal={closeModal}
          onFinished={() => {
            onFinished();
            closeModal();
          }}
        />
      )}

      {action === "annuler" && (
        <AnnulerPassage
          trajet={trajet}
          equipageId={equipage.id}
          closeModal={closeModal}
          onFinished={() => {
            onFinished();
            closeModal();
          }}
        />
      )}

      {action === "delete" && (
        <DeleteTrajet
          trajet={trajet}
          equipageId={equipage.id}
          closeModal={closeModal}
          onFinished={() => {
            onFinished();
            closeModal();
          }}
        />
      )}

      {action === "passer" && (
        <PasserSite
          trajet={trajet}
          equipage={equipage}
          closeModal={closeModal}
          dessertes={dessertes}
          onFinished={() => {
            onFinished();
            closeModal();
          }}
        />
      )}
    </>
  );
};
