import { Grid } from "@mui/material";
import {
  SelectReferentielAsycForm,
  SelectTypeVehiculeAsyncForm,
  TextInput,
  OnlyChiffreFormat,
} from "components";
import { withForm, WrapperProps } from "hoc";
import { FC, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { CODE_REFERENTIEL, REFERENTIEL_TYPE } from "types";

import { UtilitaireModal } from "../UtilitaireModal";

const Form: FC<WrapperProps> = (props) => {
  const { watch, setValue } = useFormContext();
  const categorieId = watch("categorieId")?.value;
  const typeVehicule = watch("typeVehiculeId")?.label;

  const isTransport = useMemo(() => {
    return categorieId === CODE_REFERENTIEL.PRESTATION_TRANSPORT;
  }, [categorieId]);

  useEffect(() => {
    if (isTransport && typeVehicule) {
      setValue("libelle", `TDF ${typeVehicule}`);
    }
  }, [isTransport, setValue, typeVehicule]);

  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title={
        props.isEditMode ? "Gestion de la prestation" : "Créer une prestation"
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <SelectReferentielAsycForm
            type={REFERENTIEL_TYPE.PRESTATION}
            label="Catégorie *"
            name="categorieId"
          />
        </Grid>
        {isTransport && (
          <Grid item xs={12} md={6}>
            <SelectTypeVehiculeAsyncForm
              label="Type de véhicule *"
              name="typeVehiculeId"
            />
          </Grid>
        )}
        {!isTransport && (
        <Grid item xs={12} md={6}>
          <TextInput
            label="Montant"
            name="montant"
            InputProps={{
              inputComponent: OnlyChiffreFormat as any,
            }} />
        </Grid>
        )}
        <Grid item xs={12} md={6}>
          <TextInput label="Nom *" name="libelle" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput label="Code" name="code" disabled />
        </Grid>
      </Grid>
    </UtilitaireModal>
  );
};

export const PrestationModal = withForm(Form);
