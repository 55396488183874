// material-ui
import { Grid, Stack, Typography } from "@mui/material";

// assets
import AuthWrapper from "../AuthWrapper";
import { InitPasswordForm } from "./InitForm";

import Logo from "assets/images/logo.png";

// ============================|| FIREBASE - LOGIN ||============================ //

export const InitPassword = () => {
  return (
    <AuthWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack alignItems="center">
            <img width="120px" src={Logo} alt="Burval Logo" />
            <Typography
              color={(t) => t.palette.grey[900]}
              variant="subtitle1"
              sx={{ fontWeight: 700, marginTop: 1 }}
            >
              Burval Corporate
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <InitPasswordForm />
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};
