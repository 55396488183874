import ListBilleterie from "../billetsetpieces/ListBilleterie";

const ListBillet = () => {
  return (
    <ListBilleterie
      code="billets"
      libelle="Billets"
      description="Consulter et gérer les billets"
      btn={{ label: "Ajouter un billet" }}
    />
  );
};

export default ListBillet;
