import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { FC, ReactNode } from "react";

type PageTitleProps = {
  title?: string;
  TitleIcon?: any;
  subtitle?: string;
  onBack?: () => void;
  right?: ReactNode;
  sx?: SxProps<Theme>;
};

export const PageTitle: FC<PageTitleProps> = ({
  title,
  TitleIcon,
  subtitle,
  right,
  onBack,
  sx,
}) => {
  return (
    <Grid
      item
      display="flex"
      alignItems="center"
      sx={{
        padding: "0.5rem 0 2.5rem 0",
        ...(sx || {}),
      }}
    >
      {onBack && (
        <Button
          color="inherit"
          startIcon={<ArrowBack style={{ fontSize: "1.1rem" }} />}
          onClick={onBack}
          sx={{ marginRight: 1 }}
        >
          Retour
        </Button>
      )}
      <Stack>
        <Box component="div" display="flex" alignItems="center">
          {TitleIcon && (
            <TitleIcon
              sx={{
                marginRight: 1,
                fontSize: "1.85rem",
              }}
            />
          )}
          <Typography variant="h3" sx={{ fontWeight: 500 }}>
            {title}
          </Typography>
        </Box>
        {subtitle && <Typography>{subtitle}</Typography>}
      </Stack>
      <Box sx={{ marginLeft: "auto" }}>{right}</Box>
    </Grid>
  );
};
