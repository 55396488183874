import { FormattedMessage } from "react-intl";

import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import { LINKS } from "utils/constant";
import { filterSousMenu } from "menu-items";
import { UserModule } from "types";

const sousmenus = [
  {
    id: "dessertes",
    title: <FormattedMessage id="menu.transport.desserte.title" />,
    type: "item",
    url: LINKS.dessertes.list,
    caption: "Consulter et gérer les dessertes",
  },
  {
    id: "tournees",
    title: <FormattedMessage id="menu.transport.tournee.title" />,
    type: "item",
    url: LINKS.tournees.list,
    caption: "Consulter et gérer les tournées",
  },
  {
    id: "recus",
    title: "Reçus",
    type: "item",
    url: LINKS.recus.list,
    caption: "Consulter et gérer les reçus",
  },
];

export const buildTransportMenu = (userModule: UserModule) => {
  const data = filterSousMenu(userModule, sousmenus);

  if (data.length) {
    return {
      id: "group-transport",
      type: "group",
      children: [
        {
          id: "menu.transport.title",
          title: <FormattedMessage id="menu.transport.title" />,
          type: "collapse",
          icon: EmojiTransportationIcon,
          children: data,
        },
      ],
    };
  }
  return null;
};
