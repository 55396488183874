import { ArrowRightAlt } from "@mui/icons-material";
import { Stack, SxProps, Typography } from "@mui/material";

import { FC } from "react";
import { DesserteResource } from "types/transport.types";

type DesserteSensProps = {
  desserte: DesserteResource;
  libelleSx?: SxProps;
};

export const DesserteSens: FC<DesserteSensProps> = ({
  desserte,
  libelleSx,
}) => {
  return (
    <DesserteTrajetSens
      nomSiteDepart={desserte?.siteDepart?.nom}
      nomSiteArrivee={desserte?.siteArrivee?.nom}
      libelleSx={libelleSx}
    />
  );
};

type DesserteTrajetSensProps = {
  nomSiteDepart?: string;
  nomSiteArrivee?: string;
  libelleSx?: SxProps;
};

export const DesserteTrajetSens: FC<DesserteTrajetSensProps> = ({
  nomSiteDepart,
  nomSiteArrivee,
  libelleSx,
}) => {
  return (
    <Stack spacing={0} padding={0} flexDirection="row">
      <Typography component="span" sx={libelleSx} fontWeight={600}>
        {nomSiteDepart}
      </Typography>
      &nbsp;
      <Typography component="span" fontWeight={600}>
        <ArrowRightAlt fontSize="small" />
      </Typography>
      &nbsp;
      <Typography component="span" sx={libelleSx} fontWeight={600}>
        {nomSiteArrivee}
      </Typography>
    </Stack>
  );
};
