import { FC, useCallback } from "react";
import { Collapse } from "components";
import MainCard from "components/MainCard";
import { DesserteInfoGeneraleForm } from "./DesserteInfoGeneraleForm";
import { useFormContext } from "react-hook-form";
import { PageTitle, ViewIcons } from "pages/common";
import { withForm, WrapperProps } from "hoc";
import { DesserteFacturation } from "./DesserteFacturation";
import { siteService } from "services";
import { selectHelper } from "utils/helpers";
import { SitePrestationResource } from "types/facturation.types";
import { useNotification } from "hooks";
import { Box } from "@mui/material";
import { desserteSchema } from "./desserteSchema";
import { CODE_REFERENTIEL } from "types";
import { dateParser } from "utils/date";
// import { DesserteEscaleForm } from "./DesserteEscaleForm";

const Form: FC<WrapperProps> = ({
  isEditMode,
  renderButtons,
  onBack,
  onSubmit,
}) => {
  const { watch, setValue, clearErrors } = useFormContext();
  const notification = useNotification();

  const setSitePrestation = useCallback(
    (siteFacture?: any, prestation?: SitePrestationResource) => {
      setValue("siteFacture", siteFacture);
      setValue("kmVoieBitumee", siteFacture?.kmVoieBitumee || "");
      setValue("kmVoieNonBitumee", siteFacture?.kmVoieNonBitumee || "");

      const typeVehicule =
        prestation?.clientPrestation?.prestation?.typeVehicule;

      setValue(
        "typeVehicule",
        selectHelper.build(typeVehicule?.code, typeVehicule?.id)
      );

      setValue(
        "typeDesserte",
        selectHelper.buildReferentiel(prestation?.typeDesserte)
      );

      if (prestation?.typeDesserte) {
        const isAppro = prestation.typeDesserte.id === CODE_REFERENTIEL.APPRO;
        const { heureDebutPassage, heureFinPassage } =
          (isAppro ? watch("siteArrivee") : watch("siteDepart")) || {};

        setValue(
          "heureDebutPassage",
          dateParser.createDateFromTime(heureDebutPassage)
        );
        setValue(
          "heureFinPassage",
          dateParser.createDateFromTime(heureFinPassage)
        );
      }

      setValue(
        "transport.montantForfaitaire",
        prestation?.montantForfaitaire || ""
      );
      setValue("transport.montantKmVb", prestation?.montantKmVb || "");
      setValue("transport.montantKmVnb", prestation?.montantKmVnb || "");
      setValue("transport.montantJrFerie", prestation?.montantJrFerie || "");
      setValue("transport.montantMensuel", prestation?.montantMensuel || "");
      setValue(
        "transport.pourcentageColis",
        prestation?.pourcentageColis || ""
      );

      if (prestation) {
        setValue("transport.intramuros", prestation.intramuros.toString());
        setValue("transport.modeFacture", prestation.modeFacture.toString());
      } else {
        setValue("transport.intramuros", "1");
        setValue("transport.modeFacture", "1");
      }
      clearErrors([
        "transport.intramuros",
        "transport.modeFacture",
        "typeDesserte",
        "typeVehicule",
        "siteFacture",
      ]);
    },
    [clearErrors, setValue, watch]
  );

  const loadSitePrestation = useCallback(
    (siteFacture?: any) => {
      if (!siteFacture) {
        return Promise.resolve();
      }
      if (!siteFacture.client) {
        setSitePrestation();
        notification.error({
          title: "Le site selectionné n'est pas facturable",
        });
        return Promise.resolve();
      }
      return siteService.findPrestations(siteFacture.value).then((data) => {
        const prestation = data.find(
          (item) =>
            item.clientPrestation?.prestation?.categorie?.id ===
            "prestation_transport"
        );
        setSitePrestation(siteFacture, prestation);
        if (!prestation) {
          notification.error({
            title:
              "Le site selectionné n'a pas de prestation lié au transport de fond",
          });
        }
      });
    },
    [notification, setSitePrestation]
  );

  return (
    <>
      <form onSubmit={onSubmit}>
        <PageTitle
          subtitle={isEditMode ? `N° ${watch("numero")}` : ""}
          title={`${isEditMode ? "Modifier" : "Créer"} une desserte`}
          onBack={onBack}
          right={renderButtons()}
        />

        <MainCard sx={{ marginBottom: 3 }}>
          <Collapse
            title="Information générale"
            subtitle="Renseigner les informations générales de la desserte"
            leftIcon={ViewIcons.infoGenerale}
          >
            <DesserteInfoGeneraleForm setSitePrestation={loadSitePrestation} />
          </Collapse>
        </MainCard>

        <MainCard sx={{ marginBottom: 3 }}>
          <Collapse
            title="Facturation"
            subtitle="Renseigner les informations liées à la facturation de la desserte"
            leftIcon={ViewIcons.facture}
          >
            <DesserteFacturation />
          </Collapse>
        </MainCard>

        {/* <MainCard sx={{ marginBottom: 3 }}>
          <Collapse
            title="Dessertes escales"
            subtitle="Renseigner les dessertes en escale"
            leftIcon={ViewIcons.escale}
          >
            <DesserteEscaleForm />
          </Collapse>
        </MainCard> */}
        <Box sx={{ textAlign: "right" }}>{renderButtons()}</Box>
      </form>
    </>
  );
};

export const DesserteForm = withForm(Form, desserteSchema);
