import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
} from "@mui/material";
import { RadioGroupBox } from "components";
import { FC } from "react";

type TDFProps = {
  hasTdf: boolean;
};

export const TransportDeFondModeFacture: FC<TDFProps> = ({ hasTdf }) => {
  return (
    <Grid item xs={12} sm={6} md={8} key="mode_facture">
      <FormControl disabled={!hasTdf}>
        <FormLabel id="mode_forfait">Mode facturation</FormLabel>
        <RadioGroupBox
          name="transport.modeFacture"
          sx={{ display: "flex", flexDirection: "row" }}
        >
          <FormControlLabel
            value="1"
            control={<Radio />}
            label="Par desserte"
          />
          <FormControlLabel value="0" control={<Radio />} label="Mensuel" />
          <FormControlLabel
            value="2"
            control={<Radio />}
            label="Pourcentage colis"
          />
        </RadioGroupBox>
      </FormControl>
    </Grid>
  );
};
