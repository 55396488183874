import { FC, ReactNode, HTMLAttributes, Fragment } from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import MuiAutocomplete from "@mui/material/Autocomplete";
import { Box, Divider } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { SelectItem } from "types/form.type";
import { getJsonValue } from "utils/helpers";

type AutoCompleteAsyncProps = {
  name: string;
  renderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: SelectItem
  ) => ReactNode;
  setValue?: (name: string, newValue: SelectItem[]) => void;
  hasError?: boolean;
  errorMessage?: string;
  value?: SelectItem[];
  options?: SelectItem[];
  resetDependencies?: any;
  afterSelected?: (name: string, value: any) => void;
};

export const MultiAutoComplete: FC<AutoCompleteAsyncProps & TextFieldProps> = ({
  label,
  name,
  renderOption,
  setValue,
  hasError,
  errorMessage,
  value,
  options,
  size = "small",
  disabled,
}) => {
  return (
    <>
      <MuiAutocomplete
        multiple
        limitTags={10}
        options={options || []}
        disableCloseOnSelect
        value={value || []}
        size={size}
        getOptionLabel={(option) => option.label}
        disabled={disabled}
        id={`select.${name}`}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderOption={(props: any, option) => (
          <Fragment key={option.value}>
            <Box component="li" {...props}>
              {renderOption ? renderOption(props, option) : option.label}
            </Box>
            {props["data-option-index"] ===
            (options || []).length - 1 ? null : (
              <Divider />
            )}
          </Fragment>
        )}
        onChange={(event: any, newValue) => {
          setValue && setValue(name, newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            error={hasError}
            helperText={errorMessage || ""}
          />
        )}
        sx={{
          "& .MuiAutocomplete-tag": {
            bgcolor: (t) => t.palette.grey[100],
            border: "1px solid",
            borderColor: (t) => t.palette.grey[400],
            "& .MuiSvgIcon-root": {
              color: "primary.main",
              "&:hover": {
                color: "primary.dark",
              },
            },
          },
          "& .MuiInputBase-sizeSmall": {
            input: {
              padding: "8px 8px 8px 8px !important",
            },
          },
        }}
        noOptionsText="Aucun résultat"
      />
    </>
  );
};

export const MultiAutoCompleteHookForm: FC<
  AutoCompleteAsyncProps & TextFieldProps
> = (props) => {
  const {
    formState: { errors },
    setValue,
    clearErrors,
    watch,
  } = useFormContext();

  const error = getJsonValue(errors, props.name);
  return (
    <MultiAutoComplete
      {...props}
      setValue={(name, value) => {
        setValue(name, value, { shouldDirty: true, shouldTouch: true });
        clearErrors(name);
        props.afterSelected && props.afterSelected(name, value);
      }}
      value={watch(props.name)}
      hasError={!!error}
      errorMessage={error?.message?.toString() || ""}
    />
  );
};
