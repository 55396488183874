// assets

import { useMemo } from "react";
import { LINKS } from "utils/constant";
import UtiliataireTemplate from "./UtiliataireTemplate";

export const ActiviteNavigation = () => {
  const links = useMemo(() => {
    return [
      {
        label: "Prestations",
        to: LINKS.activites.prestations,
      },
      {
        label: "Secteur d'activités",
        to: LINKS.activites.secteurActivites,
      },
      {
        label: "Services",
        to: LINKS.activites.services,
      },
      {
        label: "Fonctions",
        to: LINKS.activites.fonctions,
      },
      {
        label: "Jours fériés",
        to: LINKS.activites.joursferies,
      },
    ];
  }, []);

  return <UtiliataireTemplate links={links} />;
};
