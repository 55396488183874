// assets

import { useMemo } from "react";
import { LINKS } from "utils/constant";
import UtiliataireTemplate from "./UtiliataireTemplate";

export const SsbNavigation = () => {
  const links = useMemo(() => {
    return [
      {
        label: "Catégories",
        to: LINKS.ssb.ticketCategories,
      },
      {
        label: "Types",
        to: LINKS.ssb.ticketTypes,
      },
      {
        label: "Cassettes",
        to: LINKS.ssb.cassettes,
      },
    ];
  }, []);

  return <UtiliataireTemplate links={links} />;
};
