import * as yup from "yup";

import { isAfter, isEqual } from "date-fns";
import { validationHelper } from "utils/validationHelper";
import { numberHelper } from "utils/helpers";

export const colisValidation = {
  numero: yup.string().required(),
  montant: yup.string().required(),

  sousColis: yup.array().of(
    yup.object().shape({
      numero: yup.string().required(),
      montant: yup.string().required(),
    })
  ),
};

const departValidation = {
  dateDepart: yup.date().required().nullable(),
  heureDepart: yup.date().required().nullable(),
  kmDepart: yup.string().nullable(),
  colis: yup
    .array()
    .of(yup.object().shape(colisValidation))
    .min(1, "Renseigner au moins un colis"),
};

export const livrerDesserteSchema = yup.object({
  ...departValidation,

  dateArrivee: yup
    .date()
    .when(["dateDepart", "etat"], {
      is: (dateDepart: any, etat: string) =>
        !!dateDepart && etat === "livraison",
      then: () => {
        return yup
          .date()
          .nullable()
          .required()
          .test({
            message: "Doit être après la date d'expédition",
            test: (dateArrivee, ctx) =>
              !dateArrivee
                ? true
                : isAfter(dateArrivee, ctx.parent.dateDepart) ||
                  isEqual(dateArrivee, ctx.parent.dateDepart),
          });
      },
    })
    .nullable(),

  heureArrivee: yup
    .date()
    .when(["heureDepart", "etat"], {
      is: (heureDepart: any, etat: string) =>
        !!heureDepart && etat === "livraison",
      then: () => {
        return yup
          .date()
          .nullable()
          .required()
          .test({
            message: "Doit être après l'heure d'expédition",
            test: (heureArrivee, ctx) =>
              !heureArrivee
                ? true
                : isAfter(heureArrivee, ctx.parent.heureDepart) ||
                  isEqual(heureArrivee, ctx.parent.heureDepart),
          });
      },
    })
    .nullable(),

  kmArrivee: yup
    .string()
    .when(["kmDepart", "etat"], {
      is: (kmDepart: any, etat: string) => !!kmDepart && etat === "livraison",
      then: () => {
        return yup
          .string()
          .nullable()
          .test({
            message: "Doit être supérieur au KM de départ",
            test: (kmArrivee, ctx) => {
              return !kmArrivee
                ? true
                : parseInt(numberHelper.trim(kmArrivee) || "0") >
                    parseInt(numberHelper.trim(ctx.parent.kmDepart) || "0");
            },
          });
      },
    })
    .nullable(),
});

export const desserteSchema = yup.object({
  client: yup.object().required().nullable(),
  provenance: yup.object().required().nullable(),
  etatDesserte: yup.object().when("id", {
    is: (v?: string) => !!v,
    then: yup.object().required().nullable(),
  }),
  typeDesserte: yup.object().required().nullable(),
  centre: yup.object().required().nullable(),
  siteDepart: yup.object().required().nullable(),
  dateDepart: yup.date().required().nullable(),
  siteArrivee: yup
    .object()
    .required()
    .test({
      message: "Doit être différent du site d'expédition",
      test: (siteArrivee, ctx) =>
        siteArrivee?.value !== ctx.parent.siteDepart?.value,
    })
    .nullable(),

  siteFacture: yup.object().required().nullable(),
  typeVehicule: yup.object().required().nullable(),
  heureFinPassage: validationHelper.heureFinPassage,
  // ...dateArriveeValidator,
});
