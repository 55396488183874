import { startOfTomorrow } from "date-fns";
import { useNotification } from "hooks";
import { DuplicateTourneeModal } from "pages/tournees/form";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { tourneeService } from "services";
import { TourneeResource } from "types/transport.types";
import { LINKS } from "utils/constant";
import { dateFormater } from "utils/date";

type DuplicationTourneeProps = {
  tournee: TourneeResource;
  onClose: () => void;
};

export const DuplicationTournee: FC<DuplicationTourneeProps> = ({
  tournee,
  onClose,
}) => {
  const notification = useNotification();
  const navigate = useNavigate();

  return (
    <DuplicateTourneeModal
      onBack={onClose}
      onFinished={(result) => {
        onClose();
        navigate(LINKS.tournees.view(result.id));
      }}
      onSave={(data) =>
        tourneeService
          .duplicate(tournee.id, {
            dateDepart: dateFormater.toBackPattern(data.dateDepart),
          })
          .then((result) => {
            notification.success({
              title: `Tournée dupliquée avec succès`,
              message: `La nouvelle tournée a été crée avec le numéro ${result.data.numero}`,
            });
            return result.data;
          })
      }
      defaultValues={{
        dateDepart: startOfTomorrow(),
        numero: tournee.numero,
      }}
      showSuccessNotification={false}
    />
  );
};
