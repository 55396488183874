import { DesserteResource, EquipageResource } from "types/transport.types";
import { useFormContext } from "react-hook-form";
import { withForm, WrapperProps } from "hoc";
import { FC, useContext, useEffect, useState } from "react";

import * as yup from "yup";
import { UtilitaireModal } from "pages/utilitaires";
import { Collapse, SelectSiteAsyncForm } from "components";
import { Alert, Grid } from "@mui/material";
import { PlanificationTrajetPanel } from "pages/tournees/form";
import { desserteService, tourneeService } from "services";
import { TrajetItem, ViewTrajet } from "pages/tournees/view/trajet";
import { TrajetIcon } from "pages/common";
import { useDispatch } from "react-redux";
import { closeLoader, showLoader } from "reducers";
import { TrajetContext } from "pages/tournees/context";
import MainCard from "components/MainCard";
import { selectHelper } from "utils/helpers";
import { DesserteSens } from "./DesserteSens";
import { useNotification } from "hooks";
import { reBuildTrajets, useEditTrajet } from "pages/tournees/common";
import { CODE_REFERENTIEL } from "types";

const Form: FC<WrapperProps> = (props) => {
  const { watch } = useFormContext();
  const desserte = watch("desserte");
  const { changeDestination } = useEditTrajet();
  const { trajets } = useContext(TrajetContext);

  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title="Changer destination"
      maxWidth="lg"
    >
      <Alert
        color="primary"
        security="info"
        variant="border"
        sx={{ marginBottom: 3 }}
      >
        Vous êtes sur le point de changer la destination de la desserte&nbsp;
        <strong>N° {desserte.numero}</strong>
        <br />
        <DesserteSens desserte={desserte} />
      </Alert>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SelectSiteAsyncForm
                name="siteArrivee"
                label="Nouvelle destination *"
                afterSelected={(_, selected) => changeDestination(selected)}
                queryParams={{
                  transportDeFond: "1",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <MainCard>
                <Collapse
                  title="Trajet"
                  subtitle="Itinéraire à suivre par l'équipe"
                  leftIcon={<TrajetIcon color="primary" />}
                >
                  <ViewTrajet
                    trajets={trajets}
                    renderItem={(trajet) => (
                      <TrajetItem
                        trajet={trajet}
                        // onDelete={
                        //   trajet.idSite === desserte.siteArrivee.id
                        //     ? () => {}
                        //     : undefined
                        // }
                      />
                    )}
                  />
                </Collapse>
              </MainCard>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <PlanificationTrajetPanel title="Modifier le trajet" withDelete />
        </Grid>
      </Grid>
    </UtilitaireModal>
  );
};

const ChangeDesserteModal = withForm(
  Form,
  yup.object({
    trajets: yup.array().min(1).required("Le trajet est obligatoire"),
    siteArrivee: yup.object().required().nullable(),
  })
);

type DestinationDesserteProps = {
  desserte: DesserteResource;
  tourneeId?: string;
  onFinished: () => void;
  closeModal: () => void;
};

export const ChangerDestinationDesserte: FC<DestinationDesserteProps> = ({
  desserte,
  onFinished,
  closeModal,
  tourneeId,
}) => {
  const dispatch = useDispatch();
  const [equipage, setEquipage] = useState<EquipageResource | undefined>();
  const notification = useNotification();

  useEffect(() => {
    if (!tourneeId) {
      notification.error({
        message: "La desserte n'est pas liée à une tournée",
      });
      return;
    }
    dispatch(showLoader());

    Promise.all([
      desserteService.findEquipages(desserte.id),
      tourneeService.findDessertes(tourneeId),
    ])
      .then(([equipages, dessertes]) => {
        if (!equipages.length) {
          notification.error({ message: "Pas équipage pour la desserte" });
          return;
        }

        const equipage = equipages.find((e) => !e.motifChangement);

        if (!equipage) {
          notification.error({
            message: "Pas d'équipage avec motif changement à null",
          });
          return;
        }

        const hasDesserte =
          dessertes
            .filter((item) => item.id !== desserte.id)
            .filter((item) => {
              return (
                item.etatDesserte?.id !== CODE_REFERENTIEL.LIVRE &&
                (item.siteArrivee.id === desserte.siteArrivee.id ||
                  item.siteDepart.id === desserte.siteArrivee.id)
              );
            }).length > 0;

        const newEquipage = hasDesserte
          ? equipage
          : {
              ...equipage,
              trajets: equipage?.trajets?.filter(
                ({ idSite }) => idSite !== desserte.siteArrivee.id
              ),
            };
        setEquipage(newEquipage);
      })
      .finally(() => {
        dispatch(closeLoader());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changerDestination = (data: Record<string, any>) => {
    if (!equipage?.id || !tourneeId) {
      return Promise.reject({ message: "Il manque la tournée ou l'équipage" });
    }
    return tourneeService.changerDesserteDestination(
      tourneeId,
      equipage.id,
      desserte.id,
      {
        trajets: reBuildTrajets(data.trajets),
        siteArriveeId: selectHelper.getValue(data.siteArrivee),
      }
    );
  };

  if (!equipage) {
    return null;
  }

  return (
    <ChangeDesserteModal
      onBack={closeModal}
      onFinished={onFinished}
      onSave={changerDestination}
      defaultValues={{ equipage, desserte }}
    />
  );
};
