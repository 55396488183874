import { FC } from "react";
import { FormControl, FormControlLabel, FormLabel, Radio } from "@mui/material";
import { RadioGroupBox } from "components";

type TDFProps = {
  hasTdf: boolean;
};

export const RegimeTransportDeFond: FC<TDFProps> = ({ hasTdf }) => {
  return (
    <FormControl disabled={!hasTdf}>
      <FormLabel id="regime">Régime</FormLabel>
      <RadioGroupBox
        name="transport.intramuros"
        sx={{ display: "flex", flexDirection: "row" }}
      >
        <FormControlLabel value="1" control={<Radio />} label="Intra muros" />
        <FormControlLabel value="0" control={<Radio />} label="Extra muros" />
      </RadioGroupBox>
    </FormControl>
  );
};
