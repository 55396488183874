import { RequestParam } from "types";
import { ListPaginated } from "types/list.type";
import { requestParams } from "utils/helpers";
import { requestGet, requestPost, requestPut } from "./request";

export class CrudService<L, R> {
  private base: string;

  constructor(base: string) {
    this.base = base;
  }

  findAll = (params?: RequestParam) => {
    return requestGet<ListPaginated<L>>(`${this.base}${requestParams(params)}`);
  };

  findById = (id: string) => {
    return requestGet<{ data: R }>(`${this.base}/${id}`).then(
      ({ data }) => data
    );
  };

  create = (data: any) => {
    return requestPost<{ data: R }>(this.base, data).then(({ data }) => data);
  };

  update = (id: string, data: any) => {
    return requestPut<{ data: R }>(`${this.base}/${id}`, data).then(
      ({ data }) => data
    );
  };

  getBase = () => {
    return this.base;
  };
}
