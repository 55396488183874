import { Badge, Typography } from "@mui/material";

import MainCard from "components/MainCard";

import { FC, ReactNode } from "react";
import { DesserteResource } from "types/transport.types";
import { PlanificationDesserteItem } from "./PlanificationDesserteItem";
import { ListDesserteWrapper } from "./ListDesserteWrapper";

type PlanificationDessertePanelProps = {
  title: string;
  secondary?: ReactNode;
  dessertes: DesserteResource[];
  checked: DesserteResource[];
  handleToggle: (desserte: DesserteResource) => void;
};

export const PlanificationDessertePanel: FC<
  PlanificationDessertePanelProps
> = ({ title, secondary, dessertes, checked, handleToggle }) => {
  const renderListItem = (desserte: DesserteResource) => {
    const isChecked =
      checked.findIndex((item) => item.id === desserte.id) !== -1;
    return (
      <PlanificationDesserteItem
        isChecked={isChecked}
        handleToggle={handleToggle}
        key={desserte.id}
        desserte={desserte}
      />
    );
  };

  return (
    <MainCard
      content={false}
      elevation={2}
      boxShadow
      title={
        <Typography>
          <Badge badgeContent={dessertes.length} color="primary">
            {title}
          </Badge>
        </Typography>
      }
      sx={{ width: "100%" }}
      headerSX={{ backgroundColor: "#f7f7f7" }}
      secondary={secondary}
    >
      <ListDesserteWrapper list={dessertes} renderItem={renderListItem} />
    </MainCard>
  );
};
