// material-ui
import { Theme } from "@mui/material/styles";
import {
  useMediaQuery,
  Container,
  Link,
  Typography,
  Stack,
} from "@mui/material";

// ==============================|| FOOTER - AUTHENTICATION ||============================== //

export const AuthFooter = () => {
  const matchDownSM = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Container maxWidth="xl">
      <Stack
        direction={matchDownSM ? "column" : "row"}
        justifyContent={matchDownSM ? "center" : "space-between"}
        spacing={2}
        textAlign={matchDownSM ? "center" : "inherit"}
      >
        <Stack
          direction={matchDownSM ? "column" : "row"}
          spacing={matchDownSM ? 1 : 3}
          textAlign={matchDownSM ? "center" : "inherit"}
        >
          <Typography
            variant="subtitle2"
            color="secondary"
            component={Link}
            href="https://www.burvalcorporate.com/"
            target="_blank"
            underline="hover"
          >
            &copy; 2022 Tous droits reservés
          </Typography>
        </Stack>
      </Stack>
    </Container>
  );
};
