import { useEffect, useMemo, useState } from "react";

import {
  Button,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

// assets
import { TableSimple } from "components";
import { PageTitle } from "pages/common";
import { centreRegionalService } from "services";

import { CentreRegionalResource } from "types/centre.type";
import { CentreRegionalModal } from "./CentreRegionalModal";
import { useModal, useUser } from "hooks";
import { addOrUpdateElement } from "utils/helpers";
import { EditTwoTone } from "@ant-design/icons";

const ListCentreRegional = () => {
  const theme = useTheme();
  const [defaultValues, setDefaultValues] = useState<any | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<CentreRegionalResource[]>([]);
  const modal = useModal();
  const { paysId } = useUser();

  useEffect(() => {
    setLoading(true);

    centreRegionalService
      .findAll({ paysId })
      .then((resultat) => {
        setData(resultat.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [paysId]);

  const save = (data: any) => {
    if (defaultValues?.id) {
      return centreRegionalService.update(defaultValues.id, data);
    }
    return centreRegionalService.create({ ...data, paysId });
  };

  const onEdit = (original: CentreRegionalResource) => {
    setDefaultValues({
      id: original.id,
      libelle: original.libelle,
      code: original.code,
    });
    modal.show();
  };

  const closeModal = () => {
    modal.close();
    setDefaultValues(undefined);
  };

  const onFinished = (element: CentreRegionalResource) => {
    const index = data.findIndex(({ id }) => id === element.id);
    setData((d) => addOrUpdateElement(d, element, index));
    closeModal();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Libelle",
        accessor: "libelle",
        Cell: ({ row: { original } }: any) => (
          <Typography variant="subtitle1">{original.libelle}</Typography>
        ),
      },
      {
        Header: "Code",
        accessor: "code",
        Cell: ({ row: { original } }: any) => (
          <Typography>{original.code || "-"}</Typography>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",

        Cell: ({ row: { original } }: any) => (
          <Tooltip title="Modifier le centre régional">
            <IconButton
              color="error"
              size="large"
              onClick={() => onEdit(original)}
            >
              <EditTwoTone twoToneColor={theme.palette.error.main} />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <PageTitle
        title="Centres régionaux"
        subtitle="Consulter et gérer les centres régionaux"
        sx={{ padding: "1.5rem 0" }}
        right={
          <Button variant="contained" onClick={modal.show}>
            Créer un centre régional
          </Button>
        }
      />

      <TableSimple
        columns={columns}
        data={data}
        withPagination
        loading={loading}
      />
      {modal.open && (
        <CentreRegionalModal
          onBack={closeModal}
          onFinished={onFinished}
          onSave={save}
          defaultValues={defaultValues}
        />
      )}
    </>
  );
};

export default ListCentreRegional;
