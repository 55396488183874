import { Backdrop, CircularProgress } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { closeLoader, selectShowLoader } from "reducers";

export const GlobalLoader = () => {
  const showLoader = useSelector(selectShowLoader);
  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeLoader());
  };

  return (
    <Backdrop
      sx={{
        backgroundColor: "rgba(0,0,0,.08)",
      }}
      open={showLoader}
      onClick={close}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};
