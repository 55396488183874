import { FC } from "react";
import { useTheme } from "@mui/material/styles";
import {
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import Dot from "components/@extended/Dot";
import {
  CardMembership,
  Check,
  LocalShipping,
  ReportProblem,
} from "@mui/icons-material";
import { AvatarIcon } from "components/@extended/Avatar";
import { NotificationResource } from "types/message.type";
import { useNavigate } from "react-router-dom";
import { LINKS, NOTIFICATION_TYPES } from "utils/constant";

type NotificationItemProps = {
  notification: NotificationResource;
  onSelected: (notification: NotificationResource) => void;
  selected?: boolean;
};

const ICONS = {
  [NOTIFICATION_TYPES.incident]: (
    <AvatarIcon color="error" type="outlined">
      <ReportProblem />
    </AvatarIcon>
  ),

  [NOTIFICATION_TYPES.livraison]: (
    <AvatarIcon color="success" type="outlined">
      <LocalShipping />
    </AvatarIcon>
  ),

  [NOTIFICATION_TYPES.priseEnCharge]: (
    <AvatarIcon color="info" type="outlined">
      <LocalShipping />
    </AvatarIcon>
  ),

  [NOTIFICATION_TYPES.finaliserPaiement]: (
    <AvatarIcon color="primary" type="outlined">
      <CardMembership />
    </AvatarIcon>
  ),
};

export const NotificationItem: FC<NotificationItemProps> = ({
  notification,
  selected,
  onSelected,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const isRead = !!notification.readAt;

  return (
    <ListItemButton
      sx={{ pl: 1 }}
      onClick={() => {
        navigate(LINKS.notifications.view(notification.id), { replace: true });
        onSelected(notification);
      }}
      divider
      selected={selected}
    >
      <ListItemAvatar>{ICONS[notification.type]}</ListItemAvatar>
      <ListItemText
        primary={
          <Stack
            component="span"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
          >
            <Typography
              variant={isRead ? undefined : "subtitle1"}
              color="inherit"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {notification.data.titre}
            </Typography>
          </Stack>
        }
        secondary={
          <Stack
            component="span"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
          >
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontWeight: isRead ? 300 : 500,
              }}
            >
              {notification.createdAt}
            </Typography>
            {isRead ? (
              <Check
                style={{ color: theme.palette.grey[400] }}
                fontSize="small"
              />
            ) : (
              <Dot color="primary" size={10} />
            )}
          </Stack>
        }
      />
    </ListItemButton>
  );
};
