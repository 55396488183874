import { FC, HTMLAttributes, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { RequestParam } from "types";
import { SelectAsyncProps } from "types/select-async.type";
import { MultiAutoCompleteHookForm } from "../MultiAutoComplete";
import {
  cassetteService,
  dabService,
  ticketCategorieService,
  ticketTypeService,
} from "services";
import { SelectItem } from "types/form.type";
import { buildSelectItem } from "utils/helpers";
import { AutoCompleteAsyncHookForm } from "../AutoComplete";
import { useRestrictions, useUser } from "hooks";
import { isClient } from "utils/user.helpers";
import { Stack, Typography } from "@mui/material";

interface SelectPersonnelAsyncFormProps extends SelectAsyncProps {
  params?: RequestParam;
}

export const SelectMultipleCassettes: FC<SelectPersonnelAsyncFormProps> = ({
  queryParams,
  name,
  label,
  afterSelected,
  disabled,
}) => {
  const { setValue } = useFormContext();

  const [options, setOptions] = useState<SelectItem[]>([]);

  useEffect(() => {
    const loadData = async (queryParams?: RequestParam) => {
      const { data } = await cassetteService.findAll(queryParams);
      return data.map((item) => ({
        label: item.libelle,
        value: item.id,
      }));
    };
    loadData(queryParams).then(setOptions);
  }, [setValue, queryParams]);

  return (
    <MultiAutoCompleteHookForm
      options={options}
      label={label}
      name={name}
      disabled={disabled}
      afterSelected={afterSelected}
    />
  );
};

export const SelectTicketTypeAsycForm: FC<SelectAsyncProps> = (props) => {
  const fetchData = async () => {
    const { data } = await ticketTypeService.findAll(props.queryParams);
    return {
      data: data.map(({ libelle, id }) => buildSelectItem(libelle, id)),
      hasMore: false,
    };
  };
  return <AutoCompleteAsyncHookForm {...props} fetchData={fetchData} />;
};

export const SelectTicketCategorieAsycForm: FC<SelectAsyncProps> = (props) => {
  const fetchData = async () => {
    const { data } = await ticketCategorieService.findAll(props.queryParams);
    return {
      data: data.map(({ libelle, id, code, activite }) => ({
        ...buildSelectItem(libelle, id),
        code,
        activite,
      })),
      hasMore: false,
    };
  };
  return <AutoCompleteAsyncHookForm {...props} fetchData={fetchData} />;
};

const renderDabOption = (
  props: HTMLAttributes<HTMLLIElement>,
  _option: SelectItem
) => {
  const option = _option as any;
  return (
    <Stack>
      <Typography variant="subtitle1">{option.label}</Typography>
      <Typography color="secondary" variant="caption">
        {option.site?.nom}
      </Typography>
    </Stack>
  );
};

export const SelectDabAsycForm: FC<SelectAsyncProps> = (props) => {
  const { addCentreRestrictions } = useRestrictions();
  const { user } = useUser();
  const fetchData = async () => {
    const params = addCentreRestrictions(props.queryParams || {});
    if (isClient(user)) {
      params.clientId = user.userableId;
    }
    const { data } = await dabService.findAll(params);

    return {
      data: data.map(({ libelle, id, site }) => ({
        ...buildSelectItem(libelle, id),
        site,
      })),
      hasMore: false,
    };
  };
  return (
    <AutoCompleteAsyncHookForm
      {...props}
      fetchData={fetchData}
      renderOption={renderDabOption}
    />
  );
};
