import { ManageSearch, Search } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { Collapse } from "components/common";
import MainCard from "components/MainCard";
import { FC, ReactNode } from "react";
import { FormProvider, useForm } from "react-hook-form";

type TableFilterProps = {
  children: ReactNode;
  setFilter: (data: any) => void;
  defaultValues?: Record<string, any>;
  subtitle?: string;
  loading?: boolean;
  showSearch?: boolean;
  columns?: number;
};

export const TableFilter: FC<TableFilterProps> = ({
  subtitle,
  setFilter,
  defaultValues,
  loading,
  columns,
  children,
}) => {
  const methods = useForm({ defaultValues });
  const showSearch = defaultValues?.showSearch || true;
  const onReset = () => {
    const resetValues = Object.entries(methods.getValues()).reduce(
      (prev, curr) => {
        if (curr) {
          if (curr && typeof curr[1] === "object" && curr[1]?.value) {
            prev[curr[0]] = null;
          } else {
            prev[curr[0]] = "";
          }
        }
        return prev;
      },
      {} as Record<string, any>
    );
    methods.reset({ ...resetValues, page: 1, perpage: 10 });
    setFilter({ ...resetValues, page: 1, perpage: 10 });
  };

  return (
    <MainCard sx={{ marginBottom: 2 }}>
      <Collapse
        leftIcon={<ManageSearch />}
        title="Rechercher"
        subtitle={subtitle}
        headerBottom={1}
        defaultValue={showSearch}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit((data) =>
              setFilter({ ...data, page: 1 })
            )}
            onReset={onReset}
          >
            <Grid
              container
              columns={columns || 12}
              spacing={2}
              sx={{ marginTop: 0, paddingTop: 0.5 }}
            >
              {children}
              <Grid item xs={columns || 12} justifyContent="end" display="flex">
                <Button
                  variant="contained"
                  color="info"
                  startIcon={<Search />}
                  type="submit"
                  disabled={loading}
                  size="small"
                >
                  Rechercher
                </Button>

                <Button
                  variant="outlined"
                  disableElevation
                  color="primary"
                  type="reset"
                  size="small"
                  sx={{ marginLeft: 0.5 }}
                  disabled={loading}
                >
                  Re-initialiser
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Collapse>
    </MainCard>
  );
};
