import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { LoadingButton } from "components";
import { useNotification } from "hooks";
import { FC, useState } from "react";
import { jonctionService } from "services";
import { JonctionResource } from "types/transport.types";

export const SupprimerEquipageJonction: FC<{
  jonction: JonctionResource;
  onFinished: () => void;
  closeModal: () => void;
}> = ({ jonction, onFinished, closeModal }) => {
  const [starting, setStarting] = useState<boolean>(false);
  const notification = useNotification();

  const supprimer = () => {
    setStarting(true);
    jonctionService
      .supprimer(jonction.id)
      .then(() => {
        notification.success({
          title: "La jonction a bien été supprimée",
        });
        onFinished();
      })
      .catch((e) => {
        notification.warning({ title: e.message });
      })
      .finally(() => {
        setStarting(false);
        closeModal();
      });
  };

  return (
    <Dialog maxWidth="sm" open onClose={closeModal}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Vous voulez vraiment supprimer la jonction ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Annuler</Button>
        <LoadingButton
          variant="contained"
          onClick={supprimer}
          autoFocus
          loading={starting}
        >
          Oui
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
