// assets

import { useMemo } from "react";
import { LINKS } from "utils/constant";
import UtiliataireTemplate from "./UtiliataireTemplate";

export const BilleterieNavigation = () => {
  const links = useMemo(() => {
    return [
      {
        label: "Billets",
        to: LINKS.billeteries.billets,
      },
      {
        label: "Pièces",
        to: LINKS.billeteries.pieces,
      },
    ];
  }, []);

  return <UtiliataireTemplate links={links} />;
};
