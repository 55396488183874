import { isAfter } from "date-fns";
import * as yup from "yup";
import { numberHelper } from "./helpers";

export const validationHelper = {
  heureFinPassage: yup
    .date()
    .when(["heureDebutPassage"], {
      is: (heureDebutPassage: any) => !!heureDebutPassage,
      then: (e) => {
        return yup
          .date()
          .nullable()
          .test({
            message: "Doit être après heure de début",
            test: (heureFinPassage, ctx) =>
              !heureFinPassage
                ? true
                : isAfter(heureFinPassage, ctx.parent.heureDebutPassage),
          });
      },
    })
    .nullable(),

  pourcentageValidator: yup.string().test({
    message: "Doit être comprise entre 1 et 100",
    test: (value) => {
      if (!value) {
        return true;
      }
      const v = numberHelper.parseInt(value);
      return v >= 1 && v <= 100;
    },
  }),
};
