import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FilterProps, ListPaginated } from "types/list.type";
import {
  BeneficiaireResource,
  PaiementResource,
} from "types/paiement-sur-site.type";

type PaiementSurSiteState = {
  paiements: ListPaginated<PaiementResource>;
  paiementFilter: FilterProps;

  beneficiaires: ListPaginated<BeneficiaireResource>;
  beneficiaireFilter: FilterProps;
};

const INIT_LIST = {
  data: [],
  total: 0,
  totalPage: 0,
  page: 1,
  totalInDatabase: 0,
};

const INIT_FILTRE = {
  page: 1,
  perpage: 10,
};

const initialState: PaiementSurSiteState = {
  paiements: { ...INIT_LIST },
  paiementFilter: { ...INIT_FILTRE },

  beneficiaires: { ...INIT_LIST },
  beneficiaireFilter: { ...INIT_FILTRE },
};

const stockSlice = createSlice({
  name: "paiementSurSite",
  initialState,
  reducers: {
    setPaiements(
      state,
      action: PayloadAction<ListPaginated<PaiementResource>>
    ) {
      state.paiements = action.payload;
    },
    setPaiementFilter(state, { payload }: PayloadAction<FilterProps>) {
      state.paiementFilter = payload;
      state.paiements.data = [];
    },

    setBeneficiares(
      state,
      action: PayloadAction<ListPaginated<BeneficiaireResource>>
    ) {
      state.beneficiaires = action.payload;
    },
    setBeneficiaireFilter(state, { payload }: PayloadAction<FilterProps>) {
      state.beneficiaireFilter = payload;
      state.beneficiaires.data = [];
    },
  },
});

export default stockSlice.reducer;

export const {
  setPaiements,
  setPaiementFilter,
  setBeneficiares,
  setBeneficiaireFilter,
} = stockSlice.actions;

const getStockReducer = (state: any): PaiementSurSiteState =>
  state.paiementSurSite;

export const selectBeneficiaires = createSelector(
  getStockReducer,
  ({ beneficiaires }) => beneficiaires
);

export const selectBeneficiaireFilter = createSelector(
  getStockReducer,
  ({ beneficiaireFilter }) => beneficiaireFilter
);

export const selectPaiements = createSelector(
  getStockReducer,
  ({ paiements }) => paiements
);

export const selectPaiementFilter = createSelector(
  getStockReducer,
  ({ paiementFilter }) => paiementFilter
);
