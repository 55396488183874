import { VehiculeResource } from "types/transport.types";
import { CrudService } from "./crudService";

const base = "vehicules";

const crudService = new CrudService<VehiculeResource, VehiculeResource>(base);

export const vehiculeService = {
  ...crudService,
};
