import React, {
  useState,
  useEffect,
  FC,
  ReactNode,
  HTMLAttributes,
  Fragment,
} from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import MuiAutocomplete from "@mui/material/Autocomplete";
import { Box, CircularProgress, Divider } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { SelectItem } from "types/form.type";
import { useDebounce } from "hooks";
import { getJsonValue } from "utils/helpers";

export type FetchDataProps = { page: number; search?: string };

type AutoCompleteAsyncProps = {
  name: string;
  fetchData?: (params: FetchDataProps) => Promise<{
    data: SelectItem[];
    hasMore: boolean;
  }>;
  renderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: SelectItem
  ) => ReactNode;
  setValue?: (name: string, newValue: SelectItem | null) => void;
  hasError?: boolean;
  errorMessage?: string;
  value?: SelectItem;
  defaultOptions?: SelectItem[];
  resetDependencies?: any;
  afterSelected?: (name: string, value: any) => void;
};

export const AutoCompleteAsync: FC<AutoCompleteAsyncProps & TextFieldProps> = ({
  fetchData,
  label,
  name,
  renderOption,
  setValue,
  hasError,
  errorMessage,
  value,
  defaultOptions,
  resetDependencies,
  size = "small",
  disabled,
  ...rest
}) => {
  const [page, setPage] = useState(1);
  const [options, setOptions] = useState<SelectItem[]>([]);

  const [position, setPosition] = useState<number>(0);
  const [listboxNode, setListboxNode] = useState<HTMLLIElement | undefined>();

  const [, setInputValue] = useState("");
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (listboxNode) {
      listboxNode.scrollTop = position;
    }
  }, [position, listboxNode]);

  useEffect(() => {
    setOptions(defaultOptions || []);
  }, [defaultOptions, resetDependencies]);

  const loadMoreResults = async (pageNumber: number) => {
    if (isLoading) {
      return;
    }
    if (!fetchData) {
      return;
    }
    setIsLoading(true);
    try {
      const { data, hasMore } = await fetchData({
        page: pageNumber,
      });
      setPage(pageNumber);
      setOptions((prevOptions) => [...prevOptions, ...data]);
      setIsLoading(false);
      setHasMore(hasMore);
    } catch {
      setIsLoading(false);
    }
  };

  const onScroll = async (event: any) => {
    setListboxNode(event.currentTarget);
    if (listboxNode) {
      const x = listboxNode.scrollTop + listboxNode.clientHeight;
      if (listboxNode.scrollHeight - x <= 1 && hasMore) {
        loadMoreResults(page + 1).then(() => {
          setPosition(x);
        });
      }
    }
  };

  return (
    <>
      <MuiAutocomplete
        options={options}
        autoHighlight
        loading={isLoading}
        loadingText="Chargement ..."
        size={size}
        getOptionLabel={(option) => option.label}
        disabled={disabled}
        id={`select.${name}`}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onOpen={() => {
          if (!options.length) {
            loadMoreResults(1);
          }
        }}
        sx={{
          "& .MuiAutocomplete-option": {
            backgroundColor: "red !important",
          },
        }}
        renderOption={(props: any, option) => (
          <Fragment key={props["data-option-index"]}>
            <Box component="li" {...props}>
              {renderOption ? renderOption(props, option) : option.label}
            </Box>
            {props["data-option-index"] === options.length - 1 ? null : (
              <Divider />
            )}
          </Fragment>
        )}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        value={value || null}
        onChange={(event: any, newValue: SelectItem | null) => {
          setValue && setValue(name, newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            id={`input.${name}`}
            variant={rest.variant}
            label={label}
            InputProps={{
              ...params.InputProps,
              autoComplete: "new-password",
              endAdornment: (
                <>
                  {isLoading && (
                    <CircularProgress color="secondary" size={25} />
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            sx={{
              "& .MuiInputBase-sizeSmall": {
                input: {
                  padding: "8px 8px 8px 8px !important",
                },
              },
            }}
            error={hasError}
            helperText={errorMessage || ""}
          />
        )}
        ListboxProps={{ onScroll }}
        noOptionsText="Aucun résultat"
      />
    </>
  );
};

export const AutoCompleteAsyncPaginated: FC<
  AutoCompleteAsyncProps & TextFieldProps
> = ({
  fetchData,
  label,
  name,
  renderOption,
  setValue,
  hasError,
  errorMessage,
  value,
  defaultOptions,
  resetDependencies,
  size = "small",
  disabled,
  ...rest
}) => {
  const [page, setPage] = useState(1);
  const [options, setOptions] = useState<SelectItem[]>([]);
  const [filterOptions, setFilterOptions] = useState<SelectItem[]>([]);
  const [position, setPosition] = useState<number>(0);
  const [listboxNode, setListboxNode] = useState<HTMLLIElement | undefined>();

  const [searchElements, setSearchElements] = useState<boolean>(false);
  const [inputValue] = useState("");
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { debouncedValue, setValue: setDebouncedValue } = useDebounce("");

  useEffect(() => {
    if (listboxNode) {
      listboxNode.scrollTop = position;
    }
  }, [position, listboxNode]);

  useEffect(() => {
    setOptions(defaultOptions || []);
  }, [defaultOptions, resetDependencies]);

  useEffect(() => {
    if (searchElements) {
      loadMoreResults(1, debouncedValue, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchElements, debouncedValue]);

  const loadMoreResults = async (
    pageNumber: number,
    search?: string,
    filter?: boolean
  ) => {
    if (isLoading) {
      return;
    }
    if (!fetchData) {
      return;
    }
    setIsLoading(true);
    try {
      const { data, hasMore } = await fetchData({
        page: pageNumber,
        search: search,
      });
      setPage(pageNumber);
      if (filter) {
        setFilterOptions((prevOptions) => {
          return [...prevOptions, ...data];
        });
      } else {
        setOptions((prevOptions) => {
          return [...prevOptions, ...data];
        });
      }
      setIsLoading(false);
      setHasMore(hasMore);
    } catch {
      setIsLoading(false);
    }
  };

  const onScroll = async (event: any) => {
    setListboxNode(event.currentTarget);
    if (listboxNode) {
      const x = listboxNode.scrollTop + listboxNode.clientHeight;
      if (listboxNode.scrollHeight - x <= 1 && hasMore) {
        loadMoreResults(page + 1, inputValue, false).then(() => {
          setPosition(x);
        });
      }
    }
  };

  return (
    <>
      <MuiAutocomplete
        options={debouncedValue ? filterOptions : options}
        autoHighlight
        loading={isLoading}
        loadingText="Chargement ..."
        size={size}
        getOptionLabel={(option) => option.label}
        disabled={disabled}
        onOpen={() => {
          if (!options.length) {
            loadMoreResults(1, undefined, false);
          }
          setSearchElements(false);
          setDebouncedValue("");
        }}
        sx={{
          "& .MuiAutocomplete-option": {
            backgroundColor: "red !important",
          },
        }}
        renderOption={(props: any, option) => (
          <Fragment key={props["data-option-index"]}>
            <Box component="li" {...props}>
              {renderOption ? renderOption(props, option) : option.label}
            </Box>
            {props["data-option-index"] === options.length - 1 ? null : (
              <Divider />
            )}
          </Fragment>
        )}
        onInputChange={(event, newInputValue) => {
          setSearchElements(true);
          // setInputValue(newInputValue);

          if (event.type !== "click") {
            setDebouncedValue(newInputValue);
          } else {
            setDebouncedValue("");
          }
        }}
        value={value || null}
        onChange={(event: any, newValue: SelectItem | null) => {
          setValue && setValue(name, newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={rest.variant}
            label={label}
            InputProps={{
              ...params.InputProps,
              autoComplete: "new-password",
              endAdornment: (
                <>
                  {isLoading && (
                    <CircularProgress color="secondary" size={25} />
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            sx={{
              "& .MuiInputBase-sizeSmall": {
                input: {
                  padding: "8px 8px 8px 8px !important",
                },
              },
            }}
            error={hasError}
            helperText={errorMessage || ""}
          />
        )}
        ListboxProps={{ onScroll }}
        filterOptions={(options, state) => {
          return options;
        }}
        noOptionsText="Aucun résultat"
      />
    </>
  );
};

export const AutoCompleteAsyncHookForm: FC<
  AutoCompleteAsyncProps & TextFieldProps
> = (props) => {
  const {
    formState: { errors },
    setValue,
    clearErrors,
    watch,
  } = useFormContext();
  const error = getJsonValue(errors, props.name);
  return (
    <AutoCompleteAsync
      {...props}
      setValue={(name, value) => {
        setValue(name, value, { shouldDirty: true, shouldTouch: true });
        clearErrors(name);
        props.afterSelected && props.afterSelected(name, value);
      }}
      value={watch(props.name)}
      hasError={!!error}
      errorMessage={error?.message?.toString() || ""}
    />
  );
};
