import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { dateFormater } from "utils/date";

type TrajetDateTimeProps = {
  site?: string;
  date?: string;
  heure?: string;
};

export const TrajetDateTime: FC<TrajetDateTimeProps> = ({
  site,
  date,
  heure,
}) => {
  const fulldate = [dateFormater.getDate(date) || "", heure]
    .filter((e) => !!e)
    .join(" à ");

  if (!site) {
    return <Typography>{fulldate}</Typography>;
  }
  return (
    <Stack>
      <Typography>{site}</Typography>
      <Typography
        variant={date ? "caption" : "inherit"}
        color={date ? "secondary" : "inherit"}
        fontWeight={date ? 500 : 400}
      >
        {fulldate}
      </Typography>
    </Stack>
  );
};
