import { SecteurActiviteResource } from "types/referentiel.type";
import { CrudService } from "./crudService";

const crud = new CrudService<SecteurActiviteResource, SecteurActiviteResource>(
  "secteuractivites"
);

export const secteurActiviteService = {
  ...crud,
};
