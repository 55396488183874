import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { LoadingButton } from "components";
import { useNotification } from "hooks";
import { FC, useState } from "react";
import { tourneeService } from "services";
import { TourneeResource } from "types/transport.types";

export const TerminerTournee: FC<{
  tournee: TourneeResource;
  onFinish: (tournee?: TourneeResource) => void;
  onClose: () => void;
}> = ({ tournee, onFinish, onClose }) => {
  const [starting, setStarting] = useState<boolean>(false);
  const notification = useNotification();

  const terminerTournee = (tourneeId: string) => {
    setStarting(true);
    tourneeService
      .terminer(tourneeId)
      .then(({ data }) => {
        notification.success({
          title: "La tournée a bien été marquée comme terminée",
        });
        onFinish(data);
      })
      .catch((e) => {
        notification.warning({ title: e.message });
      })
      .finally(() => {
        setStarting(false);
        onClose();
      });
  };

  return (
    <Dialog maxWidth="sm" open onClose={onClose}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Vous voulez vraiment marquer la tournée&nbsp;
          <Typography component="span" fontWeight={600}>
            {tournee.numero}
          </Typography>
          &nbsp;comme étant terminée ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <LoadingButton
          variant="contained"
          onClick={() => terminerTournee(tournee.id)}
          autoFocus
          loading={starting}
        >
          Oui
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
