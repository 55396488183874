import { Grid } from "@mui/material";
import { TextInput } from "components";
import { withForm, WrapperProps } from "hoc";
import { FC } from "react";
import { UtilitaireModal } from "../UtilitaireModal";

const Form: FC<WrapperProps> = (props) => {
  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title={
        props.isEditMode
          ? "Mise à jour du service"
          : "Ajouter un service"
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextInput label="Nom *" name="libelle" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput label="Code *" name="code" disabled={props.isEditMode} />
        </Grid>
      </Grid>
    </UtilitaireModal>
  );
};

export const ServiceModal = withForm(Form);
