import { useEffect, useMemo, useState } from "react";

import {
  Button,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

// assets
import { TableSimple } from "components";
import { PageTitle } from "pages/common";
import { villeService } from "services";

import { VilleResource } from "types/centre.type";
import { useModal, useUser } from "hooks";
import { addOrUpdateElement, selectHelper } from "utils/helpers";
import { EditTwoTone } from "@ant-design/icons";
import { VilleModal } from "./VilleModal";

const ListVille = () => {
  const theme = useTheme();
  const [defaultValues, setDefaultValues] = useState<any | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<VilleResource[]>([]);
  const modal = useModal();
  const { paysId } = useUser();

  useEffect(() => {
    setLoading(true);

    villeService
      .findAll({ paysId })
      .then((resultat) => {
        setData(resultat.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [paysId]);

  const save = (data: any) => {
    if (defaultValues?.id) {
      return villeService.update(defaultValues.id, {
        ...data,
        paysId: selectHelper.getValue(data.paysId),
        centreId: selectHelper.getValue(data.centreId),
      });
    }
    return villeService.create({
      ...data,
      paysId,
      centreId: selectHelper.getValue(data.centreId),
    });
  };

  const onEdit = (original: VilleResource) => {
    setDefaultValues({
      id: original.id,
      libelle: original.libelle,
      code: original.code,
      centreId: selectHelper.buildCentre(original.centre),
      paysId: selectHelper.buildPays(original.pays),
    });
    modal.show();
  };

  const closeModal = () => {
    modal.close();
    setDefaultValues(undefined);
  };

  const onFinished = (element: VilleResource) => {
    const index = data.findIndex(({ id }) => id === element.id);
    setData((d) => addOrUpdateElement(d, element, index));
    closeModal();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Libelle",
        accessor: "libelle",
        Cell: ({ row: { original } }: any) => (
          <Typography variant="subtitle1">{original.libelle}</Typography>
        ),
      },
      {
        Header: "Code",
        accessor: "code",
        Cell: ({ row: { original } }: any) => (
          <Typography>{original.code || "-"}</Typography>
        ),
      },
      {
        Header: "Centre",
        accessor: "centre",
        Cell: ({ row: { original } }: any) => (
          <Typography>{original.centre.libelle || "-"}</Typography>
        ),
      },
      {
        Header: "Pays",
        accessor: "pays",
        Cell: ({ row: { original } }: any) => (
          <Typography>{original.pays.libelle || "-"}</Typography>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",

        Cell: ({ row: { original } }: any) => (
          <Tooltip title="Modifier la ville">
            <IconButton
              color="error"
              size="large"
              onClick={() => onEdit(original)}
            >
              <EditTwoTone twoToneColor={theme.palette.error.main} />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <PageTitle
        title="Villes"
        subtitle="Consulter et gérer les villes"
        sx={{ padding: "1.5rem 0" }}
        right={
          <Button variant="contained" onClick={modal.show}>
            Créer une ville
          </Button>
        }
      />

      <TableSimple
        columns={columns}
        data={data}
        loading={loading}
        withPagination
      />
      {modal.open && (
        <VilleModal
          onBack={closeModal}
          onFinished={onFinished}
          onSave={save}
          defaultValues={defaultValues}
        />
      )}
    </>
  );
};

export default ListVille;
