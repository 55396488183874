import SimpleBar from "components/third-party/SimpleBar";
import { DrawerNavigation } from "./navigation";
import { buildUserMenu } from "menu-items";
import { useUser } from "hooks";
import { useMemo } from "react";

export const DrawerContent = () => {
  const { user } = useUser();

  const menuItems = useMemo(() => {
    return buildUserMenu(user);
  }, [user]);

  return (
    <SimpleBar
      sx={{
        "& .simplebar-content": {
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DrawerNavigation menuItems={menuItems} />
    </SimpleBar>
  );
};
