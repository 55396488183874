import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
} from "@mui/material";
import { CentreAsyncSelectForm, RadioGroupBox, TextInput } from "components";
import { withForm, WrapperProps } from "hoc";
import { FC } from "react";
import { UtilitaireModal } from "../UtilitaireModal";

const Form: FC<WrapperProps> = (props) => {
  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title={props.isEditMode ? "Mise à jour du magasin" : "Ajouter un magasin"}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextInput label="Libellé *" name="libelle" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput label="Code *" name="code" />
        </Grid>
        <Grid item xs={12}>
          <CentreAsyncSelectForm label="Centre " name="centreId" />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl>
            <FormLabel sx={{ marginRight: 2 }}>Principal ?</FormLabel>
            <RadioGroupBox
              name="principal"
              sx={{ display: "flex", flexDirection: "row" }}
            >
              <FormControlLabel value="1" control={<Radio />} label="Oui" />
              <FormControlLabel value="0" control={<Radio />} label="Non " />
            </RadioGroupBox>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl>
            <FormLabel sx={{ marginRight: 2 }}>Corbeille ?</FormLabel>
            <RadioGroupBox
              name="corbeille"
              sx={{ display: "flex", flexDirection: "row" }}
            >
              <FormControlLabel value="1" control={<Radio />} label="Oui" />
              <FormControlLabel value="0" control={<Radio />} label="Non " />
            </RadioGroupBox>
          </FormControl>
        </Grid>
      </Grid>
    </UtilitaireModal>
  );
};

export const MagasinModal = withForm(Form);
