import { FC, Fragment } from "react";
import { Box, Button, Grid, IconButton, Tooltip } from "@mui/material";
import { AutoCompleteAsyncHookForm, Collapse, TextInput } from "components";
import { Add, Delete } from "@mui/icons-material";
import { useFormContext, useFieldArray } from "react-hook-form";
import { CORPS } from "utils/constant";
import { ViewIcons } from "pages/common";

type TourneePrestataireFormProps = {
  base?: string;
};

export const TourneePrestataireForm: FC<TourneePrestataireFormProps> = ({
  base,
}) => {
  const { control } = useFormContext();
  const prefix = `${base ? `${base}.` : ""}prestataires`;
  const prestataires = useFieldArray({ control, name: prefix });
  const addButton = (
    <Button
      disableElevation
      size="small"
      onClick={() => {
        prestataires.append({
          fullname: "",
          matricule: "",
          telephone: "",
          corps: "",
        });
      }}
      startIcon={<Add />}
      sx={{ marginTop: 2 }}
    >
      Ajouter un prestataire
    </Button>
  );

  if (!prestataires.fields.length) {
    return addButton;
  }
  return (
    <>
      <Collapse
        title="Prestataires"
        subtitle="Ajouter des prestataires externes à la tournée"
        leftIcon={ViewIcons.prestataire}
        sx={{ marginTop: 3 }}
      >
        {prestataires.fields.map((field, i) => (
          <Box
            key={field.id}
            sx={{
              padding: 1,
              marginBottom: 1,
              bgcolor: (t) => t.palette.grey[100],
            }}
          >
            <Box sx={{ display: "flex", marginBottom: 1 }}>
              <Tooltip title="Supprimer le prestataire">
                <IconButton
                  sx={{ marginLeft: "auto" }}
                  size="small"
                  color="error"
                  onClick={() => {
                    prestataires.remove(i);
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
            <Grid container spacing={3} marginBottom={2}>
              <Grid item xs={12} md={3}>
                <TextInput
                  name={`${prefix}.${i}.fullname`}
                  label="Nom et prénom *"
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextInput
                  name={`${prefix}.${i}.matricule`}
                  label="Matricule"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextInput
                  name={`${prefix}.${i}.telephone`}
                  label="Numéro tél."
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <AutoCompleteAsyncHookForm
                  name={`${prefix}.${i}.corps`}
                  label="Corps"
                  defaultOptions={CORPS}
                />
              </Grid>
            </Grid>
          </Box>
        ))}
        {addButton}
      </Collapse>
    </>
  );
};
