import { Grid } from "@mui/material";
import { YearInput } from "components";
import { withForm, WrapperProps } from "hoc";
import { FC } from "react";
import { UtilitaireModal } from "../UtilitaireModal";

import * as yup from "yup";

const Form: FC<WrapperProps> = (props) => {
  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title={
        "Duplication de jours fériés"
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <YearInput
            name="fromYear"
            label="Année de référence *"
            dateWrapperProps={{
              minDate: new Date(),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <YearInput
            name="toYear"
            label="Année concernée *"
            dateWrapperProps={{
              minDate: new Date(),
            }}
          />
        </Grid>
      </Grid>
    </UtilitaireModal>
  );
};

export const DuplicateModal = withForm(
  Form,
  yup.object({
    fromYear: yup.date().required().nullable(),
    toYear: yup.date().required().nullable(),
  })
);
