import React, { useState } from "react";

// material-ui
import {
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { authService } from "services";
import { useNotification } from "hooks";
import { LoadingButton } from "components";

export const ForgetPasswordForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });
  const navigate = useNavigate();
  const notification = useNotification();
  const [loading, setLoading] = useState<boolean>(false);

  const sendForgetPassord = (data: Record<string, string>) => {
    setLoading(true);
    authService
      .forgetpassword(data)
      .then((data: any) => {
        setLoading(false);
        notification.success({
          title: "Bravo",
          message: data.message,
        });
      })
      .catch(({ status }) => {
        setLoading(false);
        if (status === 404) {
          notification.error({
            title: "Echec",
            message: "Aucun utilisateur ne correspond à l'adresse mail saisie",
          });
        } else {
          notification.error({
            title: "Erreur technique",
            message: "Une erreur technique est survenue lors du traitement",
          });
        }
      });
  };

  const loginPage = () => {
    navigate("/login");
  };

  return (
    <form onSubmit={handleSubmit(sendForgetPassord)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <InputLabel htmlFor="email">Adresse email</InputLabel>
            <OutlinedInput
              type="email"
              placeholder="Votre adresse email"
              fullWidth
              {...register("email", { required: true })}
            />
            {errors.email && (
              <FormHelperText error>
                {errors.email.message?.toString()}
              </FormHelperText>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            disableElevation
            disabled={false}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            loading={loading}
          >
            SOUMETTRE
          </LoadingButton>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Typography
            sx={{
              "&:hover": {
                textDecoration: "underline",
                cursor: "pointer",
              },
            }}
            onClick={loginPage}
            component="span"
            marginBottom={0}
          >
            Retour à la page de connexion
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};
