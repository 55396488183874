import { FC, useContext, useEffect, useMemo, useState } from "react";
import { Collapse } from "components";
import { withForm, WrapperProps } from "hoc";

import { dateFormater } from "utils/date";
import MainCard from "components/MainCard";
import { TourneeInfoGeneralForm } from "./TourneeInfoGeneralForm";
import { TourneeEquipageForm } from "./TourneeEquipageForm";

import { TourneeDessertesForm } from "./TourneeDessertesForm";
import { Box, CircularProgress, Grid, Grow, Stack } from "@mui/material";
import { PageTitle, ViewIcons } from "pages/common";
import { useFormContext } from "react-hook-form";
import { PlanifierTourneeContext } from "../context";
import { desserteService } from "services";
import { useNotification, useUser } from "hooks";
import { TourneeJonctionForm } from "./TourneeJonctionForm";
import { tourneeSchema } from "./common/tourneeSchema";

const Form: FC<WrapperProps> = ({
  isEditMode,
  renderButtons,
  onBack,
  onSubmit,
}) => {
  const notification = useNotification();
  const { watch, setValue } = useFormContext();
  const { paysId } = useUser();
  const { setDessertesAPlanifier } = useContext(PlanifierTourneeContext);

  const centreId = watch("centre")?.value;
  const _dateDepart = watch("dateDepart");

  const dateDepart = useMemo(() => {
    return dateFormater.toBackPattern(_dateDepart, "yyyy-MM-dd");
  }, [_dateDepart]);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!dateDepart || !centreId) {
      return;
    }
    setLoading(true);
    setValue("typeVehicule", null);
    setValue("ville", null);
    setValue("situationGeographique", null);
    setValue("zone", null);
    desserteService
      .aPlanifier({
        paysId,
        dateDepart,
        centreId,
      })
      .then(({ data }) => {
        setDessertesAPlanifier(data);
        if (!data.length) {
          notification.warning({
            title: "Recherche infractueuse",
            message: "La recherche n'a ramenée aucune desserte",
          });
        }
      })
      .catch(() => {
        setDessertesAPlanifier([]);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paysId, dateDepart, centreId]);

  return (
    <form onSubmit={onSubmit}>
      <PageTitle
        title={`${isEditMode ? "Modifier" : "Planification de"} tournée`}
        subtitle={isEditMode ? `N° ${watch("numero")}` : ""}
        onBack={onBack}
        right={renderButtons()}
      />

      <MainCard sx={{ marginBottom: 3 }}>
        <TourneeInfoGeneralForm />
        {loading && (
          <Grow in>
            <Grid container>
              <Grid item xs={12} marginTop={2}>
                <Stack flexDirection="row" alignItems="center">
                  <CircularProgress size={20} sx={{ marginRight: 2 }} />
                  &nbsp; chargement ...
                </Stack>
              </Grid>
            </Grid>
          </Grow>
        )}
      </MainCard>

      <MainCard sx={{ marginBottom: 3 }}>
        <TourneeDessertesForm />
      </MainCard>

      <MainCard sx={{ marginBottom: 3 }}>
        <Collapse
          title="Equipage"
          subtitle="Choisir le véhicule et former l'équipage"
          leftIcon={ViewIcons.equipe}
        >
          <TourneeEquipageForm />
        </Collapse>
      </MainCard>

      <MainCard sx={{ marginBottom: 3 }}>
        <TourneeJonctionForm />
      </MainCard>

      <Box sx={{ textAlign: "right" }}>{renderButtons()}</Box>
    </form>
  );
};

export const TourneeForm = withForm(Form, tourneeSchema);
