import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { Box, Stack, Typography } from "@mui/material";
import { SwitchBox } from "components";
import { DesserteTrajetSens } from "pages/dessertes";

type TraitementHeaderProps = {
  desserteIndex: number;
};

export const TraitementHeader: FC<TraitementHeaderProps> = ({
  desserteIndex,
}) => {
  const { watch } = useFormContext();

  return (
    <Stack flexDirection="row" alignItems="center">
      <Box>
        <DesserteTrajetSens
          nomSiteArrivee={watch(`dessertes.${desserteIndex}.arrivee`)}
          nomSiteDepart={watch(`dessertes.${desserteIndex}.depart`)}
          libelleSx={{ fontSize: "1rem" }}
        />
        <Typography
          color="secondary"
          sx={{ marginBottom: 2 }}
          variant="subtitle2"
        >
          N° {watch(`dessertes.${desserteIndex}.numero`)}
        </Typography>
      </Box>
      <SwitchBox
        name={`dessertes.${desserteIndex}.selected`}
        color="success"
        sx={{ marginLeft: "auto", alignSelf: "start" }}
      />
    </Stack>
  );
};
