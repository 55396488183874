import {
  Checkbox as MuiCheckBox,
  CheckboxProps,
  FormHelperText,
  RadioGroup as RadioGroupMui,
  RadioGroupProps,
  Switch,
  SwitchProps,
} from "@mui/material";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

export const CheckBox: FC<
  {
    name: string;
  } & CheckboxProps
> = ({ name, ...rest }) => {
  const { watch, register } = useFormContext();

  const values = watch(name) || [];
  let defaultChecked = false;
  if (values.length) {
    defaultChecked = values.includes(rest.value);
  } else {
    defaultChecked = rest.value === values;
  }

  return (
    <MuiCheckBox
      checked={defaultChecked}
      id={name}
      {...rest}
      {...register(name)}
    />
  );
};

export const SwitchBox: FC<
  {
    name: string;
    afterSelected?: (name?: string, value?: any) => void;
  } & SwitchProps
> = ({ name, afterSelected, ...rest }) => {
  const { register, setValue, watch } = useFormContext();
  return (
    <Switch
      id={name}
      {...rest}
      {...register(name)}
      checked={!!watch(name)}
      onChange={(e) => {
        setValue(name, e.target.checked);
        if (afterSelected) {
          afterSelected(name, e.target.checked);
        }
      }}
    />
  );
};

export const RadioGroupBox: FC<
  {
    name: string;
    afterSelected?: (name?: string, value?: any) => void;
  } & RadioGroupProps
> = ({ name, afterSelected, ...rest }) => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <RadioGroupMui
        value={watch(name)}
        id={name}
        {...rest}
        onChange={(e) => {
          setValue(name, e.target.value);
          if (afterSelected) {
            afterSelected(name, e.target.value);
          }
        }}
      />
      {errors[name]?.message && (
        <FormHelperText error>
          {errors[name]?.message?.toString()}
        </FormHelperText>
      )}
    </>
  );
};
