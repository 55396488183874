// material-ui
import { Theme } from "@mui/material/styles";
import { Box, Stack, useMediaQuery } from "@mui/material";

// import Message from './Message';
import Profile from "./profile";
// import Localization from './Localization';
// import Notification from './Notification';
// import Customization from './Customization';
import MobileSection from "./MobileSection";
import { HeaderNotification } from "./HeaderNotification";
// import MegaMenuSection from './MegaMenuSection';
// import Drapeau from "assets/images/rci.png";

// ==============================|| HEADER - CONTENT ||============================== //

export const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps

  return (
    <>
      {/* {!matchesXs && <Search />} */}
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}

      <Stack
        flexDirection="row"
        alignItems="center"
        sx={{ flexShrink: 0, ml: "auto" }}
      >
        <HeaderNotification />
        {/* <Message /> */}
        {!matchesXs && <Profile />}
        {/* <img
          src={Drapeau}
          alt="Côte d'Ivoire"
          title="Côte d'Ivoire"
          style={{ width: "25px", marginLeft: "5px" }}
        /> */}
        {matchesXs && <MobileSection />}
      </Stack>
    </>
  );
};
