import { useEffect, useMemo, useState } from "react";

import {
  Button,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

// assets
import { TableSimple } from "components";
import { PageTitle } from "pages/common";
import { paysService } from "services";

import { PaysResource } from "types/centre.type";
import { useModal } from "hooks";
import { addOrUpdateElement, selectHelper } from "utils/helpers";
import { EditTwoTone } from "@ant-design/icons";
import { PaysModal } from "./PaysModal";

const INITIAL = { tva: 0 };

const ListPays = () => {
  const theme = useTheme();
  const [defaultValues, setDefaultValues] = useState<any | undefined>(INITIAL);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<PaysResource[]>([]);
  const modal = useModal();

  useEffect(() => {
    setLoading(true);

    paysService
      .findAll()
      .then((resultat) => {
        setData(resultat.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const save = (data: any) => {
    const body = {
      ...data,
      tva: parseFloat(data.tva),
      deviseId: selectHelper.getValue(data.devise),
    };
    if (defaultValues?.id) {
      return paysService.update(defaultValues.id, body);
    }
    return paysService.create(body);
  };

  const onEdit = (original: PaysResource) => {
    setDefaultValues({
      id: original.id,
      libelle: original.libelle,
      code: original.code,
      nationalite: original.nationalite,
      tva: parseFloat(original.tva) || 0,
      devise: selectHelper.buildDevise(original.devise),
      tempsAttente: original.tempsAttente,
    });
    modal.show();
  };

  const closeModal = () => {
    modal.close();
    setDefaultValues(INITIAL);
  };

  const onFinished = (element: PaysResource) => {
    const index = data.findIndex(({ id }) => id === element.id);
    setData((d) => addOrUpdateElement(d, element, index));
    closeModal();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Libelle",
        accessor: "libelle",
        Cell: ({ row: { original } }: any) => (
          <Typography variant="subtitle1">{original.libelle}</Typography>
        ),
      },
      {
        Header: "Code",
        accessor: "code",
        Cell: ({ row: { original } }: any) => (
          <Typography>{original.code || "-"}</Typography>
        ),
      },
      {
        Header: "Nationalite",
        accessor: "nationalite",
      },
      {
        Header: "TVA",
        accessor: "tva",
      },
      {
        Header: "Devise",
        accessor: "devise",
        Cell: ({ row: { original } }: any) => (
          <Typography>{original.devise?.libelle || "-"}</Typography>
        ),
      },
      {
        Header: "Temps d'attente (mn)",
        accessor: "tempsAttente",
      },
      {
        Header: "Actions",
        accessor: "actions",

        Cell: ({ row: { original } }: any) => (
          <Tooltip title="Modifier le pays">
            <IconButton
              color="error"
              size="large"
              onClick={() => onEdit(original)}
            >
              <EditTwoTone twoToneColor={theme.palette.error.main} />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <PageTitle
        title="Pays"
        subtitle="Consulter et gérer les pays"
        sx={{ padding: "1.5rem 0" }}
        right={
          <Button
            variant="contained"
            onClick={() => {
              modal.show();
              setDefaultValues(INITIAL);
            }}
          >
            Créer un pays
          </Button>
        }
      />

      <TableSimple
        columns={columns}
        data={data}
        loading={loading}
        withPagination
      />
      {modal.open && (
        <PaysModal
          onBack={closeModal}
          onFinished={onFinished}
          onSave={save}
          defaultValues={defaultValues}
        />
      )}
    </>
  );
};

export default ListPays;
