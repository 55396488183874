import { Close } from "@mui/icons-material";
import {
  Box,
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import { FC, ReactNode } from "react";

type UtilitaireModalProps = {
  onClose?: () => void;
  renderButtons: () => ReactNode;
  onSubmit: () => void;
  title: string;
  children: ReactNode;
  maxWidth?: false | Breakpoint | undefined;
};

export interface DialogTitleProps {
  children?: any;
  onClose?: () => void;
}

export function Title(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
          color="primary"
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export const UtilitaireModal: FC<UtilitaireModalProps> = ({
  title,
  onClose,
  renderButtons,
  onSubmit,
  maxWidth = "sm",
  children,
}) => {
  return (
    <form onSubmit={onSubmit}>
      <Dialog maxWidth={maxWidth} fullWidth open={true}>
        <Title onClose={onClose}>{title}</Title>
        <Divider />
        <DialogContent
          sx={{
            backgroundColor: (t) => t.palette.background.default,
            padding: "1.5rem !important",
          }}
        >
          {children}
        </DialogContent>
        <DialogActions>
          <Box
            sx={{ marginTop: 0, marginLeft: "auto" }}
            display="flex"
            justifyContent="end"
          >
            {renderButtons()}
          </Box>
        </DialogActions>
      </Dialog>
    </form>
  );
};
