import { requestBlob } from "./request";
import fileDownload from "js-file-download";
import { requestParams } from "utils/helpers";

export const exportService = {
  pdf: (path: string, body?: Record<string, any>) => {
    const _body = { ...(body || {}), format: "pdf" };
    return requestBlob(`${path}${requestParams(_body)}`).then((response) => {
      const filename =
        response.headers?.get("Content-Disposition")?.split("filename=")[1] ||
        "document.pdf";

      return response.blob().then((blob: any) => {
        fileDownload(blob, filename);
      });
    });
  },

  download: (path: string, body?: Record<string, any>) => {
    return requestBlob(`${path}${requestParams(body)}`).then((response) => {
      const filename =
        response.headers?.get("Content-Disposition")?.split("filename=")[1] ||
        "document";

      return response.blob().then((blob: any) => {
        console.log(blob);
        fileDownload(blob, filename);
      });
    });
  },
};
