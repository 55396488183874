import { useCallback, useEffect, useRef, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Badge,
  Box,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
} from "@mui/material";

// project import
import MainCard from "components/MainCard";
import IconButton from "components/@extended/IconButton";
import Transitions from "components/@extended/Transitions";

// assets
import { Notifications, Refresh } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { LINKS } from "utils/constant";
import { notificationService } from "services";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLoadingNotifications,
  selectNotifications,
  setLoadingNotifications,
  setNotifications,
} from "reducers";
import { NotificationItem } from "pages/notifications";
import { LoadingButton } from "components";
import { useNotification } from "hooks";
import SimpleBar from "components/third-party/SimpleBar";

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

export const HeaderNotification = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const notification = useNotification();
  const notifications = useSelector(selectNotifications);
  const loading = useSelector(selectLoadingNotifications);

  const loadNotifications = useCallback(() => {
    dispatch(setLoadingNotifications(true));
    return notificationService
      .findAll()
      .then((data) => {
        dispatch(setNotifications(data));
      })
      .finally(() => {
        setOpen(false);
        dispatch(setLoadingNotifications(false));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    loadNotifications();
  }, [loadNotifications]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const openNotifications = () => {
    navigate(LINKS.notifications.list);
    setOpen(false);
  };

  const iconBackColorOpen =
    theme.palette.mode === "dark" ? "grey.200" : "grey.300";

  const iconBackColor =
    theme.palette.mode === "dark" ? "background.default" : "grey.100";

  return (
    <Box sx={{ flexShrink: 0, mr: 0.75 }}>
      <IconButton
        color="secondary"
        variant="light"
        sx={{
          color: "text.primary",
          bgcolor: open ? iconBackColorOpen : iconBackColor,
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge badgeContent={0} color="info">
          <Notifications />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? "bottom" : "bottom-end"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [matchesXs ? -5 : 0, 9],
              },
            },
          ],
        }}
        sx={{
          minWidth: 300,
        }}
        // PaperProps={{
        //   sx: {
        //     padding: 0,
        //   },
        // }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: "100%",
                minWidth: 400,
                maxWidth: 420,
                maxHeight: 500,
                [theme.breakpoints.down("md")]: {
                  maxWidth: 320,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="Notifications"
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <LoadingButton
                      sx={{ marginLeft: "auto" }}
                      startIcon={<Refresh />}
                      color="primary"
                      disableElevation
                      size="small"
                      variant="contained"
                      onClick={() => {
                        loadNotifications().then(() => {
                          notification.success({
                            title: "Les notifications ont été mises à jour",
                          });
                        });
                      }}
                      loading={loading}
                    >
                      Actualiser
                    </LoadingButton>
                  }
                >
                  <SimpleBar
                    sx={{
                      overflowX: "hidden",
                      height: 400,
                    }}
                  >
                    <List
                      component="nav"
                      sx={{
                        p: 0,
                        "& .MuiListItemButton-root": { py: 0.5 },
                        maxHeight: 400,
                      }}
                    >
                      {(notifications.data || []).map((notification) => (
                        <NotificationItem
                          notification={notification}
                          key={notification.id}
                          onSelected={() => {
                            setOpen(false);
                          }}
                        />
                      ))}
                    </List>
                  </SimpleBar>
                  <ListItemButton
                    sx={{ textAlign: "center", py: `${12}px !important` }}
                    onClick={openNotifications}
                    selected
                  >
                    <ListItemText
                      primary={
                        <Typography variant="h6" color="primary">
                          Voir tout
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};
