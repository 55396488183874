import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FilterProps, ListPaginated } from "types/list.type";
import { DabResource, TicketResource } from "types/ssb.type";

type ssbState = {
  dabs: ListPaginated<DabResource>;
  dabFilter: FilterProps;

  tickets: ListPaginated<TicketResource>;
  ticketFilter: FilterProps;
};

const initialState: ssbState = {
  dabs: {
    data: [],
    total: 0,
    totalPage: 0,
    page: 1,
    totalInDatabase: 0,
  },
  dabFilter: {
    page: 1,
    perpage: 10,
  },

  tickets: {
    data: [],
    total: 0,
    totalPage: 0,
    page: 1,
    totalInDatabase: 0,
  },
  ticketFilter: {
    page: 1,
    perpage: 10,
  },
};

const ssbSlice = createSlice({
  name: "ssb",
  initialState,
  reducers: {
    setDabs(state, action: PayloadAction<ListPaginated<DabResource>>) {
      state.dabs = action.payload;
    },
    setDabFilter(state, { payload }: PayloadAction<Record<string, any>>) {
      state.dabFilter = payload;
    },

    setTickets(state, action: PayloadAction<ListPaginated<TicketResource>>) {
      state.tickets = action.payload;
    },

    setTicketFilter(state, { payload }: PayloadAction<Record<string, any>>) {
      state.ticketFilter = payload;
    },
  },
});

export default ssbSlice.reducer;

export const { setDabs, setDabFilter, setTickets, setTicketFilter } =
  ssbSlice.actions;

const getSsbReducer = (state: any): ssbState => state.ssb;

export const selectDabs = createSelector(getSsbReducer, ({ dabs }) => dabs);

export const selectDabFilter = createSelector(
  getSsbReducer,
  ({ dabFilter }) => dabFilter
);

export const selectTickets = createSelector(
  getSsbReducer,
  ({ tickets }) => tickets
);

export const selectTicketFilter = createSelector(
  getSsbReducer,
  ({ ticketFilter }) => ticketFilter
);
