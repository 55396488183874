import { Collapse } from "components";
import { ViewIcons, ViewListItemEmpty } from "pages/common";
import { FC, useEffect, useState } from "react";
import { desserteService } from "services";
import { IncidentResource } from "types/incidents.types";
import { Incidents } from "./Incidents";

type DesserteIncidentProps = {
  desserteId: string;
};

export const DesserteIncident: FC<DesserteIncidentProps> = ({ desserteId }) => {
  const [incidents, setIncidents] = useState<IncidentResource[]>([]);

  useEffect(() => {
    desserteService.findIncidents(desserteId).then(setIncidents);
  }, [desserteId]);

  return (
    <Collapse
      title="Incidents"
      subtitle="Liste des incidents liés à la desserte"
      leftIcon={ViewIcons.incident}
    >
      {incidents.length ? (
        <Incidents incidents={incidents} />
      ) : (
        <ViewListItemEmpty />
      )}
    </Collapse>
  );
};
