import { useUser } from "hooks";
import { FC } from "react";
import {
  paysService,
  situationGeographiqueService,
  villeService,
  deviseService,
  zoneService,
} from "services";
import { SelectAsyncProps } from "types/select-async.type";
import { buildSelectItem } from "utils/helpers";
import { AutoCompleteAsyncHookForm } from "../AutoComplete";

export const SelectPaysAsycForm: FC<SelectAsyncProps> = (props) => {
  const fetchData = async () => {
    const { data } = await paysService.findAll();
    return {
      data: data.map(({ libelle, id }) => buildSelectItem(libelle, id)),
      hasMore: false,
    };
  };
  return <AutoCompleteAsyncHookForm {...props} fetchData={fetchData} />;
};

export const SelectDeviseAsycForm: FC<SelectAsyncProps> = (props) => {
  const fetchData = async () => {
    const { data } = await deviseService.findAll();
    return {
      data: data.map(({ libelle, id }) => buildSelectItem(libelle, id)),
      hasMore: false,
    };
  };
  return <AutoCompleteAsyncHookForm {...props} fetchData={fetchData} />;
};

export const SelectZoneAsycForm: FC<SelectAsyncProps> = ({
  queryParams,
  ...props
}) => {
  const { paysId } = useUser();

  const fetchData = async () => {
    const { data } = await zoneService.findAll({
      paysId,
      ...(queryParams || {}),
    });
    return {
      data: data.map(({ libelle, id }) => buildSelectItem(libelle, id)),
      hasMore: false,
    };
  };
  return <AutoCompleteAsyncHookForm {...props} fetchData={fetchData} />;
};

export const SelectVilleAsycForm: FC<SelectAsyncProps> = ({
  queryParams,
  ...props
}) => {
  const { paysId } = useUser();

  const fetchData = async () => {
    const { data } = await villeService.findAll({
      paysId,
      ...(queryParams || {}),
    });
    return {
      data: data.map(({ libelle, id }) => buildSelectItem(libelle, id)),
      hasMore: false,
    };
  };
  return <AutoCompleteAsyncHookForm {...props} fetchData={fetchData} />;
};

export const SelectSituationGeographiqueAsycForm: FC<SelectAsyncProps> = ({
  queryParams,
  ...props
}) => {
  const { paysId } = useUser();

  const fetchData = async () => {
    const { data } = await situationGeographiqueService.findAll({
      paysId,
      ...(queryParams || {}),
    });
    return {
      data: data.map(({ libelle, id }) => buildSelectItem(libelle, id)),
      hasMore: false,
    };
  };
  return <AutoCompleteAsyncHookForm {...props} fetchData={fetchData} />;
};
