import React, { FC, ReactNode, useMemo, useRef } from "react";
import { centreService } from "services";
import { RequestParam } from "types";
import { CentreResource } from "types/centre.type";
import { addOrUpdateElement } from "utils/helpers";

export type IAppParam<T> = {
  fetchData: (param?: RequestParam) => Promise<T[]>;
  update: (element: T) => void;
};

const InitialParam = {
  fetchData: () => Promise.resolve([]),
  update: () => {},
};

export const AppContext = React.createContext<
  Record<string, IAppParam<CentreResource>>
>({
  centres: InitialParam,
});

type AppContextProviderProps = {
  children: ReactNode;
};

// Pratique pour garder en cache les données qui seront réutiliser pour le formulaire
export const AppContextProvider: FC<AppContextProviderProps> = ({
  children,
}) => {
  const centresRef = useRef<CentreResource[]>();

  const values = useMemo(() => {
    return {
      centres: {
        fetchData: (param?: RequestParam) => {
          if (centresRef.current && centresRef.current.length) {
            return Promise.resolve(centresRef.current);
          }
          return centreService.findAll(param).then(({ data }) => {
            centresRef.current = data;
            return data;
          });
        },
        update: (element: CentreResource) => {
          const index = (centresRef.current || []).findIndex(
            ({ id }) => id === element.id
          );
          centresRef.current = addOrUpdateElement(
            centresRef.current || [],
            element,
            index
          );
        },
      },
    };
  }, []);

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};
