import { Close, MoreVert } from "@mui/icons-material";
import {
  Button,
  Divider,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  PopoverOrigin,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { FC, useState } from "react";
import { MoreActionItem } from "types/menu";

const MoreAction: FC<{ handleClose: () => void } & MoreActionItem> = ({
  title,
  subtitle,
  handleClose,
  Icon,
  disabled,
}) => {
  return (
    <MenuItem disabled={disabled} onClick={handleClose} sx={{ paddingY: 1.25 }}>
      {Icon && <ListItemIcon>{Icon}</ListItemIcon>}
      <ListItemText
        primary={<Typography variant="subtitle1">{title}</Typography>}
        secondary={
          <Typography variant="body2" color="text.secondary">
            {subtitle}
          </Typography>
        }
      />
    </MenuItem>
  );
};

type MoreMenuProps = {
  title?: string;
  actions: MoreActionItem[];
  onClose: () => void;
  anchorEl: any | null;
  onSelected: (action: MoreActionItem) => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
};

export const MoreMenu: FC<MoreMenuProps> = ({
  title,
  actions,
  onSelected,
  ...rest
}) => {
  const open = Boolean(rest.anchorEl);
  return (
    <Menu
      {...rest}
      open={open}
      elevation={3}
      sx={{
        ".MuiMenu-list": {
          paddingTop: 0,
          minWidth: 300,
        },
      }}
    >
      <Grow in>
        <div>
          <Stack
            padding={1.5}
            sx={{ bgcolor: (t) => t.palette.background.default }}
            flexDirection="row"
            alignItems="center"
          >
            <Typography variant="subtitle1">{title || "Menu"}</Typography>
            <Tooltip title="Fermer le menu">
              <IconButton
                size="small"
                color="error"
                sx={{ marginLeft: "auto" }}
                onClick={rest.onClose}
              >
                <Close />
              </IconButton>
            </Tooltip>
          </Stack>
          <Divider />
          {actions
            .filter((e) => e.show === true || e.show === undefined)
            .map((action) => (
              <React.Fragment key={action.code}>
                {action.code === "separator" ? (
                  <Divider />
                ) : (
                  <MoreAction
                    handleClose={() => {
                      rest.onClose();
                      onSelected(action);
                    }}
                    {...action}
                  />
                )}
              </React.Fragment>
            ))}
        </div>
      </Grow>
    </Menu>
  );
};

type MoreActionsProps = {
  actions: MoreActionItem[];
  onSelected: (action: MoreActionItem) => void;
  optionTitle?: string;
};

export const MoreActions: FC<MoreActionsProps> = ({
  actions,
  onSelected,
  optionTitle,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {optionTitle ? (
        <Button
          onClick={handleClick}
          color="primary"
          variant="outlined"
          startIcon={<MoreVert />}
        >
          {optionTitle}
        </Button>
      ) : (
        <IconButton
          sx={{
            boxShadow: (t) => t.shadows[1],
            bgcolor: (t) => t.palette.grey[100],
          }}
          onClick={handleClick}
          color="primary"
        >
          <MoreVert />
        </IconButton>
      )}
      <MoreMenu
        actions={actions}
        anchorEl={anchorEl}
        onClose={handleClose}
        onSelected={onSelected}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      />
    </div>
  );
};
