import ListBilleterie from "./ListBilleterie";

const ListPiece = () => {
  return (
    <ListBilleterie
      code="pieces"
      libelle="Pièces"
      description="Consulter et gérer les pièces"
      btn={{ label: "Ajouter un pièce" }}
    />
  );
};

export default ListPiece;
