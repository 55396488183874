import { useFormContext } from "react-hook-form";

import { Collapse } from "components";
import { PlanificationDessertes } from "./planification";
import { TrajetIcon, ViewIcons } from "pages/common";
import { PlanificationDesserteFilters } from "./planification";
import { TrajetItem, ViewTrajet } from "../view/trajet";

export const TourneeDessertesForm = () => {
  const { watch } = useFormContext();

  const trajets = watch("trajets");

  return (
    <>
      <Collapse
        title="Planification"
        subtitle="Choisir les dessertes à mettre dans la tournée"
        leftIcon={ViewIcons.event}
      >
        <PlanificationDesserteFilters />
        <PlanificationDessertes />
      </Collapse>
      {!!trajets?.length && (
        <Collapse
          title="Trajet"
          subtitle="Itinéraire à suivre par l'équipe"
          leftIcon={<TrajetIcon color="primary" />}
          sx={{ marginTop: 3 }}
        >
          <ViewTrajet
            trajets={watch("trajets")}
            renderItem={(trajet) => <TrajetItem trajet={trajet} />}
          />
        </Collapse>
      )}
    </>
  );
};
