import { FC, Fragment, useMemo } from "react";
import { CheckBox, Collapse } from "components";
import MainCard from "components/MainCard";
import { useFormContext, useFieldArray } from "react-hook-form";
import { ViewIcons } from "pages/common";
import {
  Alert,
  Box,
  Chip,
  FormControlLabel,
  Stack,
  Switch,
} from "@mui/material";

export const LIST_ECRANS = [
  {
    label: "Personnels",
    code: "personnels",
  },
  {
    label: "Clients",
    code: "clients",
  },
  {
    label: "Sites",
    code: "sites",
  },
  {
    label: "Véhicules",
    code: "vehicules",
  },
  {
    label: "Dessertes",
    code: "dessertes",
  },
  {
    label: "Tournées",
    code: "tournees",
  },
  {
    label: "Rapport TDF",
    code: "rapport_tdf",
  },
  {
    label: "Rapport Stock",
    code: "rapport_stock",
  },
  {
    label: "Chiffre d'affaire",
    code: "rapport_ca",
  },
  {
    label: "Factures",
    code: "factures",
  },
  {
    label: "Reçus",
    code: "recus",
  },
  {
    label: "Incidents",
    code: "incidents",
  },
  {
    label: "Stocks",
    code: "stocks",
  },
  {
    label: "Magasins",
    code: "magasins",
  },
  {
    label: "Approvisionnements",
    code: "approvisionnements",
  },
  {
    label: "Ventes",
    code: "ventes",
  },
  {
    label: "Inventaires",
    code: "inventaires",
  },
  {
    label: "Paiements",
    code: "paiements",
  },
  {
    label: "Bénéficiaires",
    code: "beneficiaires",
  },
  {
    label: "Tickets",
    code: "tickets",
  },
  {
    label: "DAB",
    code: "dab",
  },
];

export const DROITS = [
  {
    label: "Lecture",
    code: "LECTURE",
  },
  {
    label: "Ecriture",
    code: "ECRITURE",
  },
];

export const RoleHabilitationForm: FC = () => {
  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext();

  const habilitationsArray = useFieldArray({ control, name: "habilitations" });
  const habilitations = watch("habilitations");

  const toutCocher = useMemo(() => {
    return (habilitations || []).every((habilitation: any) => {
      return habilitation?.value?.length === DROITS.length;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [habilitations, habilitationsArray]);

  return (
    <MainCard
      elevation={2}
      boxShadow
      sx={{ width: "100%" }}
      headerSX={{ backgroundColor: "background.default" }}
    >
      <Collapse
        title="Habilitations"
        subtitle="Renseigner les droits par module"
        leftIcon={ViewIcons.lock}
      >
        {errors.habilitations && (
          <Alert color="error" variant="filled" sx={{ marginBottom: 2 }}>
            {errors.habilitations.message?.toString()}
          </Alert>
        )}
        <FormControlLabel
          control={
            <Switch
              id="tousLesDroits"
              name="tousLesDroits"
              checked={toutCocher}
              onChange={({ target: { checked } }) => {
                habilitations?.forEach((habilitation: any, i: number) => {
                  habilitationsArray.update(i, {
                    ...habilitation,
                    value: checked ? DROITS.map(({ code }) => code) : null,
                  });
                });
              }}
            />
          }
          label="Tous les droits"
          sx={{ marginBottom: 2 }}
        />

        {habilitationsArray.fields.map((field, i) => (
          <Fragment key={field.id}>
            <Stack
              flexDirection="row"
              alignItems="center"
              padding={1}
              sx={{
                bgcolor: (t) =>
                  i % 2 === 0 ? t.palette.grey[50] : "transparent",
              }}
            >
              <Box sx={{ minWidth: 150 }}>
                <Chip
                  label={watch(`habilitations.${i}.label`)}
                  size="small"
                  color="info"
                  variant="light"
                  sx={{ fontWeight: 600 }}
                />
              </Box>

              <Stack flexDirection="row">
                {DROITS.map((droit) => (
                  <FormControlLabel
                    control={
                      <CheckBox
                        name={`habilitations.${i}.value`}
                        value={droit.code}
                      />
                    }
                    label={droit.label}
                    sx={{ marginRight: 3 }}
                    key={droit.code}
                  />
                ))}
              </Stack>
            </Stack>
            {/* {habilitationsArray.fields.length !== i + 1 && <Divider />} */}
          </Fragment>
        ))}
      </Collapse>
    </MainCard>
  );
};
