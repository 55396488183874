import { BorderColor } from "@mui/icons-material";
import { MoreActions } from "components";
import { FC, useState } from "react";
import { ColisResource } from "types/transport.types";
import { useUser } from "hooks";
import { ModifierColis } from "./ModifierColis";

type ColisActionsProps = {
  onFinished: (colis: ColisResource) => void;
  colis: ColisResource;
};

export const ColisActions: FC<ColisActionsProps> = ({ colis, onFinished }) => {
  const [action, setAction] = useState<string | undefined>();
  const { canWrite } = useUser();
  const canWriteDesserte = canWrite("dessertes");

  const closeModal = () => setAction(undefined);

  if (!canWriteDesserte) {
    return null;
  }

  return (
    <>
      <MoreActions
        onSelected={({ code }) => setAction(code)}
        actions={[
          {
            code: "modifier",
            title: "Modifier",
            subtitle: "Mettre à jour les infos du colis",
            Icon: <BorderColor color="error" />,
            // disabled: !isTransitable || estLivree,
            show: canWriteDesserte,
          },
        ]}
      />
      {action === "modifier" && (
        <ModifierColis
          colis={colis}
          closeModal={closeModal}
          onFinished={onFinished}
        />
      )}
    </>
  );
};
