import { useCallback } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "reducers";
import { RequestParam } from "types";
import { PersonnelResource } from "types/personnel.type";
import {
  hasPerimetreChefCentreRegional,
  hasPerimetreToutCentre,
} from "utils/user.helpers";

export const useRestrictions = () => {
  const user = useSelector(selectUser);

  if (!user) {
    throw new Error("Pas d'utilisateur");
  }

  const getCentreRequestRestrictions = useCallback(() => {
    if (hasPerimetreToutCentre(user)) {
      return {};
    }

    if (hasPerimetreChefCentreRegional(user)) {
      const userable = user.userable;
      return {
        centreRegionalId: userable
          ? (userable as PersonnelResource)?.centreRegional?.id
          : "",
      };
    }

    // Par défaut on fait restriction sur le centre
    const userable = user.userable;
    return {
      centreId: userable ? (userable as PersonnelResource)?.centre?.id : "",
    };
  }, [user]);

  const addCentreRestrictions = useCallback(
    (requestParam: RequestParam) => {
      if (hasPerimetreToutCentre(user)) {
        return requestParam;
      }

      if (
        hasPerimetreChefCentreRegional(user) &&
        !requestParam.centreRegionalId
      ) {
        const userable = user.userable;
        const centreRegionalId = userable
          ? (userable as PersonnelResource)?.centreRegional?.id
          : "";
        return { ...requestParam, centreRegionalId };
      }

      if (!requestParam.centreId) {
        const userable = user.userable;
        const centreId = userable
          ? (userable as PersonnelResource)?.centre?.id
          : "";
        return { ...requestParam, centreId };
      }
      return requestParam;
    },
    [user]
  );

  return { getCentreRequestRestrictions, addCentreRestrictions };
};
