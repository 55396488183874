import { useEffect, useMemo, useState } from "react";

import {
  Button,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

// assets
import { TableSimple } from "components";
import { PageTitle } from "pages/common";
import { deviseService } from "services/deviseService";

import { DeviseResource } from "types/centre.type";
import { useModal } from "hooks";
import { addOrUpdateElement } from "utils/helpers";
import { EditTwoTone } from "@ant-design/icons";
import { DeviseModal } from "./DeviseModal";

const ListDevise = () => {
  const theme = useTheme();
  const [defaultValues, setDefaultValues] = useState<any | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<DeviseResource[]>([]);
  const modal = useModal();

  useEffect(() => {
    setLoading(true);

    deviseService
      .findAll()
      .then((resultat) => {
        setData(resultat.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const save = (data: any) => {
    if (defaultValues?.id) {
      return deviseService.update(defaultValues.id, {
        ...data,
      });
    }
    return deviseService.create({ ...data });
  };

  const onEdit = (original: DeviseResource) => {
    setDefaultValues({
      id: original.id,
      libelle: original.libelle,
      code: original.code,
      symbole: original.symbole,
      valeurEuro: original.valeurEuro,
    });
    modal.show();
  };

  const closeModal = () => {
    modal.close();
    setDefaultValues(undefined);
  };

  const onFinished = (element: DeviseResource) => {
    const index = data.findIndex(({ id }) => id === element.id);
    setData((d) => addOrUpdateElement(d, element, index));
    closeModal();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Libelle",
        accessor: "libelle",
        Cell: ({ row: { original } }: any) => (
          <Typography variant="subtitle1">{original.libelle}</Typography>
        ),
      },
      {
        Header: "Code",
        accessor: "code",
        Cell: ({ row: { original } }: any) => (
          <Typography>{original.code || "-"}</Typography>
        ),
      },
      {
        Header: "Symbole",
        accessor: "symbole",
        Cell: ({ row: { original } }: any) => (
          <Typography>{original.symbole || "-"}</Typography>
        ),
      },
      {
        Header: "Valeur en EURO",
        accessor: "valeurEuro",
        Cell: ({ row: { original } }: any) => (
          <Typography>{original.valeurEuro || "-"}</Typography>
        ),
      },

      {
        Header: "Actions",
        accessor: "actions",

        Cell: ({ row: { original } }: any) => (
          <Tooltip title="Modifier la devise">
            <IconButton
              color="error"
              size="large"
              onClick={() => onEdit(original)}
            >
              <EditTwoTone twoToneColor={theme.palette.error.main} />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <PageTitle
        title="Devise"
        subtitle="Consulter et gérer les devises"
        sx={{ padding: "1.5rem 0" }}
        right={
          <Button variant="contained" onClick={modal.show}>
            Créer une devise
          </Button>
        }
      />

      <TableSimple
        columns={columns}
        data={data}
        withPagination
        loading={loading}
      />
      {modal.open && (
        <DeviseModal
          onBack={closeModal}
          onFinished={onFinished}
          onSave={save}
          defaultValues={defaultValues}
        />
      )}
    </>
  );
};

export default ListDevise;
