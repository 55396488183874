import { FC } from "react";
import { tourneeService } from "services";
import { SelectAsyncProps } from "types/select-async.type";
import { AutoCompleteAsyncHookForm } from "../AutoComplete";
import { Stack, Typography } from "@mui/material";
import { formatEquipage } from "pages/tournees/view/equipage";

const renderTourneeItem = (props: any, option: any) => {
  const equipage = option.equipages?.find((e: any) => e.initial === "1");
  return (
    <Stack>
      <Typography variant="subtitle1">{option.label}</Typography>
      <Typography color="secondary" variant="caption">
        {equipage ? formatEquipage(equipage) : null}
      </Typography>
    </Stack>
  );
};

export const SelectTourneeAsyncForm: FC<SelectAsyncProps> = (props) => {
  const fetchData = async () => {
    const { data } = await tourneeService.findAll(props.queryParams);
    return {
      data: data.map(({ id, numero, equipages }) => ({
        label: numero,
        value: id,
        equipages,
      })),
      hasMore: false,
    };
  };
  return (
    <AutoCompleteAsyncHookForm
      {...props}
      fetchData={fetchData}
      renderOption={renderTourneeItem}
    />
  );
};
