import { FC } from "react";
import { Grid } from "@mui/material";
import { PrestationMontantForm } from "pages/common";

type DesserteFacturationProps = {};

export const DesserteFacturation: FC<DesserteFacturationProps> = () => {
  return (
    <Grid container spacing={3}>
      <PrestationMontantForm hasTdf loading={false} />
    </Grid>
  );
};
