import { FC } from "react";
import { jonctionService } from "services";
import { useFormContext } from "react-hook-form";
import { DesserteResource, EquipageResource } from "types/transport.types";
import { formatEquipage } from "../view/equipage/EquipageItem";
import {
  Alert,
  AlertTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { withForm, WrapperProps } from "hoc";
import { UtilitaireModal } from "pages/utilitaires";
import { DesserteSens } from "pages/dessertes";

const Form: FC<WrapperProps> = (props) => {
  const { watch } = useFormContext();
  const nomEquipage = watch("nomEquipage");
  const dessertes = watch("dessertes") || [];

  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title={`Passer la main à l'équipage ${nomEquipage}`}
      maxWidth="sm"
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Alert severity="warning" variant="border">
            <AlertTitle sx={{ fontWeight: 600, textTransform: "uppercase" }}>
              PASSER LA MAIN
            </AlertTitle>
            Les dessertes suivantes seront maintenant traitées par l'équipage
            &nbsp;<strong>{nomEquipage}</strong>
          </Alert>
          <List>
            {dessertes.map((desserte: any) => (
              <ListItem key={desserte.id} divider>
                <ListItemText
                  primary={<DesserteSens desserte={desserte} />}
                  secondaryTypographyProps={{
                    component: "div",
                  }}
                  secondary={`N°${desserte.numero}`}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </UtilitaireModal>
  );
};

const PasserLaMainModal = withForm(Form);

type PasserLaMainProps = {
  equipage: EquipageResource;
  jonctionId: string;
  dessertes: DesserteResource[];
  closeModal: () => void;
  onFinished: (data: EquipageResource) => void;
};

export const PasserLaMain: FC<PasserLaMainProps> = ({
  equipage,
  dessertes,
  closeModal,
  onFinished,
  jonctionId,
}) => {
  const passerLaMain = () => {
    return jonctionService.passerLaMain(jonctionId);
  };

  return (
    <PasserLaMainModal
      onSave={passerLaMain}
      onBack={closeModal}
      onFinished={onFinished}
      defaultValues={{
        dessertes,
        nomEquipage: formatEquipage(equipage),
      }}
    />
  );
};
