import { FC, useContext, useMemo } from "react";
import { Box, Button, Grid, IconButton, Tooltip } from "@mui/material";
import {
  CentreAsyncSelectForm,
  Collapse,
  SelectTourneeAsyncForm,
  TextInput,
} from "components";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Delete, Event } from "@mui/icons-material";
import { PlanifierTourneeContext } from "../context";
import { ViewIcons } from "pages/common";
import { SelectDessertes } from "components/form/asycSelect/SelectDessertes";
import { dateFormater } from "utils/date";

export const TourneeJonctionForm: FC = () => {
  const { watch } = useFormContext();
  const { control } = useFormContext();

  const jonctions = useFieldArray({ control, name: "jonctions" });
  return (
    <>
      <Collapse
        title="Jonctions"
        subtitle="Ajouter une ou plusieurs jonctions à la tournée"
        leftIcon={ViewIcons.jonction}
      >
        {jonctions.fields.map((item, i) => {
          const centreId = watch(`jonctions.${i}.centre`)?.value;
          return (
            <JonctionForm
              centreId={centreId}
              index={i.toString()}
              key={item.id}
              onDelete={() => jonctions.remove(i)}
            />
          );
        })}
        <Grid item>
          <Button
            variant="contained"
            startIcon={<Event />}
            onClick={() => {
              jonctions.append({});
            }}
          >
            Ajouter une jonction
          </Button>
        </Grid>
      </Collapse>
    </>
  );
};

type JonctionFormProps = {
  centreId: string;
  index: string;
  onDelete: () => void;
};

const JonctionForm: FC<JonctionFormProps> = ({ centreId, index, onDelete }) => {
  const { right } = useContext(PlanifierTourneeContext);
  const { setValue, watch } = useFormContext();
  const dateDepart = watch("dateDepart");

  const tourneeDeps = useMemo(
    () => [{ value: centreId, label: centreId }],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [centreId, dateDepart]
  );

  return (
    <Box
      sx={{ padding: 1, marginBottom: 2 }}
      bgcolor={(e) => e.palette.grey[50]}
    >
      <Box sx={{ display: "flex", marginBottom: 1 }}>
        <Tooltip title="Supprimer la jonction">
          <IconButton
            sx={{ marginLeft: "auto" }}
            size="small"
            color="error"
            onClick={onDelete}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <CentreAsyncSelectForm
            label="Centre *"
            name={`jonctions.${index}.centre`}
            size="small"
            afterSelected={() => {
              setValue(`jonctions.${index}.tournee`, null);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectTourneeAsyncForm
            name={`jonctions.${index}.tournee`}
            label="Tourneeé *"
            size="small"
            disabled={!centreId}
            resetDependencies={tourneeDeps}
            queryParams={{
              centreId,
              dateDepart: dateFormater.toBackPattern(dateDepart, "yyyy-MM-dd"),
              states: "init,en_cours",
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextInput
            label="Lieu jonction *"
            name={`jonctions.${index}.lieu`}
            size="small"
            disabled={!centreId}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectDessertes
            name={`jonctions.${index}.dessertes`}
            label="Dessertes en jonction"
            defaultOptions={right}
            disabled={!centreId}
            limitTags={5}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
