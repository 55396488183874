// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

import { NotificationDrawer } from "./NotificationDrawer";
import { Outlet } from "react-router-dom";
import MainCard from "components/MainCard";
import { selectNotifications } from "reducers";
import { useSelector } from "react-redux";

const NotificationsTemplate = () => {
  const theme = useTheme();
  const { data } = useSelector(selectNotifications);

  if (!data.length) {
    return (
      <Typography
        variant="h5"
        fontStyle="italic"
        textAlign="center"
        marginTop={5}
      >
        Aucune notification disponible
      </Typography>
    );
  }
  return (
    <Box
      sx={{
        height: `calc(100vh - ${theme.spacing(2 * 2)} - 60px)`,
        display: "flex",
        overflowX: "hidden",
        backgroundColor: "transparent",
      }}
    >
      <NotificationDrawer />
      <Box
        sx={{
          overflowX: "hidden",
          height: `calc(100vh - ${theme.spacing(2 * 2)} - 60px)`,
          width: "100%",
          position: "relative",
        }}
      >
        <MainCard
          sx={{
            overflowX: "hidden",
            height: `calc(100vh - ${theme.spacing(2 * 2)} - 60px)`,
            width: "100%",
            position: "relative",
            marginLeft: 1,
          }}
        >
          <Outlet />
        </MainCard>
      </Box>
    </Box>
  );
};

export default NotificationsTemplate;
