import { endOfMonth } from "date-fns";
import {
  CODE_REFERENTIEL,
  REFERENTIEL_TYPE,
  RequestParam,
  UserResource,
} from "types";
import {
  CentreRegionalResource,
  CentreResource,
  PaysResource,
  VilleResource,
  SituationGeographiqueResource,
  DeviseResource,
} from "types/centre.type";
import { SelectItem } from "types/form.type";
import { PersonnelResource } from "types/personnel.type";
import { ReferentielResource } from "types/referentiel.type";
import { FonctionResource, ServiceResource } from "types/service.type";
import { VehiculeResource } from "types/transport.types";
import { DATE_PATTERN, dateFormater, dateParser } from "./date";
import { get } from "lodash";
import {
  FournisseurResource,
  MagasinResource,
  FamilleArticleResource,
  ArticleResource,
  Numerotation,
} from "types/stock.type";
import { ClientResource, SiteResource } from "types/client.type";
import { TicketCategorieResource } from "types/ssb.type";
import { PRESTATIONS } from "./constant";

export const buildReferentielItem = (
  data: ReferentielResource[],
  type?: REFERENTIEL_TYPE
) => {
  return data
    .filter((item) => item.type === type)
    .map((item) => ({
      label: item.name,
      value: item.id,
    }));
};

export const getSelectValue = (item?: SelectItem) => {
  return item?.value;
};

export const buildSelectItem = (label: string, value: string) => {
  return { label, value };
};

export const selectHelper = {
  getValue: (item?: SelectItem) => {
    return item?.value || null;
  },
  build: (label?: string, value?: string): SelectItem | undefined | null => {
    if (value === undefined || value === null || !label) {
      return null;
    }
    return { label, value };
  },
  buildPersonnel: (personnel?: PersonnelResource) => {
    return personnel
      ? selectHelper.build(
          `${personnel.firstname} ${personnel.lastname}`,
          personnel.id
        )
      : null;
  },
  buildReferentiel: (data?: ReferentielResource) => {
    return selectHelper.build(data?.name, data?.id);
  },
  buildPays: (data?: PaysResource) => {
    return selectHelper.build(data?.libelle, data?.id);
  },
  buildDevise: (data?: DeviseResource) => {
    return selectHelper.build(data?.libelle, data?.id);
  },
  buildVille: (data?: VilleResource) => {
    return selectHelper.build(data?.libelle, data?.id);
  },
  buildCentre: (data?: CentreResource) => {
    return selectHelper.build(data?.libelle, data?.id);
  },
  buildCentreRegional: (data?: CentreRegionalResource) => {
    return selectHelper.build(data?.libelle, data?.id);
  },
  buildService: (data?: ServiceResource) => {
    return selectHelper.build(data?.libelle, data?.id);
  },
  buildFonction: (data?: FonctionResource) => {
    return selectHelper.build(data?.libelle, data?.id);
  },
  buildSituationGeographique: (data?: SituationGeographiqueResource) => {
    return selectHelper.build(data?.libelle, data?.id);
  },
  buildVehicule: (data?: VehiculeResource) => {
    return selectHelper.build(data?.marque, data?.id);
  },
  buildFournisseur: (data?: FournisseurResource) => {
    return selectHelper.build(data?.nom, data?.id);
  },
  buildMagasin: (data?: MagasinResource) => {
    return selectHelper.build(data?.libelle, data?.id);
  },
  buildFamilleArticle: (data?: FamilleArticleResource) => {
    return selectHelper.build(data?.designation, data?.id);
  },
  buildArticle: (data?: ArticleResource) => {
    return selectHelper.build(data?.designation, data?.id);
  },
  buildSite: (data?: SiteResource) => {
    return selectHelper.build(data?.nom, data?.id);
  },
  buildClient: (data?: ClientResource) => {
    return selectHelper.build(data?.nom, data?.id);
  },
};

export const numberHelper = {
  validation: {
    test: (value?: string | null) => {
      if (value === undefined) {
        return false;
      }
      const _value = value?.trim() || "";
      return _value === "" ? true : !isNaN(parseInt(_value));
    },
    message: "Valeur incorrecte",
  },
  trim: (montant?: string | null) =>
    montant ? montant.toString().replaceAll(" ", "") : montant,

  stringToDouble: (montant?: string | null) =>
    numberHelper.trim(montant)?.replaceAll(",", "."),

  parseInt: (value?: string | null) => {
    return parseInt(numberHelper.trim(value) || "") || 0;
  },

  isPositive: (value?: string | null) => {
    return numberHelper.parseInt(value) >= 0;
  },

  getNumber: (value?: string | null) => {
    return value?.match(/\d+/g)?.join("") || "";
  },
};

export const requestParams = (params?: RequestParam) => {
  const entries = Object.entries(params || {}).filter(
    (entry) => entry[1] !== undefined && entry[1] !== null
  );
  if (entries.length > 0) {
    return `?${entries.map((entry) => `${entry[0]}=${entry[1]}`).join("&")}`;
  }
  return "";
};

export const ETAT_COLOR: Record<string, any> = {
  livre: "success",
  termine: "success",
  finalise: "success",
  absent: "warning",
  valide: "info",
  en_cours: "info",
  t_en_cours: "info",
  t_cloturer: "success",
  t_affecter: "warning",
  init: "warning",
  paye: "success",
  non_paye: "warning",
  annule: "error",
  en_service: "success",
  hors_service: "error",
};

export const getEtatColor = (etat: ReferentielResource): any => {
  return ETAT_COLOR[etat.id || (etat as any).value] || "default";
};

export const uuid = () =>
  String(Date.now().toString(32) + Math.random().toString(16)).replace(
    /\./g,
    ""
  );

export function updateElement(list: any[], element: any, index: number) {
  return [...list.slice(0, index), element, ...list.slice(index + 1)];
}

export function addOrUpdateElement(list: any[], element: any, index: number) {
  return index < 0
    ? [...list, element]
    : [...list.slice(0, index), element, ...list.slice(index + 1)];
}

export function deleteElement(list: any[], index: number) {
  return [...list.slice(0, index), ...list.slice(index + 1)];
}

export function addElement(list: any[], element: any) {
  return [...list, element];
}

export const buildUserFullName = (user?: UserResource) => {
  if (!user) {
    return "";
  }

  return `${user.firstname} ${user.lastname}`;
};

export const buildUserShortName = (user?: UserResource) => {
  if (!user) {
    return "";
  }
  return `${user.lastname ? user.lastname[0] : ""}. ${user.firstname}`;
};

export const libelleBuilder = {
  buildRegime: (intramuros?: number) => {
    return intramuros ? "Intra muros" : "Extra muros";
  },
};

export const isIntraMuros = (value: string) => {
  return value === "1";
};

type IComputeMontant = {
  montantKmVb?: number;
  kmVoieBitumee?: number;
  montantKmVnb?: number;
  kmVoieNonBitumee?: number;
};

export const desserteHelper = {
  computeMontant: (param: IComputeMontant) => {
    return (
      (param.montantKmVb || 0) * (param.kmVoieBitumee || 0) +
      (param.montantKmVnb || 0) * (param.kmVoieNonBitumee || 0)
    );
  },
  isFacturationMensuel: (mode: string) => "0" === mode,
  isFacturationParDesserte: (mode: string) => "1" === mode,
  isFacturationPourcentage: (mode: string) => "2" === mode,

  isExtraMuros: (mode?: string) => "0" === mode,
  isIntraMuros: (mode?: string) => "1" === mode,

  isDepot: (type?: string) => CODE_REFERENTIEL.APPRO === type,

  getSiteNameByTypeDesserte: (type?: string) => {
    return desserteHelper.isDepot(type)
      ? "Site d’expédition"
      : "Site destination";
  },

  getLibelleModeFacture: (modeFacture?: number) => {
    switch (modeFacture?.toString()) {
      case "0":
        return "Mensuel";
      case "2":
        return "Pourcentage colis";
      default:
        return "Par desserte";
    }
  },

  getLibelleRegime: (intramuros?: number) => {
    return intramuros ? "Intra muros" : "Extra muros";
  },
};

export const ssbHelper = {
  isExtraMuros: (mode?: string) => "0" === mode,
  isIntraMuros: (mode?: string) => "1" === mode,
};

export const getNombreDeJours = (mois?: any, annee?: any) => {
  const periode = dateParser.toDate(`1/${mois}/${annee}`) || new Date();
  return endOfMonth(periode).getDate();
};

export function getJsonValue(obj: Record<string, any>, key: string) {
  return get(obj, key);
}

export const filterHelper = {
  dateFormater: (date: Date) => {
    return date
      ? dateFormater.toBackPattern(date, DATE_PATTERN.yyyyMMdd)
      : null;
  },
};

export const stockHelper = {
  buildPrixRevient: (articles?: any[]) => {
    if (!articles) {
      return 0;
    }
    return articles
      .map((article) => {
        return (
          numberHelper.parseInt(article.prixRevient) *
          numberHelper.parseInt(article.quantite)
        );
      })
      .reduce((prev, curr) => curr + prev, 0);
  },
  buildPrixVente: (articles?: any[]) => {
    if (!articles) {
      return 0;
    }
    return articles
      .map((article) => {
        return (
          numberHelper.parseInt(article.prixVente) *
          numberHelper.parseInt(article.quantite)
        );
      })
      .reduce((prev, curr) => curr + prev, 0);
  },

  computeArticlePrixVente: (client: any, article: any) => {
    const clientArticle = client?.articles?.find(
      (a: any) => a.id === article?.id
    );
    return clientArticle?.prixVente || article?.prixVente || "";
  },

  computeQuantiteArticle: (numeros?: Numerotation[]) => {
    if (!numeros) {
      return 0;
    }
    return numeros
      .map((numero) => {
        const debutNumerotation = numberHelper.parseInt(
          numberHelper.getNumber(numero.debutNumerotation)
        );
        const finNumerotation = numberHelper.parseInt(
          numberHelper.getNumber(numero.finNumerotation)
        );
        return finNumerotation <= debutNumerotation
          ? 0
          : finNumerotation - debutNumerotation + 1;
      })
      .reduce((prev, curr) => curr + prev, 0);
  },
};

export const beneficiaireHelper = {};

export const ticketCategorieHelper = {
  isRacine: (categorie: TicketCategorieResource) => {
    return !Boolean(categorie.parent);
  },

  hasRestriction: (categorie: TicketCategorieResource) => {
    return categorie.limitable === "1";
  },
};

export const buildPrestationFromClient = (client: ClientResource) => {
  return PRESTATIONS.filter(({ value }) => {
    return [
      value === "TDF" && client.transportDeFond === "1",
      value === "VENTE" && client.transportDeFond === "1",
      value === "SSB" && client.ssbService === "1",
      value === "PAIEMENT" && client.paiementSurSite === "1",
    ].some(Boolean);
  });
};

export const buildPrestationFromSite = (site: SiteResource) => {
  return PRESTATIONS.filter(({ value }) => {
    return [
      value === "TDF" && site.transportDeFond === "1",
      value === "VENTE" && site.transportDeFond === "1",
      value === "SSB" && site.ssb === "1",
      value === "PAIEMENT" && site.paiementSurSite === "1",
    ].some(Boolean);
  });
};
