import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  alertError,
  alertInfo,
  alertSuccess,
  alertWarning,
  showLoader,
} from "reducers";
import { Notification } from "types/message.type";

export const useNotification = () => {
  const dispatch = useDispatch();

  const success = useCallback(
    (notification?: Notification) => {
      dispatch(
        alertSuccess(
          notification || {
            title: "Traitement effectué",
            message: "Les informations ont été enregistré avec succès",
          }
        )
      );
    },
    [dispatch]
  );

  const info = useCallback(
    (notification: Notification) => {
      dispatch(alertInfo(notification));
    },
    [dispatch]
  );

  const error = useCallback(
    (notification: Notification) => {
      dispatch(alertError(notification));
    },
    [dispatch]
  );

  const warning = useCallback(
    (notification: Notification) => {
      dispatch(alertWarning(notification));
    },
    [dispatch]
  );

  const showLoading = useCallback(() => {
    dispatch(showLoader());
  }, [dispatch]);

  return {
    success,
    error,
    info,
    showLoading,
    warning,
  };
};
