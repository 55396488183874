import { incidentConverter } from "pages/incidents/form";
import { FC } from "react";
import { desserteService } from "services";
import { IncidentResource } from "types/incidents.types";
import { DesserteResource } from "types/transport.types";
import { IncidentDesserteModal } from "../form";

type IncidentDesserteProps = {
  desserte: DesserteResource;
  onFinish: (incident: IncidentResource) => void;
  onClose: () => void;
};

export const IncidentDesserte: FC<IncidentDesserteProps> = ({
  desserte,
  onFinish,
  onClose,
}) => {
  const addIncident = (body: Record<string, any>) => {
    return desserteService
      .signalerIncident(desserte.id, incidentConverter.toBody(body))
      .then((data) => data);
  };

  return (
    <IncidentDesserteModal
      onBack={onClose}
      onFinished={onFinish}
      onSave={addIncident}
      defaultValues={{
        numero: desserte.numero,
      }}
    />
  );
};
