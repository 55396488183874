// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Divider,
  Drawer,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

// project imports
import MainCard from "components/MainCard";
import SimpleBar from "components/third-party/SimpleBar";
import { ListNotification } from "./ListNotification";
import { Refresh, Subject } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import {
  selectLoadingNotifications,
  setLoadingNotifications,
  setNotifications,
} from "reducers";
import { notificationService } from "services";
import { LoadingButton } from "components";
import { useNotification } from "hooks";

export const NotificationDrawer = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));

  const notification = useNotification();

  const dispatch = useDispatch();

  const loading = useSelector(selectLoadingNotifications);

  const loadNotifications = useCallback(() => {
    dispatch(setLoadingNotifications(true));
    notificationService
      .findAll()
      .then((data) => {
        dispatch(setNotifications(data));
        notification.success({
          title: "Les notifications ont été mises à jour",
        });
      })
      .finally(() => {
        dispatch(setLoadingNotifications(false));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Drawer
      sx={{
        width: 350,
        flexShrink: 0,
        zIndex: { xs: 1100, lg: 0 },
        "& .MuiDrawer-paper": {
          height: matchDownLG ? "100%" : "auto",
          width: 350,
          boxSizing: "border-box",
          position: "relative",
          border: "none",
        },
      }}
      variant={matchDownLG ? "persistent" : "persistent"}
      anchor="left"
      open={true}
      ModalProps={{ keepMounted: true }}
    >
      <MainCard border={!matchDownLG} content={false}>
        <Box sx={{ p: 2 }}>
          <Stack flexDirection="row">
            <Box sx={{ paddingTop: 0.5, marginRight: 1 }}>
              <Subject color="primary" />
            </Box>
            <Stack>
              <Typography
                sx={{
                  fontWeight: "500 !important",
                }}
                variant="h4"
                marginBottom={0}
              >
                Notifications
              </Typography>
              <Typography marginBottom={0} color="textSecondary">
                Liste des notifications
              </Typography>
            </Stack>
            <Stack flexDirection="row" marginLeft="auto" alignItems="center">
              <LoadingButton
                sx={{ marginLeft: "auto" }}
                startIcon={<Refresh />}
                color="primary"
                size="small"
                variant="contained"
                disableElevation
                loading={loading}
                onClick={loadNotifications}
              >
                Actualiser
              </LoadingButton>
            </Stack>
          </Stack>
        </Box>
        <Divider sx={{ marginBottom: 0 }} />
        <SimpleBar
          sx={{
            overflowX: "hidden",
            height: "calc(100vh - 180px)",
          }}
        >
          <ListNotification />
        </SimpleBar>
      </MainCard>
    </Drawer>
  );
};
