import { FormattedMessage } from "react-intl";

import { Home } from "@mui/icons-material";

import { NavItemType } from "types/menu";

const dashboard: NavItemType = {
  id: "group-dashboard",
  type: "group",
  children: [
    {
      id: "menu.dashbord.title",
      title: <FormattedMessage id="menu.dashbord.title" />,
      type: "item",
      url: "/",
      icon: Home,
    },
  ],
};

export default dashboard;
