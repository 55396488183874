import {
  Delete,
  ManageAccounts,
  TransferWithinAStationSharp,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import { MoreActions } from "components";
import { FC, useState } from "react";
import {
  DesserteResource,
  EquipageResource,
  JonctionResource,
} from "types/transport.types";
import { PasserLaMain } from "./PasserLaMain";
import { ModifierJonction } from "./ModifierJonction";
import { SupprimerEquipageJonction } from "./SupprimerEquipageJonction";

type EquipageJonctionActionsProps = {
  jonction: JonctionResource;
  tourneeDessertes: DesserteResource[];
  equipage: EquipageResource;
  refreshDessertes: () => void;
  updateJonction: (data: JonctionResource) => void;
  deleteJonction: (jonction: JonctionResource) => void;
};

export const EquipageJonctionActions: FC<EquipageJonctionActionsProps> = ({
  refreshDessertes,
  jonction,
  tourneeDessertes,
  equipage,
  updateJonction,
  deleteJonction,
}) => {
  const [action, setAction] = useState<string | undefined>();

  const closeModal = () => {
    setAction(undefined);
  };

  return (
    <>
      <Box sx={{ marginRight: 1 }}>
        <MoreActions
          onSelected={({ code }) => setAction(code)}
          actions={[
            {
              code: "modifier",
              subtitle: "Modifier la jonction",
              title: "Modifier jonction",
              Icon: <ManageAccounts />,
            },
            {
              code: "passer_la_main",
              title: "Passer la main",
              subtitle:
                "L'équipage jonction prendra la main sur les dessertes en jonction",
              Icon: <TransferWithinAStationSharp color="primary" />,
            },
            {
              code: "supprimer",
              title: "Supprimer",
              subtitle: "Supprimer la jonction",
              Icon: <Delete color="error" />,
            },
          ]}
        />
      </Box>

      {action === "modifier" && (
        <ModifierJonction
          jonction={jonction}
          tourneeDessertes={tourneeDessertes}
          closeModal={closeModal}
          onFinished={(data) => {
            closeModal();
            updateJonction(data);
          }}
        />
      )}
      {action === "passer_la_main" && (
        <PasserLaMain
          dessertes={tourneeDessertes.filter((d) =>
            jonction.dessertes.includes(d.id.toString())
          )}
          equipage={equipage}
          jonctionId={jonction.id}
          closeModal={closeModal}
          onFinished={(data) => {
            closeModal();
            refreshDessertes();
          }}
        />
      )}
      {action === "supprimer" && (
        <SupprimerEquipageJonction
          jonction={jonction}
          closeModal={closeModal}
          onFinished={() => {
            closeModal();
            deleteJonction(jonction);
          }}
        />
      )}
    </>
  );
};
