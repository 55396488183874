import { FormControlLabel, Switch, Tooltip } from "@mui/material";

import { FC, ReactNode, useContext } from "react";
import { PlanifierTourneeContext } from "pages/tournees/context";

type TrierDessertesProps = {
  trieAutomatique: boolean;
  order: ReactNode;
};

export const TrierDessertes: FC<TrierDessertesProps> = ({
  trieAutomatique,
  order,
}) => {
  const { setTrieAutomatique } = useContext(PlanifierTourneeContext);
  return (
    <>
      {!trieAutomatique && order}

      <Tooltip title="Trier automatiquement les dessertes par heure de passage">
        <FormControlLabel
          control={
            <Switch
              size="small"
              color="primary"
              name="trieAutomatique"
              sx={{
                margin: 0,
              }}
              onChange={(e) => {
                setTrieAutomatique(e.target.checked);
              }}
              checked={trieAutomatique}
            />
          }
          sx={{
            "& .MuiFormControlLabel-label": {
              margin: 0,
              marginLeft: 1,
            },
          }}
          label="Trier"
        />
      </Tooltip>
    </>
  );
};
