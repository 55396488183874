import { Grid } from "@mui/material";
import { Collapse, SelectSitesAsyncForm } from "components";
import MainCard from "components/MainCard";
import { withForm, WrapperProps } from "hoc";
import { TrajetIcon } from "pages/common";
import { UtilitaireModal } from "pages/utilitaires";
import { FC, useContext, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { equipageService } from "services";
import { SiteResource } from "types/client.type";
import { SelectItem } from "types/form.type";
import {
  DesserteResource,
  EquipageResource,
  TrajetResource,
} from "types/transport.types";
import { CODE_ETAT_TRAJET } from "utils/constant";
import { buildTrajets, buildTrajetSite, TrajetContext } from "../context";
import { tourneeConverter } from "../form";
import { PlanificationTrajetPanel } from "../form/planification";
import { formatEquipage } from "../view/equipage/EquipageItem";
import { TrajetItem, ViewTrajet } from "../view/trajet";

/**
 * Utilitaire de gestion du formulaire
 * @returns
 */
export const useEditTrajet = () => {
  const { watch, setValue } = useFormContext();
  const { setTrajets, trajets } = useContext(TrajetContext);
  const equipage = watch("equipage");

  const equipageTrajets = useMemo(() => {
    return (equipage?.trajets || []) as TrajetResource[];
  }, [equipage?.trajets]);

  useEffect(() => {
    setTrajets(equipageTrajets);
  }, [setTrajets, equipageTrajets]);

  useEffect(() => {
    setValue("trajets", trajets);
  }, [trajets, setValue]);

  const addSites = (sites: SiteResource[]) => {
    setTrajets([
      ...equipageTrajets,
      ...sites.map(({ site }: any) =>
        buildTrajetSite(site.id, site.nom, CODE_ETAT_TRAJET.new_site)
      ),
    ]);
  };

  const addSitesFromDessertes = (dessertes: DesserteResource[]) => {
    const trajetsComputed = [...equipageTrajets];
    const dessertesTrajets = buildTrajets(dessertes, CODE_ETAT_TRAJET.new_site);
    dessertesTrajets.forEach((trajet) => {
      if (trajetsComputed.findIndex((e) => e.idSite === trajet.idSite) === -1) {
        trajetsComputed.push(trajet);
      }
    });
    setTrajets(trajetsComputed);
  };

  const changeDestination = (item?: SelectItem) => {
    const newEquipageTrajets = [...equipageTrajets];
    if (
      item &&
      equipageTrajets.findIndex(
        (e) => e.idSite === item.value && e.etat === CODE_ETAT_TRAJET.non_passe
      ) === -1
    ) {
      newEquipageTrajets.push(
        buildTrajetSite(item.value, item.label, CODE_ETAT_TRAJET.new_site)
      );
    }
    setTrajets(newEquipageTrajets);
  };

  return { addSites, addSitesFromDessertes, changeDestination };
};

export const reBuildTrajets = (values: TrajetResource[]) => {
  return [
    // Il faut ajouter les trajets déjà visitéss par l'équipage
    // ...(equipage?.trajets || []).filter(
    //   (trajet: any) => trajet.etat === CODE_ETAT_TRAJET.passe
    // ),
    ...values.map(tourneeConverter.toTrajet),
  ];
};

/**
 * Service de modification du trajet
 * @param equipage
 * @returns
 */
const updateTrajets =
  (equipage: EquipageResource) => (data: Record<string, any>) => {
    return equipageService.modifierTrajets(equipage.id, {
      trajets: reBuildTrajets(data.trajets),
    });
  };

/**
 * Formulaire de modification du trajet
 * @param props
 * @returns
 */

const EditForm: FC<WrapperProps> = (props) => {
  useEditTrajet();
  const { watch } = useFormContext();
  const { trajets } = useContext(TrajetContext);

  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title={watch("title")}
      maxWidth="lg"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <MainCard>
            <Collapse
              title="Trajet"
              subtitle="Itinéraire de l'équipe"
              leftIcon={<TrajetIcon color="primary" />}
            >
              <ViewTrajet
                trajets={trajets}
                renderItem={(trajet) => <TrajetItem trajet={trajet} />}
              />
            </Collapse>
          </MainCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <PlanificationTrajetPanel
            title="Modifier le trajet"
            withDuplicate
            withDelete
          />
        </Grid>
      </Grid>
    </UtilitaireModal>
  );
};

const ModifierTrajetModal = withForm(EditForm);

/**
 * Formulaire d'ajout d'un ou de plusieurs sites au trajet
 * @param props
 * @returns
 */
const AjoutForm: FC<WrapperProps> = (props) => {
  const { addSites } = useEditTrajet();
  const { watch } = useFormContext();
  const { trajets } = useContext(TrajetContext);

  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title={watch("title")}
      maxWidth="lg"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SelectSitesAsyncForm
                name="sites"
                label="Nouveaux sites"
                afterSelected={(_, sites) => addSites(sites)}
              />
            </Grid>
            <Grid item xs={12}>
              <MainCard>
                <Collapse
                  title="Trajet"
                  subtitle="Itinéraire de l'équipe"
                  leftIcon={<TrajetIcon color="primary" />}
                >
                  <ViewTrajet
                    trajets={trajets}
                    renderItem={(trajet) => <TrajetItem trajet={trajet} />}
                  />
                </Collapse>
              </MainCard>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <PlanificationTrajetPanel
            title="Modifier le trajet"
            withDuplicate={false}
            withDelete={false}
          />
        </Grid>
      </Grid>
    </UtilitaireModal>
  );
};

const AjouterTrajetSiteModal = withForm(AjoutForm);

type GererTrajetProps = {
  equipage: EquipageResource;
  closeModal: () => void;
  onFinished: (data: EquipageResource) => void;
};

/**
 * Modifier le trajet
 * @param param0
 * @returns
 */
export const ModifierTrajet: FC<GererTrajetProps> = ({
  equipage,
  closeModal,
  onFinished,
}) => {
  return (
    <ModifierTrajetModal
      onSave={updateTrajets(equipage)}
      onBack={closeModal}
      onFinished={onFinished}
      defaultValues={{
        equipage,
        title: `Modifier trajet de l'équipage ${formatEquipage(equipage)}`,
      }}
    />
  );
};

/**
 * Ajout de sites
 * @param param0
 * @returns
 */
export const AjouterTrajetSite: FC<GererTrajetProps> = ({
  equipage,
  closeModal,
  onFinished,
}) => {
  return (
    <AjouterTrajetSiteModal
      onSave={updateTrajets(equipage)}
      onBack={closeModal}
      onFinished={onFinished}
      defaultValues={{
        equipage,
        title: `Ajouter sites au trajet de l'équipage ${formatEquipage(
          equipage
        )}`,
      }}
    />
  );
};
