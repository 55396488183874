import { Alert, AlertTitle, Grid } from "@mui/material";
import {
  SelectAsyncVehiculeForm,
  SelectPersonnelAsyncForm,
  SelectReferentielAsycForm,
  TextInput,
} from "components";
import { withForm, WrapperProps } from "hoc";
import { UtilitaireModal } from "pages/utilitaires";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { equipageService } from "services";
import { REFERENTIEL_TYPE } from "types";
import { EquipageResource } from "types/transport.types";
import { CODE_CONVOYEUR, CORPS } from "utils/constant";
import { selectHelper } from "utils/helpers";

import * as yup from "yup";
import { buildEquipage } from "../form";
import { equipageValidator } from "../form/common/tourneeSchema";
import { TourneePrestataireForm } from "../form/TourneePrestataireForm";
import { formatEquipage } from "../view/equipage/EquipageItem";

export const ModifierEquipageForm: FC<WrapperProps> = (props) => {
  const { watch } = useFormContext();
  const centreId = selectHelper.getValue(watch("centre"));
  const personnelParams = { codeFonction: CODE_CONVOYEUR.join(","), centreId };
  const isChangement = watch("mode") === "changement";

  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title={watch("title")}
      maxWidth="lg"
    >
      {isChangement ? (
        <Alert
          severity="info"
          color="primary"
          variant="border"
          sx={{ marginBottom: 3 }}
        >
          <AlertTitle sx={{ fontWeight: 600, textTransform: "uppercase" }}>
            Changement d'equipage
          </AlertTitle>
          Vous êtes sur le point de <strong>changer</strong> d'équipage. Toutes
          les dessertes non livrées seront affectées à la nouvelle équipe
        </Alert>
      ) : (
        <Alert
          severity="info"
          color="primary"
          variant="border"
          sx={{ marginBottom: 3 }}
        >
          <AlertTitle sx={{ fontWeight: 600, textTransform: "uppercase" }}>
            Modifier equipage
          </AlertTitle>
          Vous êtes sur le point de modifier l'équipage
        </Alert>
      )}
      <Grid container spacing={3}>
        {isChangement ? (
          <>
            <Grid item xs={12} md={6}>
              <SelectReferentielAsycForm
                name="motifChangement"
                label="Motif changement *"
                codes={["panne", "accident", "embouteillage", "autre"]}
                type={REFERENTIEL_TYPE.TYPE_CHANGEMENT}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput name="lieu" label="Lieu *" />
            </Grid>
          </>
        ) : watch("motifChangement") ? (
          <Grid item xs={12}>
            <TextInput name="lieu" label="Lieu" />
          </Grid>
        ) : null}
        <Grid item xs={12} md={3}>
          <SelectAsyncVehiculeForm
            name="vehicule"
            label="Véhicule *"
            size="small"
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <SelectPersonnelAsyncForm
            name="chefDeBord"
            label="Chef de bord *"
            size="small"
            params={personnelParams}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectPersonnelAsyncForm
            name="chauffeur"
            label="Chauffeur *"
            size="small"
            params={personnelParams}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectPersonnelAsyncForm
            name="agentGarde"
            label="Agent de garde *"
            size="small"
            params={personnelParams}
          />
        </Grid>
      </Grid>
      <TourneePrestataireForm />
    </UtilitaireModal>
  );
};

const ModifierEquipageModal = withForm(
  ModifierEquipageForm,
  yup.object({
    ...equipageValidator,
  })
);

type ModifierEquipageProps = {
  equipage: EquipageResource;
  closeModal: () => void;
  onFinished: (data: EquipageResource) => void;
};

export const ModifierEquipage: FC<ModifierEquipageProps> = ({
  equipage,
  closeModal,
  onFinished,
}) => {
  const modifierEquipage = (data: Record<string, any>) => {
    return equipageService.update(equipage.id, buildEquipage(data));
  };

  return (
    <ModifierEquipageModal
      onSave={modifierEquipage}
      onBack={closeModal}
      onFinished={onFinished}
      defaultValues={{
        ...equipage,
        centre: selectHelper.buildCentre(equipage.centre),
        chauffeur: selectHelper.buildPersonnel(equipage.chauffeur),
        chefDeBord: selectHelper.buildPersonnel(equipage.chefDeBord),
        agentGarde: selectHelper.buildPersonnel(equipage.agentGarde),
        vehicule: selectHelper.buildVehicule(equipage.vehicule),
        prestataires: (equipage.prestataires || []).map((item) => ({
          ...item,
          corps: CORPS.find((e) => e.value === item.corps),
        })),
        mode: "modifier",
        title: `Modifier équipage ${formatEquipage(equipage)}`,
      }}
    />
  );
};
