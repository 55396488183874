import { CentreRegionalResource } from "types/centre.type";
import { CrudService } from "./crudService";

const base = "centreregionals";

const crudService = new CrudService<
  CentreRegionalResource,
  CentreRegionalResource
>(base);

export const centreRegionalService = {
  ...crudService,
};
