import { AccountBalance } from "@mui/icons-material";
import { filterSousMenu } from "menu-items";
import { UserModule } from "types";
import { LINKS } from "utils/constant";

const sousmenus = [
  {
    id: "dab",
    title: "DAB",
    type: "item",
    url: LINKS.dabs.list,
    caption: "Consulter et gérer les DABs",
  },
  {
    id: "tickets",
    title: "Tickets",
    type: "item",
    url: LINKS.tickets.list,
    caption: "Consulter et gérer les tickets",
  },
  {
    id: "sla",
    title: "Reporting",
    type: "item",
    url: "/ssb-reporting",
  },
];

export const buildSSBMenu = (userModule: UserModule) => {
  const data = filterSousMenu(userModule, sousmenus);

  if (data.length) {
    return {
      id: "group-ssb",
      type: "group",
      children: [
        {
          id: "menu-ssb",
          title: "SSB",
          type: "collapse",
          icon: AccountBalance,
          children: data,
        },
      ],
    };
  }
  return null;
};
