// assets

import { useMemo } from "react";
import { LINKS } from "utils/constant";
import UtiliataireTemplate from "./UtiliataireTemplate";

export const UStocksNavigation = () => {
  const links = useMemo(() => {
    return [
      {
        label: "Fournisseurs",
        to: LINKS.ustocks.fournisseurs,
      },
      {
        label: "Magasins",
        to: LINKS.ustocks.magasins,
      },
      {
        label: "Famille d'articles",
        to: LINKS.ustocks.familleArticles,
      },
      {
        label: "Articles",
        to: LINKS.ustocks.articles,
      },
    ];
  }, []);

  return <UtiliataireTemplate links={links} />;
};
