import { Grading } from "@mui/icons-material";
import { Collapse } from "components";
import { ListSignature } from "pages/common";
import { FC } from "react";
import { SignatureResource } from "types/transport.types";

type DesserteSignaturesProps = {
  signatures?: SignatureResource[];
};

export const DesserteSignatures: FC<DesserteSignaturesProps> = ({
  signatures,
}) => {
  return (
    <Collapse
      title="Signatures"
      subtitle="Liste des signatures posées lors du ramassage/livraison des colis"
      leftIcon={<Grading color="primary" />}
    >
      <ListSignature signatures={signatures} />
    </Collapse>
  );
};
