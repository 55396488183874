import { Suspense } from "react";

import { LoaderProps, Loader } from "./Loader";

export const Loadable = (Component: any) => (props: LoaderProps) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
